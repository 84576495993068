<template>

    <form class="container">

      <div class="row mt-5 mb-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.changePassword_h1') }}</h1>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-3">
          <label for="password">{{ $t('message.changePassword_existing') }}*</label>
        </div>
        <div class="col-md-9">
          <input type="password"
                 maxlength="100"
                 class="form-control"
                 :class="{ 'is-invalid': !isExistingPasswordValid }"
                 id="existingPassword"
                 name="existingPassword"
                 :placeholder="$t('message.changePassword_existing')"
                 required
                 v-model="existingPassword">
          <div class="invalid-feedback">
            <span v-if="!isExistingPasswordValid">{{ $t('message.changePassword_existing_error') }}</span>
          </div>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="isExistingPasswordValid">

          <span>
            <p class="mt-5" v-if="useSavePassword">
              {{ $t('message.changePassword_new_pass_p2') }}
            </p>
            <p class="mt-5" v-else>
              {{ $t('message.changePassword_new_pass_p') }}
            </p>

            <b-alert variant="danger"  :show="errormessage ? true : false">{{ errormessage }}</b-alert>
            <b-alert variant="success" :show="successmessage ? true : false">{{ successmessage }}</b-alert>

            <div v-if="username !== true">

              <new-password-form-row v-on:set-password="setPassword" :use-save-password="useSavePassword" />

              <div class="form-group row">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                  <button type="button"
                          class="btn btn-outline-dark"
                          v-on:click="submitForm"
                          :disabled="!showSubmitButton">
                       {{ $t('message.default_absenden') }}
                  </button>
                </div>
              </div>

            </div>
          </span>
        </div>
      </transition>

    </form>

</template>

<script>

import axios from 'axios'
axios.defaults.withCredentials = true
import NewPasswordFormRow from './password/NewPasswordFormRow.vue'

export default {

  name: 'ChangePassword',
  components: {
    NewPasswordFormRow,
  },
  data () {
    return {
      username: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null,
      /** Fehlermeldung nach dem Absenden der Passwort Anforderung */
      errormessage: null,
      successmessage: null,
      password: null,
      /** ist das bestehende Passwort korrekt das eingegeben wurde */
      isExistingPasswordValid: false,
      /** aktuelles Passwort des Benutzers das eingegeben wird */
      existingPassword: null,
      /** es muss ein sicheres Passwort eingegeben werden - derzeit 16 Zeichen lang */
      useSavePassword: null,
    }
  },
  computed: {

    /** kann der Weiter Button angezeigt werden? */
    showSubmitButton: function () {

      if (!this.isPasswordValid) return false;
      return true;

    },
    /** prüft ob das Passwort eingegeben wurde */
    isPasswordValid: function () {

      if (this.password && this.password !== '') {
        return true
      }

      return false

    }

  },
  mounted: function () {

    // Umami Tracking aufrufen
    this.doTracking(this.$route.name)

    this.useSavePassword = this.$route.query && this.$route.query.useSavePassword ? true : false
    if (!this.$route.query || !this.$route.query.useSavePassword) {
      // prüfe ob nicht ein sicheres Passwort nötig ist:
      this.checkUseSavePassword()
    }

  },
  watch: {

    /** prüfe das eingegebene Passwort */
    existingPassword: function () {

      const params = {
        password: this.existingPassword,
        user:     JSON.stringify(this.$store.getters.user),
      }
      const url  = process.env.VUE_APP_SERVER_URL + '/login/checkPass'
      const that = this
      axios.get(url, {
        params: params,
        withCredentials: true,
      }).then(function (response) {

        if (response.data.id) {
          that.isExistingPasswordValid = true
        } else {
          that.isExistingPasswordValid = false
        }

      }).catch(function (error) {
        console.log(error)
      })

    }

  },
  methods: {

    /**
     * prüfe ob ein sicheres Passwort eingegeben werden muss,
     * funktioniert nur wenn ein Kunde ausgewählt wurde
     */
    checkUseSavePassword: function () {

      console.log('checkUseSavePassword')
      // keine Prüfung für Administratoren nötig
      if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
        return true
      }
      // auch keine Prüfung nötig wenn ein Admin als jemand anderer eingeloggt ist
      if (this.$store.getters.loggedInAs) {
        return true
      }

      if (this.$store.getters.customer) {

        const url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/checkPasswortSicherheit'
        const params  = {
          cardcode: this.$store.getters.customer.cardcode,
          mandant:  this.$store.getters.customer.biotechCountry.name,
        }

        const that = this
        axios({
          method: 'GET',
          params: params,
          url: url,
        }).then( response => {

          if (response.data.error) {
            console.log('es muss ein sicheres Passwort eingegeben werden', response.data.error)
            that.useSavePassword = true
          }

        }).catch(error => {
          console.log(error)
        })
      }

    },
    /** Passwort wurde verifiziert und zurückgegeben */
    setPassword: function (val) {

      this.password = val

    },
    submitForm: function () {

      this.showSpinner()
      const url    = process.env.VUE_APP_SERVER_URL + '/login/changePassAsUser'
      const params = {
        user:     JSON.stringify(this.$store.getters.user),
        password: this.password,
      }

      const that = this
      axios.get(url, {
        params: params,
        withCredentials: true,
      }).then(function (response) {

        that.hideSpinner()
        if (response.data.id) {

          console.log('Passwort wurde geändert')
          that.errormessage   = null
          that.successmessage = 'Ihr Passwort wurde erfolgreich geändert.'

        } else {
          that.errormessage   = 'Fehler beim Ändern des Passwortes, bitte versuchen Sie es erneut.'
          that.successmessage = null
        }

      }).catch(function (error) {
        that.hideSpinner()
        console.log(error)
      })

    }

  }

}
</script>
