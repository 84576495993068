<template>

    <span class="servicecall-button-wrapper">

        <b-button variant="outline-primary mt-2" size="sm" @click="showModal">
            <b-icon icon="cursor"></b-icon>&nbsp;{{ $t("message.traplinkedImages_support") }}
        </b-button>

        <b-modal :id="'serviceCall-modal-' + randomid"
                 :title="$t('message.traplinkedImages_support')"
                 size="xl"
                 hide-footer>

          <b-form @submit.prevent="onSubmit" v-if="$store.getters.customer.kunde">

              <div class="row">
                  <div class="col-md-8">

                    <!-- Position anzeigen -->
                    <div class="form-group row"
                         v-if="traplinkedReportImage.position && traplinkedReportImage.position.id">
                        <label for="position" class="col-sm-4 col-form-label">{{ $t('message.support_mon_pos') }}</label>
                        <div class="col-sm-8">
                            <input type="text"
                                   id="position"
                                   disabled
                                   :value="traplinkedReportImage.position.positionsNr + ' ' + traplinkedReportImage.position.positionsBesch"
                                   class="form-control"
                            />
                        </div>
                    </div>
                    <!-- Falle anzeigen -->
                    <div class="form-group row"
                         v-if="traplinkedReportImage.traplinkedDevice && traplinkedReportImage.traplinkedDevice.id">
                        <label for="falle" class="col-sm-4 col-form-label">{{ $t('message.traplinkedStatus_24_7_name') }}</label>
                        <div class="col-sm-8">
                            <input type="text"
                                   id="falle"
                                   disabled
                                   :value="traplinkedReportImage.traplinkedDevice.traplinkedId + ' ' + traplinkedReportImage.traplinkedDevice.name"
                                   class="form-control"
                            />
                        </div>
                    </div>

                    <!-- Telefonnummer anzeigen/ändern -->
                    <div class="form-group row">
                        <label for="tel" class="col-sm-4 col-form-label">{{ $t('message.support_meine_tel') }}*</label>
                        <div class="col-sm-8">
                            <b-form-input v-model="tel"
                                          :placeholder="$t('message.support_tel')"
                                          :state="validationTel"
                                          maxLength="20"
                            />
                            <b-form-invalid-feedback :state="validationTel">
                              {{ $t('message.support_meine_tel_err') }}
                            </b-form-invalid-feedback>
                        </div>
                    </div>

                  </div>
                  <div class="col-md-4">

                      <!-- Standort Adresse -->
                      <strong>{{ traplinkedReportImage.customer.kunde }}</strong><br />
                      {{ traplinkedReportImage.customer.adresse }}<br />
                      <span v-if="traplinkedReportImage.customer.zipcode && traplinkedReportImage.customer.ort">
                          {{ traplinkedReportImage.customer.zipcode }} {{ traplinkedReportImage.customer.ort }}
                      </span><br />
                      {{ traplinkedReportImage.customer.land }}

                  </div>
              </div>

              <hr />

              <!-- Problemtyp auswählen -->
              <div class="form-group row">
                  <label for="tel" class="col-sm-2 col-form-label">{{ $t('message.support_problemtyp') }}</label>
                  <div class="col-sm-10">
                      <select-problemtyp v-model="problemTyp" :mandant="$store.getters.customer.biotechCountry.name" />
                  </div>
              </div>
              <!-- Thema -->
              <div class="form-group row">
                  <label for="tel" class="col-sm-2 col-form-label">{{ $t('message.support_thema') }}*</label>
                  <div class="col-sm-10">
                      <b-form-input v-model="thema"
                                    :placeholder="$t('message.support_thema_placeholder')"
                                    :state="validationThema"
                                    maxLength="200" />
                      <b-form-invalid-feedback :state="validationThema">
                        {{ $t('message.support_thema_err') }}
                      </b-form-invalid-feedback>
                  </div>
              </div>
              <!-- Detailtext -->
              <div class="form-group row">
                  <label for="tel" class="col-sm-2 col-form-label">{{ $t('message.support_problem_beschreibung') }}*</label>
                  <div class="col-sm-10">
                      <b-form-textarea
                          id="text"
                          v-model="text"
                          rows="3"
                          max-rows="6"
                          :state="validationText"
                      ></b-form-textarea>
                      <b-form-invalid-feedback :state="validationText">
                        {{ $t('message.support_problem_beschreibung_err') }}
                      </b-form-invalid-feedback>
                  </div>
              </div>

              <hr />

              <!-- Foto anzeigen -->
              <div class="form-group row" v-if="traplinkedReportImage.src">

                <div class="col-sm-10 offset-2">
                    <img :src="traplinkedReportImage.src"
                         :alt="$t('message.traplinkedImages_alt')"
                         class="img-fluid img-thumbnail"
                    />
                </div>

              </div>

              <div class="form-group row">
                  <div class="col-sm-10 offset-2">
                      <b-button type="submit"
                                :disabled="!isFormValid"
                                variant="outline-primary">
                          <b-icon icon="check"></b-icon>&nbsp; {{ $t('message.default_absenden') }}
                      </b-button>
                  </div>
              </div>

          </b-form>

        </b-modal>

    </span>

</template>

<script>

    import axios from 'axios';
    import SelectProblemtyp from "@/components/support/SelectProblemtyp.vue";
    import bootbox from "bootbox";
    axios.defaults.withCredentials = true;

    // Define a new component
    export default {
        name: 'ServicecallButton',
        components: {
          SelectProblemtyp
        },
        props: [
            'traplinkedReportImage'
        ],
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              randomid: Math.random(),
              /** Telefonnummer des Kunden */
              tel: null,
              problemTyp: null,
              thema: null,
              text: null,
            }
        },
        computed: {

          validationTel: function () {
            if (!this.tel || this.tel == '') return false;
            if (this.tel.length < 5) return false;
            return true;
          },
          validationText: function () {
            // kein Pflichtfeld
            return true;
          },
          validationThema: function () {
            if (!this.thema || this.thema == '') return false;
            if (this.thema.length < 4) return false;
            if (this.thema.length > 200) return false;
            return true;
          },
          isFormValid: function () {
            return this.validationText && this.validationTel && this.validationThema;
          }

        },
        methods: {

          showModal: function () {
            this.$bvModal.show('serviceCall-modal-' + this.randomid)
          },
          /**
           * an Datenbroker übermitteln
           */
          onSubmit: function () {

            if (this.isFormValid) {

              this.showSpinner();

              // baue das JSON für die API zusammen:
              /*
                  String auftragProblemTypId
                  String auftragHerkunftId
                  String customerId
                  String ansprechpartnerId
                  String personalId
                  String email
                  String thema
                  String bemerkung
                  Date planungsDatum
                  String[] images
                  String positionId
                  String mangelId
                  String tel
               */

              let params = {
                auftragProblemTypId: this.problemTyp ? this.problemTyp.id : null,
                auftragHerkunftId: 'ANALYTICS',
                customerId: this.traplinkedReportImage.customer ? this.traplinkedReportImage.customer.id : this.$store.getters.customer.id,
                ansprechpartnerId: null,
                personalId: null,
                email: this.$store.getters.user.username,
                bemerkung: this.text,
                planungsDatum: null,
                traplinkedReportImageId: this.traplinkedReportImage.id,
                positionId: this.traplinkedReportImage.position ? this.traplinkedReportImage.position.id : null,
                mangelId: null,
                tel: this.tel,
                thema: this.thema
              }
              const json = JSON.stringify(params);
              console.log('params', json);

              let that = this;
              axios({
                method: 'POST',
                url: process.env.VUE_APP_SERVER_URL + '/datenbroker/saveServiceCall',
                data: params
              }).then(function (response) {

                that.hideSpinner()
                console.log('service call success', response.data)
                bootbox.alert(that.$t('message.support_danke'))
                that.$bvModal.hide('serviceCall-modal-' + that.randomid)

              }).catch(function (error) {
                that.hideSpinner();
                console.error(error);
                bootbox.alert(that.$t('message.support_error'));
              });

            }

          }

        }

    }

</script>
