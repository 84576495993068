<!-- Login Button der zur Login Seite wechselt -->
<template>

    <button class="btn btn-outline-success my-2 my-sm-0"
            type="button"
            v-if="!$store.getters.user"
            v-on:click="login">
        <span class="oi oi-account-login"></span>&nbsp;Login
    </button>

</template>

<script>

    export default {
      name: 'LoginButton',
      methods: {
        /** zur Login Seite */
        login: function () {
          this.$router.push('/login')
        }
      }
    }

</script>
