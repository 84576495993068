<template>

    <div class="customerNotificationsWrapper" v-if="$route.name != 'CustomerNotifications'">

        <!-- gleich ein Modal öffnen mit der Anzahl der Benachrichtigungen -->
        <b-modal id="modal-customer-notifications" title="Postbox" hide-footer>

            <b-alert variant="info" show>

                <span v-if="$store.getters.unreadNotifications > 1">
                  {{ $t('message.customerNotifications_ungelesen').replace('{0}', $store.getters.unreadNotifications) }}
                </span>
                <span v-if="$store.getters.unreadNotifications == 1">
                  {{ $t('message.customerNotifications_ungelesen_1') }}
                </span>

                <br/><br/>
                <b-button variant="outline-primary" size="sm" @click="showNotifications">
                    <b-icon icon="bell-fill"></b-icon> {{ $t('message.default_anzeigen') }}
                </b-button>

            </b-alert>

        </b-modal>

        <div style="font-size: 3rem;"
           v-if="$store.getters.user_role.authority == this.ROLE_CUSTOMER || $store.getters.user_role.authority == this.ROLE_KEY_USER">
          <b-icon icon="bell-fill"
                  class="rounded-circle bg-info p-2"
                  variant="light"
                  @click="showNotifications"
                  style="cursor: pointer">
          </b-icon>
        </div>

    </div>

</template>

<script>

import axios from 'axios'

export default {

  name: 'CustomerNotifications',
  watch: {
    '$store.getters.customer': function () {
      this.init();
    },
    '$store.getters.user': function () {
      this.init();
    },
  },
  methods: {

    /**
     * Benachrichtigungen anzeigen
     */
    showNotifications: function () {
      this.$router.push('/CustomerNotifications');
    },
    init: function () {

      console.info('zähle Kunden Nachrichten', this.$store.getters.user);
      // ist ein Kunde oder KeyUser eingeloggt?
      if (this.$store.getters.user_role) {

        let params = {}
        if (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER && this.$store.getters.customer) {
          params.customer_id = this.$store.getters.customer.id;
        } else if (this.$store.getters.user_role.authority == this.ROLE_KEY_USER && this.$store.getters.user) {
          params.key_user_id = this.$store.getters.user.keyUserId;
          params.mandant = this.$store.getters.user.biotechCountry.name;
          // gebe die Standorte mit falls schon vorhanden, dann geht das schneller
          if (this.$store.getters.standorte) {
            let custList = [];
            for (let i = 0; i < this.$store.getters.standorte.length; i++) {
              custList.push(this.$store.getters.standorte[i].id);
            }
            params.customers = custList.join(',');
          }
        }

        if (params.customer_id || params.key_user_id) {

          params.customerNotificationMessageStatus = 'UNGELESEN'
          const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/countCustomerNotifications';
          axios.get(url, {
            params: params,
            withCredentials: true
          }).then( response => {

              console.info('anzahl', response.data)

              if (response.data && response.data.anzahl) {

                this.$store.commit('setUnreadNotifications',  response.data.anzahl);
                // gleich ein Modal öffnen damit das nicht übersehen wird:
                this.$bvModal.show('modal-customer-notifications');

              } else {
                this.$store.commit('setUnreadNotifications', 0);
              }

          }).catch( error => {
            console.log(error);
          });

        }

      }

    }

  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

 .customerNotificationsWrapper {

   position: fixed;
   bottom: 1rem;
   right: 1rem;
   z-index:10;

 }

</style>