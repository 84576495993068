<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.benutzer_h1') }}</h1>
          </div>
        </div>

      </div>

      <div class="card mt-3">
        <div class="card-header">
          <b-button variant="outline-primary" @click="addBenutzer">
            <b-icon icon="person-plus"></b-icon>&nbsp;{{ $t('message.benutzer_add') }}
          </b-button>
        </div>
        <div class="card-body benutzerliste">

          <div v-if="loading">

            <div class="row"><div class="col-md-12 text-center"><SpinnerImage /></div></div>

          </div>

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active"
                      id="benutzer-tab"
                      data-toggle="tab"
                      data-target="#benutzer"
                      type="button"
                      role="tab"
                      @click="closeRows()"
                      aria-controls="benutzer"
                      aria-selected="true">
                {{ $t('message.benutzer_h1') }} &raquo; {{ $t('message.customerNotifications_standort') }}
              </button>
            </li>
            <li class="nav-item" role="presentation" v-if="$store.getters.anzahlCustomer > 1">
              <button class="nav-link"
                      id="standort-tab"
                      data-toggle="tab"
                      data-target="#standort"
                      type="button"
                      @click="closeRows()"
                      role="tab"
                      aria-controls="standort"
                      aria-selected="false">
                {{ $t('message.customerNotifications_standort') }} &raquo; {{ $t('message.benutzer_h1') }}
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="benutzer" role="tabpanel" aria-labelledby="benutzer-tab">

              <transition name="fade" mode="out-in">
                <div v-if="benutzerliste && benutzerliste.length > 0">

                  <!-- Ergebnis Tabelle Filtern -->
                  <div id="filter" class="row border-top mb-3 pt-3">

                    <div class="col-md-11">

                      <b-input-group size="sm">
                        <b-form-input
                            v-model="keyword"
                            type="search"
                            id="filterInput"
                            :placeholder="$t('message.positionen_filter')">
                        </b-form-input>
                        <b-input-group-append>
                          <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                        </b-input-group-append>
                      </b-input-group>

                    </div>
                    <div class="col-md-1 text-right">

                      <b-form-group horizontal label="" class="">
                        <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                      </b-form-group>

                    </div>

                  </div>

                  <b-table striped
                           small
                           :fields="fields"
                           :items="items"
                           :keyword="keyword"
                           :current-page="currentPage"
                           :per-page="perPage"
                           hover
                           :responsive="true">

                    <template v-slot:cell(email)="row">
                      {{ row.item.username ? row.item.username : row.item.email }}
                    </template>
                    <template v-slot:cell(enabled)="row">
                      <b-icon icon="x" v-if="!row.item.enabled" style="color: red"></b-icon>
                      <b-icon icon="check" v-else style="color: green"></b-icon>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button size="sm" @click="row.toggleDetails" v-if="row.item.hasStandorte === true">
                        {{ $t('message.benutzer_standorte') }}&nbsp;<b-icon icon="caret-up-fill" v-if="row.detailsShowing"></b-icon><b-icon icon="caret-down-fill" v-else></b-icon>
                      </b-button>
                      <b-alert variant="warning"
                               show
                               v-else-if="row.item.hasStandorte === false"
                               style="float: right; margin-left: 1em; margin-bottom: 0; padding: 0.3rem 0.5rem;">
                        {{ $t('message.benutzer_no_standorte') }}
                      </b-alert>
                      <b-button variant="outline-primary"
                                size="sm"
                                @click="edit(row.item, row.index, $event.target, row)"
                                class="ml-1">
                        <b-icon icon="pencil"></b-icon>&nbsp;{{ $t('message.default_aendern') }}
                      </b-button>
                      <b-button variant="outline-danger"
                                size="sm"
                                @click="deleteBenutzer(row.item, row.index, $event.target)"
                                class="ml-1">
                        <b-icon icon="trash"></b-icon>&nbsp;{{ $t('message.default_loeschen') }}
                      </b-button>
                    </template>
                    <template v-slot:row-details="row">
                      <b-card>
                        <list-kunden-of-benutzer :benutzer="row.item" />
                      </b-card>
                    </template>

                  </b-table>

                  <b-row>
                    <b-col md="12" class="my-1">
                      <b-pagination :total-rows="totalRows"
                                    :per-page="perPage"
                                    v-model="currentPage"
                                    class="my-0" />
                    </b-col>
                  </b-row>

                </div>
              </transition>

            </div>
            <div class="tab-pane fade"
                 id="standort"
                 role="tabpanel"
                 aria-labelledby="standort-tab"
                 v-if="$store.getters.anzahlCustomer > 1">
              <customer-list :show-details="false" :action="'SHOW-USERS'" />
            </div>
          </div>

        </div>
      </div>

      <!-- Model um Benutzer zu ändern oder hinzuzufügen -->
      <benutzer-modal v-model="benutzer"
                      v-on:set-selected-customers="setSelectedCustomers"
                      v-on:benutzer-added="benutzerAdded"
      />

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import bootbox from 'bootbox'
  import ListKundenOfBenutzer from '@/components/customer/ListKundenOfBenutzer'
  import SpinnerImage from '@/components/layout/SpinnerImage'
  import BenutzerModal from '@/components/benutzer/BenutzerModal.vue'

  export default {
    name: 'Benutzer',
    components: {
      BenutzerModal,
      SpinnerImage,
      ListKundenOfBenutzer
    },
    data () {
      return {
        benutzerliste: null,
        fields: [
          {
            key: 'email',
            label: this.$t('message.login_username'),
            sortable: true
          },
          {
            key: 'vorname',
            label: this.$t('message.benutzer_vorname'),
            sortable: true
          },
          {
            key: 'nachname',
            label: this.$t('message.benutzer_nachname'),
            sortable: true
          },
          {
            key: 'enabled',
            label: this.$t('message.benutzer_aktiv'),
            sortable: true
          },
          {
            key: 'actions',
            label: '',
            sortable: false,
            class: 'text-right'
          }
        ],
        /** Filter Query String für Suche nach Position */
        keyword: '',
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 20,
        /** Gesamtanzahl der Zeilen für Pagination */
        //totalRows: 0,
        /** Pagination der Ergenistabelle */
        currentPage: 1,
        /** Benutzer der gerade geändert wird */
        benutzer: null,
        /** Benutzerliste wird geladen */
        loading: null
      }
    },
    mounted () {
      this.init()
    },
    computed: {

      items: function () {

        var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

        var selectedRows = lowercase
                ? this.benutzerliste.filter( item =>

                    (item.email    && item.email.toLowerCase().includes(lowercase)) ||
                    (item.username && item.username.toLowerCase().includes(lowercase))

                )
                : this.benutzerliste;

        return selectedRows;

      },
      totalRows: function () {
        return this.items.length
      }

    },
    methods: {

      /**
       * ein neuer Benutzer wurde hinzugefügt
       */
      benutzerAdded: function (benutzer) {

        console.log('benutzerAdded', benutzer)
        let exist = false
        for (let i = 0; i < this.benutzerliste.length; i++) {
          if (this.benutzerliste[i].id == benutzer.id) {
            exist = true
            break
          }
        }
        if (!exist) {
          this.benutzerliste.push(benutzer)
        }

      },
      closeRows: function () {
        console.log('close rows')
        // neues init der customer-list
        // let newlist = []
        for (let i = 0; i < this.benutzerliste.length; i++) {
          this.benutzerliste[i].detailsShowing = false
          this.benutzerliste[i]._showDetails = false
          // newlist.push(this.benutzerliste[i])
        }
        // this.benutzerliste = newlist
      },
      /** einen Benutzer löschen */
      deleteBenutzer: function (item) {

        console.log('Benutzer löschen ', item)
        const that = this
        const text = this.$t('message.benutzer_delete').replace('{0}', item.username)
        bootbox.confirm(text, function(result) {
          if (result) {

            that.showSpinner();
            var url = process.env.VUE_APP_SERVER_URL + '/biotechUser/delete'
            var params = {
              id: item.id
            }
            axios ({
              method:   'post',
              params:   params,
              url:      url
            }).then(function () {

              that.hideSpinner()
              var newlist = []
              for (var i = 0; i < that.benutzerliste.length; i++) {
                if (that.benutzerliste[i].id != item.id) {
                  newlist.push(that.benutzerliste[i])
                }
              }
              that.benutzerliste = newlist

            })

          }
        })

      },
      /**
       * für den Benutzer ausgewählte Kunden
       */
      setSelectedCustomers: function (obj) {

        console.log('set Selected customers ...', obj)
        this.selectedCustomers = obj.selectedCustomers
        var hasStandorte = null
        if (this.selectedCustomers && this.selectedCustomers.length > 0) {
          hasStandorte = true
        } else {
          hasStandorte = false
        }

        for (var i = 0; i < this.benutzerliste.length; i++) {
          var item = this.benutzerliste[i]
          if (item.id == obj.benutzer.id) {
            item.hasStandorte = hasStandorte
          }
        }

      },
      /** Modal für neuen Benutzer öffnen */
      addBenutzer: function () {

        this.benutzer = {
          id: -1,
          enabled: true,
          username: '',
          email: ''
        }
        // Modal öffnen
        this.$bvModal.show('modal-edit');

      },
      /** Model für Änderung öffnen */
      edit: function (item, index, button, row) {

        console.log('Benutzer ändern ' + item.id);

        // wenn die Details sichtbar sind zuerst schließen:
        if (row.detailsShowing) {
          row.toggleDetails();
        }

        this.benutzer = item;
        // Modal öffnen
        this.$bvModal.show('modal-edit');

      },
      init: function () {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name);

        this.loading = true;
        // hole alle User
        var keyuser_id  = this.$store.getters.user.id
        this.showSpinner();
        var url         = process.env.VUE_APP_SERVER_URL + '/biotechUser';
        var that        = this;
        var params      = {
          user_id: keyuser_id
        };
        axios ({
          method: 'get',
          params: params,
          url: url
        }).then(function (response) {

          that.hideSpinner();
          that.benutzerliste = [];
          for (var i = 0; i < response.data.length; i++) {

            var item =  response.data[i];
            item.hasStandorte = null;
            that.benutzerliste.push(item);

          }
          that.loading = false;

          // starte prüfung ob Benutzer Standorte haben:
          that.checkStandorte();

        }).catch(error => {
          console.log(error);
          that.hideSpinner();
          that.loading = false;
        });

      },
      /**
       * prüft einelnen Benutzer ob er Standorte hat
       */
      hasStandorte: function (biotechUser_id) {

        var url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/countCustomersOfBiotechUser';
        var that    = this;
        var params  = {
          biotechUser_id: biotechUser_id
        };
        axios({
          method: 'post',
          params: params,
          url: url
        }).then(function (response) {

          var hasStandorte = null;
          if (response.data && response.data.anzahl && response.data.anzahl > 0) {
            console.log('Benutzer mit ID ' + biotechUser_id + ' hat Standorte: ' + response.data.anzahl);
            hasStandorte = true;
          } else {
            console.log('Benutzer mit ID ' + biotechUser_id + ' hat KEINE Standorte: ' + response.data.anzahl);
            hasStandorte = false;
          }

          let newlist = [];
          for (var i = 0; i < that.benutzerliste.length; i++) {
            if (that.benutzerliste[i].id == biotechUser_id) {
              that.benutzerliste[i].hasStandorte = hasStandorte;
            }
            newlist.push(that.benutzerliste[i]);
          }
          that.benutzerliste = newlist;

        }).catch(error => {
          console.log(error);
        });

      },
      /**
       * prüfe ob die Benutzer Standorte haben
       */
      checkStandorte: function () {

        for (var i = 0; i < this.benutzerliste.length; i++) {

          var item = this.benutzerliste[i];
          console.log('prüfe Benutzer mit ID ' + item.id + ' ob Standorte ...');

          this.hasStandorte(item.id);

        }

      }

    }

  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .modal-dialog {
    max-width: 80%;
  }

</style>
