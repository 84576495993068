<template>

  <b-button :variant="variant"
            v-if="position"
            class="mb-1"
            @click="startDrag()"
            style="overflow: hidden; max-width: 100%;"
            v-b-tooltip.hover title="Auswählen um Position in Plan einzuzeichnen.">
    <b-icon icon="pin-map"></b-icon> {{ position.positionsNr }} {{ position.positionsBesch }}
  </b-button>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    // Define a new component
    export default {
        name: 'PositionSelect',
        components: {
        },
        props: [
          'position',
        ],
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              variant: 'outline-dark'
            }
        },
        mounted: function () {

          this.init()

        },
        methods: {

          /**
           * Position ausgewählt die eingezeichnet werden soll
           */
          startDrag: function () {

            console.log('start drag position: ', this.position)
            this.$emit('drag-position', this.position)

          },
          init: function () {

            if (this.position) {

              const that = this
              const url = process.env.VUE_APP_SERVER_URL + '/plan/getPlanToPosition'
              const params = {
                position_id: this.position.id
              }

              axios ({
                method: 'GET',
                params: params,
                url:    url
              }).then(response => {

                console.log('position-selected', response.data)
                if (response.data && response.data.length > 0) {
                  that.variant = 'outline-primary'
                }

              })

            }

          },

        }

    }

</script>
