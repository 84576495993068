<!-- zeige Prioritaet eines Mangels -->
<template>

    <span class="edit-mangel-prioritaet-wrapper" v-if="prioritaet">
        <b-button-group>
          <b-button
              :variant="prioritaet && prioritaet.name == 'HOHE_GEFAHR' ? 'danger' : 'outline-danger'"
              @click="editPrioritaet('HOHE_GEFAHR')"
              :class="{ focus: prioritaet && prioritaet.name == 'HOHE_GEFAHR' }">
            <b-icon icon="exclamation-octagon"></b-icon>&nbsp;{{ $t('message.editMangelPrioritaet_hoch') }}
          </b-button>
          <b-button
              :variant="prioritaet && prioritaet.name == 'MITTLERE_GEFAHR' ? 'warning' : 'outline-warning'"
              @click="editPrioritaet('MITTLERE_GEFAHR')"
              :class="{ focus: prioritaet && prioritaet.name == 'MITTLERE_GEFAHR' }">
            <b-icon icon="exclamation-triangle"></b-icon>&nbsp;{{ $t('message.editMangelPrioritaet_mittel') }}
          </b-button>
          <b-button
                :variant="prioritaet && prioritaet.name == 'GERINGE_GEFAHR' ? 'info' : 'outline-info'"
                @click="editPrioritaet('GERINGE_GEFAHR')"
                :class="{ focus: prioritaet && prioritaet.name == 'GERINGE_GEFAHR' }">
            <b-icon icon="exclamation-circle"></b-icon>&nbsp;{{ $t('message.editMangelPrioritaet_gering') }}
          </b-button>
        </b-button-group>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
        name: 'EditMangelPrioritaet',
        props: [
            /** der Mangel */
            'value'
        ],
        data () {
            return {
              prioritaet: null
            }
        },
        watch: {
          value: function () {
            this.init();
          }
        },
        mounted: function () {
          this.init();
        },
        methods: {

          init: function () {

            this.prioritaet = this.value.mangelPrioritaet;

          },
          editPrioritaet: function (val) {

            if (this.prioritaet && val != this.prioritaet.name) {

              this.showSpinner();

              let that    = this;
              const url   = process.env.VUE_APP_SERVER_URL + '/datenbroker/editMangelPrioritaet';
              let params  = {
                mangel_id: this.value.id,
                prioritaet: val
              };
              axios({
                method: 'post',
                params: params,
                url: url
              }).then(function (response) {
                if (response.data) {
                  // bootbox.alert("Die Priorität wurde erfolgreich geändert."); // wirft Fehler, hier nicht anzeigen.
                  that.prioritaet.name = val;
                  that.$emit('mangel-prioritaet-changed', response.data);
                  that.hideSpinner();
                }
              });

            }

          }

        }

    }

</script>