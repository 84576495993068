<!-- rot, grün, gelb, ... Alarme anzeigen und das Ergebnis -->
<template>

    <span class="ErgebnisColorAndValue" v-if="alarm_level">
        <b-alert show
                 style="padding:0 1.25rem"
                 :variant="color">

            <span v-if="result_value">
               {{ result_value }}
            </span>
            <span v-else-if="notCheckable">
                <b-icon icon="lightning" />&nbsp;{{ $t('message.charts_nicht_pruefbar') }}
            </span>

        </b-alert>
    </span>

</template>

<script>

    export default {
      name: 'ErgebnisColorAndValueNoChart',
      props: ['alarm_level', 'result_value', 'task_ergebnis_id', 'notCheckable'],
      computed: {

        color: function() {

          if (this.alarm_level) {
            if (this.alarm_level == 'GRUEN') {
              return 'success';
            }
            if (this.alarm_level == 'ROT') {
              return 'danger';
            }
            if (this.alarm_level == 'GELB') {
              return 'warning';
            }
          }
          return 'secondary';

        }

      }

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .btn {
        /*min-height: 25px;*/
        width: 100%;
    }

</style>
