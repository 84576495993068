<template>

    <div class="searchPositionWrapper" v-if="positionen && positionen.length > 0">

      <v-select
          label="text"
          :options="options"
          :filterable="true"
          v-model="selected"
          :placeholder="$t('message.searchPosition') + '...'"
          @input="positionSelected">

        <template  v-slot:no-options="{ search, searching }">
          <template v-if="searching && search.length > 0">
            {{ $t('message.searchPosition_keine_gefunden') }} <em>{{ search }}</em>.
          </template>
        </template>

        <template v-slot:option="option">
            <span class="optionName">
                {{ option.text }}
            </span>
        </template>

        <template #selected-option="{text}">
          <div style="display: flex; align-items: baseline;">
            {{ text }}
          </div>
        </template>

      </v-select>

    </div>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import vSelect from 'vue-select';

    // Define a new component
    export default {
        name: 'SearchPosition',
        components: {
          vSelect
        },
        /** value = Plan */
        props: [
          'value',
          'positionAdded',
          'positionRemoved',
          /** Liste mit Positionen, damit diese nicht wieder geholt werden müssen */
          'positionsList'
        ],
        /*
          Defines the data used by the component
        */
        data: function(){
            return {
              selected: null,
              positionen: [],
              options: [],
              /** zum Abbrechen von Ajax requests */
              cancelTokenSource: null
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          positionsList: function () {
            this.init();
          },
          /** wenn der Plan gewechselt wird, müssen die Positionen neu geholt werden */
          value: function () {

            /** breche vorherige Requests zum Plan ab */
            if (this.cancelTokenSource) {
              // Cancel request
              this.cancelTokenSource.cancel();
            }

            /** erzeuge einen neuen Source token um Request abbrechen zu können */
            this.cancelTokenSource = axios.CancelToken.source();

            this.init();

          },
          /** eine neue Position wurde in den Plan eingezeichnet, ermögliche Suche nach dieser Position */
          positionAdded: function (newVal, oldVal) {

            console.log("Position added: " + oldVal + " " + newVal);
            if ((newVal && this.value) && (!oldVal || newVal != oldVal)) {

              // eh nicht schon vorhanden?
              var exists = false;
              for (var i = 0; i < this.positionen.length; i++) {
                if (this.positionen[i].id == newVal) {
                  exists = true;
                  break;
                }
              }
              if (!exists) {
                var obj = {
                  id: newVal
                }
                this.getPositionDetails(obj);
              }

            }

          },
          /** eine Position wurde aus dem Plan entfernt, lösche sie aus der Suche */
          positionRemoved: function (newVal, oldVal) {

            if ((newVal && this.value) && (!oldVal || newVal != oldVal)) {

              var newlist = [];
              for (var i = 0; i < this.positionen.length; i++) {
                if (this.positionen[i].id != newVal) {
                  newlist.push(this.positionen[i]);
                }
              }
              this.positionen = newlist;
              var newoptions = [];
              for (var i = 0; i < this.options.length; i++) {
                if (this.options[i].value.id != newVal) {
                  newoptions.push(this.options[i]);
                }
              }
              this.options = newoptions;

            }

          }

        },
        methods: {

          positionSelected: function (newVal) {
            this.emitme(newVal.value);
          },
          init: function () {

            console.log ('init search position ...');
            this.positionen = null;
            this.selected   = null;
            this.options    = [
              // { value: null, text: '-- Position suchen --', disabled: true }
            ];

            var customer = this.$store.getters.customer;

            if (this.positionsList && this.positionsList.length > 0) {

              this.positionen = this.positionsList;
              if (this.positionen && this.positionen.length > 0) {
                for (var i = 0; i < this.positionen.length; i++) {
                  this.addPositionToOptions(this.positionen[i]);
                }
                // sortiere die Positionen:
                this.options = this.options.sort((a, b) => a.text.localeCompare(b.text));
              }

            } else if (this.value) { // Plan ID ist gesetzt

              var that    = this;
              var url     = process.env.VUE_APP_SERVER_URL + '/plan/getPositions';
              var params  = {
                plan_id:      this.value.id,
                customer_id:  customer.id
              };
              axios ({
                method:       'post',
                params:       params,
                url:          url,
                cancelToken:  that.cancelTokenSource ? that.cancelTokenSource.token : null
              }).then(function (response) {

                that.positionen = response.data;
                if (that.positionen && that.positionen.length > 0) {
                  for (var i = 0; i < that.positionen.length; i++) {
                    that.addPositionToOptions(that.positionen[i]);
                  }
                }
                // sortiere die Positionen:
                that.options = that.options.sort((a, b) => a.text.localeCompare(b.text));

              });

            }

          },
          /** options Liste um Position erweitern */
          addPositionToOptions: function (position) {

            var exists = false;
            for (var i = 0; i < this.options.length; i++) {
              if (this.options[i].value.id == position.id) {
                exists = true;
                break;
              }
            }
            if (!exists) {
              this.options.push (
                  {
                    value: position,
                    text:  position.positionsNr
                  }
              );
              this.getPositionDetails(position);
            }

          },
          /** holt den Namen der Position aus dem Datenbroker */
          getPositionDetails: function (position) {

            // console.log('hole position Details ' + position.id);
            var that          = this;
            var url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionDetails';
            var params        = {
              'position_id': position.id
            };

            axios ({
              method:       'post',
              params:       params,
              url:          url,
              cancelToken:  that.cancelTokenSource ? that.cancelTokenSource.token : null
            }).then(function (response) {

              if (response.data && response.data.position) {
                var positionsBesch = response.data.position.positionsBesch;
                // console.log('setze Position-Beschreibung für Suche ' + positionsBesch);
                // setzte die Beschreibung in der Positionsliste
                var exists = false;
                for (var i = 0; i < that.options.length; i++) {
                  var pos = that.options[i].value;
                  if (pos && pos.id == position.id) {
                    // console.log('Pruefe Pos ' + pos.id);
                    if (!that.options[i].text.endsWith(positionsBesch)) {
                      that.options[i].text = that.options[i].text + ' ' + positionsBesch;
                    }
                    exists = true;
                    break;
                  }
                }
                if (!exists) {
                  that.options.push({ value: response.data.position, text: response.data.position.positionsNr + ' ' + positionsBesch });
                }
              }

            });

          },
          emitme: function (position) {
            this.$emit('zoom-position', position);
          }

        }

    }

</script>
