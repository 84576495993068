<template>

    <div draggable="false">
        <b-list-group v-if="positionen">

          <span v-for="position in positionen" :key="position.id">
            <position-select :position="position" v-on:drag-position="startDrag" />
          </span>

        </b-list-group>
        <span v-else>
            <!-- Loading anzeigen -->
            <div class="row">
                <div class="col-md-3">
                    <SpinnerImage />
                </div>
                <div class="col-md-9">
                    {{ $t('message.positionen_loading') }}
                </div>
            </div>
        </span>
    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import SpinnerImage from '@/components/layout/SpinnerImage'
    import PositionSelect from "@/components/plan/PositionSelect.vue";

    // Define a new component
    export default {
        name: 'PositionList',
        components: {
          PositionSelect,
          SpinnerImage
        },
        props: [
          'value',
           /** Positionen einer einzelnen Area */
          'area',
           /** Positionen einer Liste von Areas */
          'areas',
          'drawnPositions',
          'plan',
          'trigger'
        ],
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              selected: null,
              positionen: null,
            }
        },
        mounted: function () {

          this.init()

        },
        watch: {

          selected: function (newVal, oldVal) {
            if (newVal && oldVal && newVal.id != oldVal.id) {
              this.emitme()
            } else if (newVal) {
              this.emitme()
            }
          },
          area: function (newVal, oldVal) {
            console.log('Area changed ' + newVal.id)
            if (newVal && oldVal && newVal.id != oldVal.id) {
              this.init()
            } else if (newVal) {
              this.init()
            }
          },
          areas: function (newVal) {
            console.log('Areas changed ' + newVal.length)
            this.init()
          },
          drawnPositions: function () {
            // console.log('drawnPositions changed, filter it ...');
            this.filterDrawnPositions()
          },
          /** wenn der Plan gewechselt wird, müssen die Positionen neu geholt werden */
          plan: function () {
            this.init()
          },
          /** Komponente neu laden */
          trigger: function () {
            this.init()
          }

        },
        methods: {

          /**
           * bereits eingezeichnete Positionen nicht mehr anzeigen
           */
          filterDrawnPositions: function () {

            if (this.drawnPositions && this.positionen && this.positionen.length > 0 && this.drawnPositions.length > 0) {
                var newlist = []
                for (var i = 0; i < this.positionen.length; i++) {

                  var exists = true
                  for (var j = 0; j < this.drawnPositions.length; j++) {

                    if (this.drawnPositions[j] == this.positionen[i].id) {
                      exists = false
                      break
                    }

                  }
                  if (exists) {
                    newlist.push(this.positionen[i])
                  }

                }
                this.positionen = newlist
            }

          },
          /**
           * Position ausgewählt die eingezeichnet werden soll
           */
          startDrag: function (position) {

            console.log('start drag position: ', position)
            this.$emit('drag-position', position)

          },
          init: function () {

            console.log ('init select position ...')
            this.positionen = null
            this.selected   = null

            var that = this
            var url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionenOfArea'
            let params = null
            if (this.area) {

              params = {
                'area_id':  this.area.id,
                'cardcode': this.$store.getters.customer.cardcode,
                'mandant':  this.$store.getters.customer.biotechCountry.name
              }

            } else if (this.areas) {

              let ids = []
              for (let area in this.areas) {
                let a = this.areas[area]
                ids.push(a.id)
              }

              params = {
                'areas': ids.join(','),
                'cardcode': this.$store.getters.customer.cardcode,
                'mandant':  this.$store.getters.customer.biotechCountry.name
              }

            }

            if (params) {

              axios ({
                method: 'post',
                params: params,
                url:    url
              }).then(response => {

                that.positionen = response.data
                that.filterDrawnPositions()

              })

            }

          },
          emitme: function () {
            this.$emit('input', this.selected)
          }

        }

    }

</script>
