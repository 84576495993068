<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.printMaengel_h1") }}</h1>
          </div>
        </div>

      </div>

      <div class="maengeluebersicht">

          <transition name="fade" mode="out-in">
              <div class="mt-5 pl-3 pr-3" v-if="$store.getters.customer">

                <maengel-table :customer="$store.getters.customer" />

              </div>
          </transition>

      </div>

    </div>

</template>

<script>

  import maengelTable from '@/components/maengelliste/maengelTable'

  export default {
    name: 'Maengelliste',
    components: {
      maengelTable
    },
    mounted () {
      // Umami Tracking aufrufen
      this.doTracking(this.$route.name)
    }

  }

</script>
