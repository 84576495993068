<template>

    <div id="content" role="main">

      <b-card no-body class="mt-4">
        <b-tabs
            card
            active-nav-item-class="font-weight-bold">
          <b-tab :title="$t('message.sicherheitsdatenblaetter_sdb')" active>
            <b-card-text><sicherheitsdatenblaetter-list /></b-card-text>
          </b-tab>
          <b-tab :title="$t('message.sicherheitsdatenblaetter_allgemein')">
            <b-card-text><allgemeine-dokumente /></b-card-text>
          </b-tab>
          <b-tab :title="$t('message.sicherheitsdatenblaetter_eigene')">
            <b-card-text><eigene-dokumente /></b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>

    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import SicherheitsdatenblaetterList from "@/components/dokumente/SicherheitsdatenblaetterList.vue";
  import AllgemeineDokumente from "@/components/dokumente/AllgemeineDokumente.vue";
  import EigeneDokumente from "@/components/dokumente/EigeneDokumente.vue";

  export default {

    name: 'Sicherheitsdatenblaetter',
    components: {
      SicherheitsdatenblaetterList,
      AllgemeineDokumente,
      EigeneDokumente
    },
    mounted() {
      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);
    }

  }

</script>