<template>

    <span class="alarmeTaskResult">

        <span class="alarmeTaskResult" v-if="chartdata">

           <line-chart
                   v-if="loaded"
                   v-on:set-chart="setChart"
                   :chartdata="chartdata"
                   :options="options" />

          <!-- Legende -->
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageBlau + ')', marginBottom: 0 }" class="mt-2 ml-4">
            <li style="font-size: 0.9rem">{{ $t('message.charts_nicht_pruefbar') }}</li>
          </ul>
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageGruen + ')', marginBottom: 0 }" class="ml-4">
            <li style="font-size: 0.9rem">{{ $t('message.default_ok') }}</li>
          </ul>
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageGelb + ')', marginBottom: 0 }" class="ml-4">
            <li style="font-size: 0.9rem">{{ $t('message.charts_warnung') }}</li>
          </ul>
          <ul v-bind:style="{ listStyleImage: 'url(' + listImageRot + ')' }" class="ml-4">
            <li style="font-size: 0.9rem">{{ $t('message.charts_gefahr') }}</li>
          </ul>

        </span>
        <div v-else-if="!chartdata && !loaded" class="text-center">
            <SpinnerImage/>
        </div>
        <b-alert show v-if="setNoData">{{ $t('message.charts_nicht_genuegend_ergebnisse') }}</b-alert>

    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import LineChart from '@/components/charts/LineChart';
    import SpinnerImage from "@/components/layout/SpinnerImage";

    export default {
      name: 'LineStackedAreaChartAlarmeTaskResultNoMonatsbericht',
      components: {
        LineChart,
        SpinnerImage
      },
      props: [
          'taskErgebnisId',
          // ODER
          'customerErgebnisId',
      ],
      data () {
        return {
          /** noch kein Ergebnis verfügbar - true setzen */
          setNoData: false,
          loaded: false,
          /** Daten für den Bar Chart */
          chartdata: null,
          options: null,
          /** Chart.js Chart */
          chart: null,
          areasAllowed: null,
          /** Ergebnisliste auf Klick auf den Icon */
          ergebnisliste: null,
          listImageGruen: require('@/assets/images/list-style-plan-gruen.gif'),
          listImageGelb:  require('@/assets/images/list-style-plan-gelb.gif'),
          listImageRot:   require('@/assets/images/list-style-plan-rot.gif'),
          listImageBlau:  require('@/assets/images/list-style-plan-blau.gif')
        }
      },
      watch: {
        taskErgebnisId: function () {
            this.init()
        }
      },
      created: function () {
        this.init()
      },
      methods: {

        init: async function () {

          this.loaded = false;
          if (this.taskErgebnisId || this.customerErgebnisId) {

            // ich brauche hier die Areas von allen Standorten des Benutzers:
            this.areasAllowed = null;
            if (this.$store.getters.showAreas && this.$store.getters.showAreas.length > 0) {
              this.areasAllowed = this.$store.getters.showAreas.join(',');
            }

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataOfBesuchsbericht';
            var params  = {
              task_ergebnis_id: this.taskErgebnisId,
              customer_ergebnis_id: this.customerErgebnisId,
              type:             'LINE_STACKED_AREA_CHART_TASK_ERGEBNIS',
              areasAllowed:     this.areasAllowed
            }

            var that = this;
            await axios({
              method: 'post',
              params: params,
              url: url
            }).then(function (response) {

              if (response.data) {

                that.chartdata = response.data.chartdata;
                if (that.chartdata) {

                  var datasets = that.chartdata.datasets;
                  if (datasets) {

                    var hasTicks = Object.keys(that.chartdata.ticks).length > 0;
                    // kalkuliere max y Wert, falls Required Text:
                    var max = 0;
                    if (that.chartdata.ticks && hasTicks) {
                      for (const [key, value] of Object.entries(that.chartdata.ticks)) {
                        console.log(key, value);
                        if (key > max) max = key;
                      }
                    }
                    console.log('max WERT: ' + max);

                    var tooltips = null;
                    var ticks = null;
                    if (hasTicks) {

                      console.log('chart hat ticks: ' + Object.keys(that.chartdata.ticks).length + ' hasTicks ' + hasTicks);
                      // wenn bei den ticks kein -1 dabei ist, dann beginAtZero auf true setzen
                      var hasMinusEinsTick = false;
                      for (const [key, value] of Object.entries(that.chartdata.ticks)) {
                        if (key == -1 && value) {
                          hasMinusEinsTick = true;
                          break;
                        }
                      }
                      if (hasMinusEinsTick && max == 0) {
                        // ermittle max aus den chartdatas:
                        for (let i = 0; i < datasets[0].data.length; i++) {
                          if (datasets[0].data[i] > max) {
                            max = datasets[0].data[i];
                          }
                        }
                      }

                      ticks =
                          {
                            beginAtZero: hasMinusEinsTick ? false : true,
                            callback: function (value, index) {
                              if (that.chartdata && that.chartdata.ticks) {
                                let ticksdata = that.chartdata.ticks[value];
                                if (ticksdata) {
                                  console.log('get from ticks ' + that.chartdata.ticks[value] + ' index: ' + index);
                                  return ticksdata;
                                }
                              }
                              console.log("get value = " + value + " " + that.chartdata.ticks);
                              if (that.chartdata && that.chartdata.ticks[1]) {
                                return '';
                              }
                              if (that.chartdata && typeof that.chartdata.datasets[0].data[0] === 'number') {
                                return value;
                              }
                              return '';
                            },
                            min: hasMinusEinsTick ? -1 : 0,
                            max: max
                          };

                      tooltips = {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var label = data.datasets[tooltipItem.datasetIndex].label;
                            if (!label) label = '';
                            let wert = that.chartdata.ticks[tooltipItem.yLabel];
                            if (!wert) {
                              wert = tooltipItem.value;
                            }
                            label += ": " + wert;
                            console.log('label= ' + label);
                            return label;
                          }
                        }
                      };

                    }

                    that.options =
                        {
                          responsive: true,
                          title: {
                            display: false
                          },
                          legend: {
                            display: false
                          },
                          hover: {
                            mode: 'index'
                          },
                          scales: {
                            xAxes: [{
                              display: true,
                              scaleLabel: {
                                display: false
                              }
                            }],
                            yAxes: [{
                              display: true,
                              scaleLabel: {
                                display: true,
                                labelString: that.$t('message.printErgebnisliste_ergebnis')
                              },
                            }]
                          }
                        };

                    if (hasTicks) {
                      that.options.tooltips = tooltips;
                      that.options.scales.yAxes[0].ticks = ticks;
                    } else {
                      that.options.scales.yAxes[0].ticks = {
                        beginAtZero: true,
                        min: 0
                      }
                      if (hasTicks) {
                        that.options.scales.yAxes[0].ticks.min = -1;
                      }
                    }

                  } else {
                    that.chartdata = null;
                    that.setNoData = true;
                  }

                } else { // end if that.chartdata
                  that.setNoData = true;
                }

              }
              that.loaded = true;

            }).catch(error => {
              console.log(error);
              that.loaded = true;
              that.setNoData = true;
            });
          }

        },
        /**
         * Chart des Parents zurückliefern um Klick Events zu behandeln
         */
        setChart: function (obj) {
          console.log("set chart: " + obj);
          this.chart = obj;
        },
        /**
         * holt die Ergebnisse dieses Tasks, index = der Besuchsbericht in der Liste der Besuchsberichte für diesen Task
         */
        getErgebnisse: function (index) {

          console.log('getErgebnisse für index = ' + index);

          this.showSpinner();
          this.ergebnisliste = null;
          var url            = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataErgebnisse';
          var params         = {
            task_ergebnis_id:     this.taskErgebnisId,
            areasAllowed:         this.areasAllowed,
            index:                index
          };

          var that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            that.ergebnisliste = response.data;
            that.hideSpinner();
            if (that.ergebnisliste && that.ergebnisliste.length > 0) {
              that.$bvModal.show('modal-ergebnisse-' + that.taskErgebnisId);
            }

          });

        }

      }

    }

</script>

<style scoped>
  .modal-ergebnisse .modal-lg {
      max-width: 1250px;
  }
</style>