<!--
zeige das Objekt des Traplinked Device an
-->
<template>

  <span v-if="objekt && objekt.id">
    <b-overlay :show="showOverlay" rounded="sm">
      {{ objekt.bezeichnung }}
    </b-overlay>
  </span>

</template>
<script>

  // Define a new component
  import axios from "axios";

  export default {

    name: 'ObjektOfTraplinkedDevice',
    props: ['value', 'object'], // das Traplinked Device
    data: function () {
      return {
        objekt: null,
        /** Overlay anzeigen während objekt geladen wird */
        showOverlay: false
      }
    },
    watch: {

      value: function () {
        this.init()
      },
      object: function () {
        this.init()
      }

    },
    mounted: function () {

      this.init();

    },
    methods: {

      /** hole Objekt zu einem Traplinked Device */
      init: function () {

        this.objekt = null;
        if (this.object) {

          this.objekt = this.object

        } else if (this.value) {

          // zeige overlay-loading:
          this.showOverlay = true;
          var customer = this.$store.getters.customer;
          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getObjektOfTraplinkedDevice';
          var params   = {
            traplinked_device_id: this.value.id,
            customer_id:          customer.id
          };
          var that = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data) {

              that.objekt = response.data;
              let emitme = {
                traplinked_device_id: that.value.id,
                objekt: that.objekt
              }
              console.log('emit objekt of traplinked_device_id ' + that.value.id + ' objekt = '  + that.value.id);
              that.$emit('set-objekt', emitme);

            }
            that.showOverlay = false;

          }).catch(error => {
            console.log(error);
            that.showOverlay = false;
          });

        }

      }

    }

  }

</script>
