<!-- zeige Anzahl der Mängel eines Kunden -->
<template>

  <span>

    <b-icon icon="arrow-clockwise" animation="spin" v-if="showOverlay" />

    <span class="anzahlMaengelIcon" v-if="showMangelIcon" @click="showMaengelliste">
        <b-avatar variant="warning"
                  v-b-tooltip.hover.top
                  :title="hovertitle"
                  v-if="anzahlMaengel > 0"
                  :text="'' + anzahlMaengel"
                  size="1.6rem">
        </b-avatar>
    </span>

  </span>

</template>

<script>

    // Define a new component
    import axios from "axios";
    axios.defaults.withCredentials = true;

    export default {
        name: 'AnzahlMaengelIcon',
        props: {
            /** Anzahl der Mängel */
            anzahl: {
              type: Number,
              required: false
            },
            customer: {
              type: Object,
              required: false
            }
        },
        data () {
            return {
              /** prüfen ob die Mängel angezeigt werden dürfen aus den Benutzerrechten des Kunden */
              showMaengelAllowed: true,
              anzahlMaengel: 0,
              showOverlay: null,
              hovertitle: ''
            }
        },
        watch: {

          customer: function () {
            this.init();
          },
          anzahl: function () {
            this.init();
          }

        },
        mounted: function () {

          this.init();

        },
        computed: {

          showMangelIcon: function () {

            if (!this.anzahlMaengel) return false;
            return this.showMaengelAllowed;

          }

        },
        methods: {

          init: function () {

            if (this.anzahl != null) {

              this.anzahlMaengel = this.anzahl;
              this.hovertitle    = this.anzahlMaengel + ' offene Mängel';

            } else if (this.customer) {

              this.hovertitle = this.$t('message.anzahlMaengelIcon_hover');

              this.showOverlay = true;
              this.anzahlMaengel = this.anzahl;

              // hole die Benutzerrechte des Customer um zu prüfen ob die Mängel angezeigt werden können
              const serverUrl = process.env.VUE_APP_SERVER_URL;
              var url     =  serverUrl + '/biotechUser/getBenutzerrechte';
              var params  = {
                cardcode:         this.customer.cardcode,
                mandant:          this.customer.biotechCountry.name,
                biotechUser_id:   this.$store.getters.user.id
              };
              var that    = this;
              axios({
                method: 'post',
                params: params,
                url: url
              }).then(function (response) {

                if (response.data && response.data.id && response.data.showMaengel === false) {
                  that.showMaengelAllowed = false;
                } else {

                  // wenn keine Anzahl übergeben wurde, dann hole sie:
                  if (that.anzahlMaengel == null) {

                    url     = serverUrl + '/datenbroker/countMaengel';
                    params  = {
                      customer: JSON.stringify(that.customer)
                    };
                    axios({
                      method: 'get',
                      params: params,
                      url: url
                    }).then(function (response) {
                      if (response.data && response.data.anzahl) {
                        that.anzahlMaengel = response.data.anzahl;
                        console.log('Anzahl Maengel des Kunden: ' + that.anzahlMaengel);
                        that.emitAnzahl();
                      }
                    });

                  } else {
                    // wenn kein Plan hochgeladen wurde, hat der Kunde einen zusätzlichen Mangel:
                    that.countPlaene();
                  }

                }

                that.showOverlay = false;

              });

            }

          },
          /**
           * wenn kein Plan hochgeladen wurde, hat der Kunde einen zusätzlichen Mangel:
           */
          countPlaene: function () {

            let that    = this;
            let url     = process.env.VUE_APP_SERVER_URL + '/plan/countPlaene';
            let params  = {
              customer: JSON.stringify(this.customer)
            };
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(function (response) {
              if (response.data && response.data.anzahl < 1) {
                if (that.anzahlMaengel == null) that.anzahlMaengel = 0;
                that.anzahlMaengel = that.anzahlMaengel + 1;
              }
              that.emitAnzahl();
            });

          },
          /** Anzahl an Parent zurückliefern */
          emitAnzahl: function () {

            const obj = {
              customer: this.customer,
              anzahl:   this.anzahlMaengel
            }
            this.$emit('set-anzahl-maengel', obj);

          },
          /** Mängelliste anzeigen */
          showMaengelliste: function () {

            if (this.customer) {
              this.setCustomerAndUserRights(this.customer);
              this.$router.push('/Maengelliste');
            }

          }

        }

    }

</script>

<style scoped>
    .anzahlMaengelIcon {
        cursor: pointer;
    }
</style>
