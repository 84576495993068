<template>

    <span class="isCheckedBiotechUserToCustomer" v-if="value && benutzer">

        <span v-if="benutzer && benutzer.length > 0">
          <b-table-simple hover small responsive>
              <b-tbody>
                <b-tr v-for="item in benutzer" :key="item.id">
                  <b-td>{{ item.username }}</b-td>
                  <b-td>{{ item.vorname }}</b-td>
                  <b-td>{{ item.nachname }}</b-td>
                  <b-td>

                    <span v-b-tooltip.hover :title="$t('message.benutzer_aktiv')"  v-if="item.enabled">
                      <b-icon icon="check"
                              style="color: green">
                      </b-icon>
                    </span>
                    <span v-b-tooltip.hover :title="$t('message.benutzer_inaktiv')" v-else>
                      <b-icon icon="exclamation-triangle"
                              style="color: red">
                      </b-icon>
                    </span>

                  </b-td>
                  <b-td class="text-right">
                      <b-button variant="outline-primary"
                                size="sm"
                                @click="edit(item)"
                                class="ml-1">
                        <b-icon icon="pencil"></b-icon>&nbsp;{{ $t('message.default_aendern') }}
                      </b-button>
                      &nbsp;
                      <b-button variant="outline-danger"
                                size="sm"
                                @click="deleteBenutzer(item)"
                                class="ml-1">
                        <b-icon icon="trash"></b-icon>&nbsp;{{ $t('message.default_loeschen') }}
                      </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
          </b-table-simple>
        </span>
        <span v-else-if="empty">
          {{ $t('message.show_benutzer_of_customer_none') }}
        </span>
        <span v-else-if="loading">
          <SpinnerImage />
        </span>

        <!-- Model um Benutzer zu ändern oder hinzuzufügen -->
        <benutzer-modal v-model="benutzer"
                        :modal-id="'modal-edit' + value.id"
                        v-on:set-selected-customers="setSelectedCustomers"
        />

    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import SpinnerImage from '@/components/layout/SpinnerImage'
    import bootbox from 'bootbox'
    import BenutzerModal from '@/components/benutzer/BenutzerModal.vue'

    export default {
      name: 'ShowBenutzerOfCustomer',
      components: {
        BenutzerModal,
        SpinnerImage
      },
      props: [
          /** der Kunde */
          'value'
      ],
      data () {
        return {
          /** Liste der Benutzer zum Standort */
          benutzer: null,
          /** Benutzer werden geladen */
          loading: false,
          empty: false,
        }
      },
      watch: {

        value: function () {
          this.init()
        },

      },
      created () {

        this.init()

      },
      methods: {

        setSelectedCustomers: function (obj) {
          // TODO:
          console.log('setSelectedCustomers', obj)
        },
        /** Model für Änderung öffnen */
        edit: function (item) {

          console.log('Benutzer ändern ' + item.id)

          this.benutzer = item
          // Modal öffnen
          this.$bvModal.show('modal-edit' + this.value.id)
          // wenn geschlossen Benutzer neu laden
          this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            console.log('Modal is about to be shown', bvEvent, modalId)
            if (modalId.startsWith('modal-edit')) {
              this.init()
            }
          })

        },
        /** einen Benutzer löschen */
        deleteBenutzer: function (item) {

          console.log('Benutzer löschen ' + item.id)
          const that = this
          const text = this.$t('message.benutzer_delete').replace('{0}', item.username)
          bootbox.confirm(text, result => {
            if (result) {

              that.showSpinner()
              var url = process.env.VUE_APP_SERVER_URL + '/biotechUser/delete';
              var params = {
                id: item.id
              }
              axios ({
                method: 'POST',
                params: params,
                url:    url
              }).then( () => {

                let newlist = []
                for (let i = 0; i < that.benutzer.length; i++) {
                  if (that.benutzer[i].id != item.id) {
                    newlist.push(that.benutzer[i])
                  }
                }
                that.benutzer = newlist
                that.hideSpinner()

              })

            }
          })

        },
        init: function () {

          this.benutzer = null
          this.empty    = false
          if (this.value) {

            this.loading = true
            const url    = process.env.VUE_APP_SERVER_URL + '/biotechUser/getUsersOfCustomer';
            const params = {
              cardcode: this.value.cardcode,
              mandant:  this.value.biotechCountry.name,
              biotechUser_id: this.$store.getters.user.id,
            }
            const that = this
            axios({
              method: 'GET',
              params: params,
              url: url,
            }).then(response => {

              if (response.data && response.data.length > 0) {
                that.benutzer = response.data
              } else {
                that.empty = true
              }
              that.loading  = false

            }).catch(error => {
              console.log(error)
              that.loading = false
            })

          }

        },

      } // end methods

    }

</script>