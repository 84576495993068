<template>

  <div class="newPasswordsRows">

    <div class="form-group row">
      <div class="col-md-2">
        <label for="password">{{ $t('message.login_password') }}*</label>
      </div>
      <div class="col-md-10">
        <input type="password"
               maxlength="100"
               class="form-control"
               :class="{ 'is-invalid': !isPasswordValid || !isPasswordsSame }"
               id="password"
               name="password"
               :placeholder="$t('message.login_password')"
               required
               v-model="password">
        <div class="invalid-feedback">
          <span v-if="!isPasswordValid">{{ text1 }}</span>
          <span v-if="!isPasswordsSame">{{ text2 }}</span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-2">
        <label for="password2">{{ $t('message.newPasswordFormRow_passwort_wiederholen') }}*</label>
      </div>
      <div class="col-md-10">
        <input type="password"
               class="form-control"
               :class="{ 'is-invalid': !isPassword2Valid || !isPasswordsSame }"
               id="password2"
               name="password2"
               required
               maxlength="100"
               :placeholder="$t('message.login_password')"
               v-model="password2">
        <div class="invalid-feedback">
          <span v-if="!isPassword2Valid">{{ text1 }}</span>
          <span v-if="!isPasswordsSame">{{ text2 }}</span>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {

  name: 'NewPasswordFormRow',
  props: [
    /** soll die Passwortsicherheit erhöht werden? */
    'useSavePassword'
  ],
  data () {
    return {
      password: null,
      password2: null,
      text1: this.useSavePassword ? this.$t('message.newPasswordFormRow_rule2') : this.$t('message.newPasswordFormRow_rule1'),
      text2: this.$t('message.newPasswordFormRow_passwort_error'),
    }
  },
  computed: {

    /** prüft ob das Passwort eingegeben wurde */
    isPasswordValid: function () {

      if (this.password && this.password !== '') {
        return this.checkPasswordRules(this.password);
      }

      return true;

    },
    /** prüft ob das Passwort 2 eingegeben wurde */
    isPassword2Valid: function () {

      if (this.password2 && this.password2 !== '') {
        return this.checkPasswordRules(this.password2);
      }

      return true;

    },
    /** prüft ob die beiden Passwörter übereinstimmen */
    isPasswordsSame: function () {

      if (this.password === this.password2) return true
      return false

    }

  },
  watch: {
    /** eingegebenes Passwort an Parent zurückgeben */
    password2: function (val) {
      this.emitPassword(val);
    },
    password: function (val) {
      this.emitPassword(val);
    },
    useSavePassword: function () {
      this.init()
    },
  },
  methods: {

    mounted: function () {
      this.init()
    },
    init: function () {
      this.text1 = this.useSavePassword ? this.$t('message.newPasswordFormRow_rule2') : this.$t('message.newPasswordFormRow_rule1')
    },
    /** Passwortrichtlinie prüfen */
    checkPasswordRules: function (password) {

      if (this.useSavePassword && password.length < 16) {
        return false
      } else if (password.length < 8) {
        return false
      }

      /** Regular Expressions mit enthaltenen Zeichen in einem Passwort */
      var regex = [ '[A-Z]', '[a-z]', '[0-9]', '[$@$!%*#?&]' ];

      // prüfe Passwort Richtlinien:
      var passed = 0;
      // Validate for each Regular Expression.
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          console.log(regex[i] + ' ist ok')
          passed++
        }
      }

      if (passed < 4) {
        return false
      }

      return true

    },
    /**
     * Passwort an Parent zurückgeben
     */
    emitPassword: function (val) {

      if (this.isPasswordsSame && this.isPassword2Valid && this.isPasswordValid) {
        this.$emit('set-password', val);
      } else {
        this.$emit('set-password', null);
      }

    }

  }

}

</script>
