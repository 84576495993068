<template>

    <span v-if="value" class="customerNotificationStatusWrapper">
        <b-badge style="background-color: #73b025"
                 v-if="value.name == 'ABGESCHLOSSEN'">
            {{ $t('message.customerNotificationStatus_abgeschlossen') }}
        </b-badge>
        <b-badge style="background-color: #eda512"
                 v-else-if="value.name == 'BEARBEITET'">
            {{ $t('message.customerNotificationStatus_bearbeitet') }}
        </b-badge>
        <b-badge style="background-color: #e3150f"
                 v-else-if="value.name == 'OFFEN'">
            {{ $t('message.customerNotificationStatus_offen') }}
        </b-badge>
              <b-badge style="background-color: #e3150f"
                       v-else-if="value.name == 'UNGEPRUEFTE_247_BILDER'">
            {{ $t('message.customerNotificationStatus_offen') }}
        </b-badge>
    </span>

</template>

<script>

    export default {

      name: 'CustomerNotificationStatus',
      props: ['value']

    }

</script>