<template>

    <span class="keyUserOfUserWrapper" v-if="keyUsers">
        {{ benutzer }}
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import $ from 'jquery';

    export default {
      name: 'KeyUserOfUser',
      props: ['value'],
      data () {
        return {
          keyUsers: null,
          benutzer: null
        }
      },
      watch: {
        value: function () {
          this.init();
        }
      },
      created () {

        this.init();

      },
      methods: {

        init: function () {

          if (this.value) {

            var url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/getKeyUserOfUser';
            var params  = {
              user_id:  this.value.id
            };

            var that    = this;
            axios({
              method: 'post',
              params: params,
              url: url
            }).then(function (response) {

              if (!response.data.error) {

                that.keyUsers = response.data;

                if (that.keyUsers) {

                  var str = '';

                  for (var i = 0; i < that.keyUsers.length; i++) {

                    var item = '';
                    if (that.keyUsers[i].vorname) {
                      item = that.keyUsers[i].vorname;
                    }
                    if (that.keyUsers[i].nachname) {
                      item += ' ' + that.keyUsers[i].nachname;
                    }
                    item += ' ' + that.keyUsers[i].username;
                    if (i > 0) {
                      str += ', ' + $.trim(item);
                    } else {
                      str = item;
                    }

                  }

                  that.benutzer = $.trim(str);

                }

              } else {
                console.log(response.data.error);
                that.keyUsers = null;
              }

            }).catch(error => {
              console.log(error);
            });
          }

        }

      }

    }

</script>
