<!-- rot, grün, gelb, ... Alarme anzeigen -->
<template>

    <span class="alarmLevelIcon" v-if="alarm">
        <b-button :variant="color">&nbsp;</b-button>
    </span>

</template>

<script>

    export default {
      name: 'AlarmLevelIcon',
      props: ['alarm'],
      computed: {

        color: function () {

          if (this.alarm) {

            let name = null
            if (typeof this.alarm === 'string') {
              name = this.alarm
            } else {
              name = this.alarm.name
            }

            if (name == 'GRUEN') {
              return 'success'
            }
            if (name == 'ROT') {
              return 'danger'
            }
            if (name == 'GELB') {
              return 'warning'
            }
          }
          return 'secondary'

        }

      }

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .btn {
        min-height: 25px;
    }

</style>
