<!-- rot, grün, gelb, ... Alarme anzeigen und das Ergebnis -->
<template>

  <span class="sicherheitdatenblaetterwrapper">

    <b-overlay :show="showOverlay" rounded="sm" style="min-height: 300px">

      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon" />
          <p id="cancel-label">{{ $t('message.sicherheitsdatenblaetterList_loading') }}...</p>
        </div>
      </template>

        <div id="filter" class="row pb-2">

          <div class="col-md-8">

            <b-input-group size="sm">
              <b-form-input
                  v-model="keyword"
                  type="search"
                  id="filterInput"
                  :placeholder="$t('message.positionen_filter')" />
              <b-input-group-append>
                <b-button :disabled="!keyword" @click="keyword = ''"><b-icon-x></b-icon-x></b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
          <div class="col-md-3"></div>
          <div class="col-md-1 text-right">

            <b-form-group horizontal label="" class="">
              <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
            </b-form-group>

          </div>

        </div>
        <div id="sdbTable">

          <div class="card-box">
            <b-table small
                     hover
                     :items="items"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :keyword="keyword">

              <template v-slot:cell(buttons)="row">
                <SicherheitsdatenblaetterLink :article_id="row.item.id" />
              </template>

            </b-table>
          </div>

          <b-row>
            <b-col md="12" class="my-1">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  class="my-0">
              </b-pagination>
            </b-col>
          </b-row>

        </div>

      </b-overlay>

    </span>

</template>

<script>

    import SicherheitsdatenblaetterLink from '@/components/ergebnisliste/Sicherheitsdatenblaetter';
    import axios from "axios";

    export default {
      name: 'SicherheitsdatenblaetterList',
      components: {
        SicherheitsdatenblaetterLink
      },
      props: [],
      data () {
          return {
            sortBy: 'datum',
            sortDesc: true,
            /** Filter Query String */
            keyword: '',
            fields: [
              {
                label: this.$t('message.sicherheitsdatenblaetterList_artikel'),
                key: 'itemName',
                sortable: true
              },
              {
                label: '',
                key: 'buttons',
                sortable: false,
                class: 'text-right'
              }
            ],
            sicherheitsdatenblaetter: null,
            //totalRows: null,
            currentPage: null,
            /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
            pageOptions: [ 5, 10, 15, 20, 50, 100 ],
            /** ausgewählter Wert aus den pageOptions */
            perPage: 20,
            showOverlay: false
          }
      },
      computed: {

        // a computed getter
        items: function () {

          if (this.sicherheitsdatenblaetter) {

            var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

            var selectedRows = lowercase
                ? this.sicherheitsdatenblaetter.filter(item =>

                    (item.itemName && item.itemName.toLowerCase().includes(lowercase))

                ) : this.sicherheitsdatenblaetter;

            return selectedRows;

          }
          return null;

        },
        totalRows: function () {
          return this.items ? this.items.length : 0;
        }

      },
      created () {

        this.init();

      },
      methods: {

        init: function() {

          this.showOverlay = true;
          var that     = this;
          var url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getSicherheitsdatenblaetter';
          var customer = this.$store.getters.customer;
          var params   = {
            customer: JSON.stringify(customer)
          };
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            that.sicherheitsdatenblaetter = response.data;
            that.currentPage              = 1; //Pagination auf 1 Seite setzen
            that.showOverlay = false;

          }).catch(error => {
            console.log(error);
            that.showOverlay = false;
          });

        }

      }

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .btn {
        width: 100%;
    }

</style>
