<template>

    <!-- Achtung Texte auch mit ReportService.generateErgebnislisteReport abgleichen, falls es hier Änderungen gibt -->
    <!-- ========================================================================================================== -->
    <div v-if="wert">
      <!-- unbewertetes Traplinked Image -->
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-if="wert == 'IMAGE_RECEIVED' && !alarmLevel"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'TRIGGERED' || wert == 'TRAP_TRIGGERED'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'INACTIVE' || wert == 'OFFLINE'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'HEARTBEAT_FAILED'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'IMAGE_RECEIVED'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'LOW_BATTERY' || wert == 'BATTERY_LOW'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'LOCATION_MODE'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'UNSTABLE_WIFI'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'warning'"
               show
               v-else-if="wert == 'ACTIVITY_CRITICAL'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'danger'"
               show
               v-else-if="wert == 'INFESTED' || wert == 'CATCH_DETECTED'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'danger'"
               show
               v-else-if="wert == 'LIGHT_INFESTATION'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'danger'"
               show
               v-else-if="wert == 'SERVERE_INFESTATION'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'danger'"
               show
               v-else-if="wert == 'ACTIVITY_WARNING'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'success'"
               show
               v-else-if="wert == 'NULL' || (value && value.name == 'ACTIVE') || value == 'ACTIVE'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
      <b-alert :id="'traplinked-ergebnis-' + id"
               :variant="variant ? variant : 'success'"
               show
               v-else-if="wert == 'REARMED'"
               style="padding: 0rem 1rem;">
        {{ text }}
      </b-alert>
  </div>

</template>
<script>

  // Define a new component
  export default {

    name: 'TraplinkedErgebnis',
    props: [
        /** value = deviceStatus */
        'value',
        /** wenn Report vorhanden der ReportType */
        'reportType',
        'eventType',
        /** vorgegebener Alarm Level */
        'alarmLevel',
        /** ID des Datensatzes */
        'id'
    ],
    data: function () {
      return {
        wert: null,
        /** Text der für den User angezeigt wird */
        text: null
      }
    },
    computed: {

      variant: function () {
        if (this.alarmLevel) {
          if (this.alarmLevel == 'GRUEN')   return 'success';
          if (this.alarmLevel == 'GELB')    return 'warning';
          if (this.alarmLevel == 'WARNING') return 'danger';
        }
        return  null;
      }

    },
    watch: {

      value: function () {
        this.init();
      },
      reportType: function () {
        this.init();
      },
      eventType: function () {
        this.init();
      }

    },
    mounted: function () {

      this.init();

    },
    methods: {

      /**
       * Ergebnis Text erzeugen und an Parent emitten
       */
      getErgebnisTextAndEmit: function (val) {

        const text = this.getErgebnisText(val);
        // console.log('Text zu val=' + val + ' = ' + text);
        // emit den Text für Parent Components zur Filterung
        const obj = {
            text: text,
            id: this.id
        }
        this.$emit('set-ergebnis-text', obj);

        this.text = text;

      },
      init: function () {

        this.wert = null;
        if (this.reportType && this.reportType.name) {

          this.wert = this.reportType.name;

        } else if (this.reportType) {

          this.wert = this.reportType;

        } else if (this.eventType && this.eventType.name) {

          this.wert = this.eventType.name;

        } else if (this.eventType) {

          this.wert = this.eventType;

        } else if (this.value && this.value.name) { // in report Type kann auch null sein, z. B. bei WLAN Repeatern von Traplinked, dort gibt es keine Ergebnisse

          this.wert = this.value.name;

        } else if (this.value) { // Ergebnis kommt aus Cache

          this.wert = this.value;

        } else {

          this.wert = null;

        }
        if (this.wert) {
          //console.log("this.wert = " + this.wert);
          this.getErgebnisTextAndEmit(this.wert);
        }

      }

    }
  }

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alert {
    text-align: center;
  }
</style>