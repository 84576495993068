<template>
  <div>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>Biotech Analytics</h1>
          </div>
        </div>

      </div>
      <div class="container mt-5 pb-5">
        <div class="row" v-if="showIeWarning">
          <div class="col-md-12">

            <b-alert show variant="danger">
              <h3 class="alert-heading">{{ $t("message.welcome_browser_veraltet") }}</h3>
              <p class="text-center">
                  {{ $t("message.welcome_browser_aktualisieren") }}
              </p>
              <hr>
              <p class="mb-0 text-center">
                [ <a href="https://browsehappy.com/">{{ $t("message.welcome_browser_herunterladen") }}</a> ]
              </p>
            </b-alert>

          </div>
        </div>
        <div class="row mt-5 pt-5">

          <div class="col-md-3"></div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <img class="card-img-top" src="../assets/images/biotech-analytics-logo.png" alt="Biotech Analytics">
              </div>
              <div class="card-body">
                <h4>{{ $t("message.welcome_neue_funktionen") }}</h4>
                <ul v-html='$t("message.welcome_neue_funktionen_list")'></ul>
              </div>
              <div class="card-footer">
                <button-login></button-login>
              </div>
            </div>
          </div>
          <div class="col-md-3"></div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>

  import buttonLogin from '@/components/layout/ButtonLogin'

  export default {
    name: 'Welcome',
    components: {
      buttonLogin
    },
    data () {
      return {
        showIeWarning: false
      }
    },
    created () {

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name)

      if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        this.showIeWarning = true
      }

      // loggedInAs auf null setzen:
      this.$store.commit('setLoggedInAs', null)

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  h2 {
    font-size: 2rem;
  }
  h4 {
    text-align: left;
    font-size: 1.2rem;
    text-transform: none;
    letter-spacing: normal;
  }
  .card {
    height: 100%;
  }

</style>
