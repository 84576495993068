<template>

    <div class="lineTempSensor" v-if="value">

        <div class="row mt-1 mb-3 ml-2">

          <div class="col">

            <b-button variant="outline-primary"
                      @click="showX = !showX"
                      size="sm"
                      :pressed="showX"
                      v-b-tooltip.hover
                      :title="showX ? $t('message.sensor_hide_x') : $t('message.sensor_show_x')">
              <b-icon icon="calendar-date" />
            </b-button>

            <!-- Taupunkt ein/ausblenden -->
            <b-button variant="outline-primary"
                      class="ml-2"
                      :pressed="showTaupunkt"
                      v-if="type && type == 'TEM'"
                      @click="showTaupunkt = !showTaupunkt"
                      size="sm"
                      v-b-tooltip.hover
                      :title="showTaupunkt ? $t('message.sensor_taupunkt_ausblenden') : $t('message.sensor_taupunkt_anzeigen')">
              <b-icon icon="droplet" />
            </b-button>

            <!-- Schwellenwerte ein/ausblenden -->
            <b-button variant="outline-primary"
                      class="ml-2"
                      :pressed="showSchwellenwert"
                      v-if="type && (type == 'TEM' || type == 'HUM')"
                      @click="showSchwellenwert = !showSchwellenwert"
                      size="sm"
                      v-b-tooltip.hover
                      :title="showSchwellenwert ? $t('message.sensor_schwellenwert_ausblenden') : $t('message.sensor_schwellenwert_anzeigen')">
              <b-icon icon="list" />
            </b-button>

          </div>

        </div>

        <div class="lineTempSensorWrapper" v-if="chartdata">

          <div class="chart-wrapper" >
             <line-chart
                     v-if="loaded"
                     v-on:set-chart="setChart"
                     :chartdata="chartdata"
                     :options="options"
             />
          </div>

          <!-- Legende -->
          <div class="legende mt-2 ml-3" v-if="type && type != 'DEW'">
            <ul v-bind:style="{ listStyleImage: 'url(' + listImageGruen + ')', marginBottom: 0 }"
                style="float: left">
              <li style="font-size: 0.9rem">{{ $t('message.sensor_charts_ok') }}</li>
            </ul>
            <ul v-bind:style="{ listStyleImage: 'url(' + listImageGelb + ')', marginBottom: 0 }"
                style="float: left">
              <li style="font-size: 0.9rem">{{ $t('message.sensor_charts_warnung') }}</li>
            </ul>
            <ul v-bind:style="{ listStyleImage: 'url(' + listImageRot + ')' }"
                style="float: left">
              <li style="font-size: 0.9rem">{{ $t('message.sensor_charts_gefahr') }}</li>
            </ul>
          </div>

        </div>

        <div v-else-if="!chartdata && !loaded" class="text-center">
            <SpinnerImage />
        </div>

        <b-alert show v-if="setNoData">{{ $t('message.charts_nicht_genuegend_ergebnisse') }}</b-alert>

    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import LineChart from '@/components/charts/LineChart'
    import SpinnerImage from '@/components/layout/SpinnerImage'
    import moment from "moment/moment";

    export default {
      name: 'LineTempSensor',
      components: {
        LineChart,
        SpinnerImage,
      },
      props: [
          /** value = Sensor */
          'value',
          /** TEM für Temperatur, HUM für Luftfeuchtigkeit, DEW für Taupunkt */
          'type',
          'jahr',
          'monat',
          'woche',
          /** Tag ist ein moment Datum für Tagesansicht */
          'tag',
      ],
      data () {
        return {
          /** noch kein Ergebnis verfügbar - true setzen */
          setNoData: false,
          loaded: false,
          /** Daten für den Chart */
          chartdata: null,
          options: null,
          areasAllowed: null,
          listImageGruen: require('@/assets/images/list-style-plan-gruen.gif'),
          listImageGelb:  require('@/assets/images/list-style-plan-gelb.gif'),
          listImageRot:   require('@/assets/images/list-style-plan-rot.gif'),
          start: null,
          end: null,
          /** das Jahr für den die Chartdaten angezeigt werden sollen */
          year: null,
          /** das Monat für das die Chartdaten angezeigt werden sollen */
          month: null,
          /** der Tag als moment Objekt für das die Chartdaten angezeigt werden sollen */
          day: null,
          /** wenn true, dann den Taupunkt in der Temperatur anzeigen */
          showTaupunkt: false,
          /** X-Achse einblenden/ausblenden */
          showX: false,
          /** Schwellenwerte ein/ausblenden */
          showSchwellenwert: false,
        }
      },
      watch: {
        value: function () {
            this.init()
        },
        monat: function () {
          this.init()
        },
        tag: function () {
          this.init()
        },
        woche: function () {
          this.init()
        },
        showTaupunkt: function () {
          this.init()
        },
        showSchwellenwert: function () {
          this.init()
        },
        showX: function (val) {

          console.log('showX', val)
          this.chart.options.
                scales.xAxes = [{
                    display: val
                }]
          this.chart.update()

        }
      },
      created: function () {

        this.init()

      },
      methods: {

        init: async function () {

          this.setNoData = false
          this.loaded = false
          if (this.value && this.value.id) {

            this.year = this.jahr
            if (!this.year) this.year = new Date().getFullYear()
            this.month = this.monat
            if (!this.month) this.month = new Date().getMonth() + 1

            // ich brauche hier die Areas von allen Standorten des Benutzers:
            this.areasAllowed = null
            if (this.$store.getters.showAreas && this.$store.getters.showAreas.length > 0) {
              this.areasAllowed = this.$store.getters.showAreas.join(',')
            }

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getChartDataOfSensor';
            var params  = {
              sensor_id:            this.value.id,
              type:                 this.type,
              areasAllowed:         this.areasAllowed,
              customer_id:          this.$store.getters.customer.id,
              year:                 this.year,
              lang:                 this.$i18n.locale,
              showTaupunkt:         this.showTaupunkt,
              showSchwellenwerte:   this.showSchwellenwert,
            }
            if (this.tag) {

              params.start = this.tag.format('DD.MM.YYYY')
              params.end   = this.tag.format('DD.MM.YYYY')

            } else if (this.woche) {

              var dat = moment().year(this.year).week(this.woche)
              var monday = dat.clone().weekday(0)
              var sunday = dat.clone().weekday(6)
              console.log('monday', monday)
              console.log('sunday', sunday)

              params.start = monday.format('DD.MM.YYYY')
              params.end   = sunday.format('DD.MM.YYYY')

            } else {
              params.month  = this.month
            }

            var that = this;
            await axios({
              method: 'post',
              params: params,
              url: url
            }).then(function (response) {

              console.log('response sensor chart: ', response)

              if (response.data && response.data.chartdata && response.data.chartdata.do_not_show_chart) {
                const obj = {
                  sonsor_id: that.value.id
                }
                that.$emit('do-not-show-chart', obj)
              } else if (response.data) {

                that.chartdata = response.data.chartdata
                if (that.chartdata) {

                  that.start     = that.chartdata.start
                  that.end       = that.chartdata.end

                  var datasets = that.chartdata.datasets;
                  if (datasets) {

                    var hasTicks = Object.keys(that.chartdata.ticks).length > 0;
                    // kalkuliere max y Wert, falls Required Text:
                    var max = 0
                    if (that.chartdata.ticks && hasTicks) {
                      for (const [key, value] of Object.entries(that.chartdata.ticks)) {
                        console.log(key, value)
                        if (key > max) max = key
                      }
                    }

                    var tooltips = null;
                    var ticks = null;
                    if (that.chartdata.ticks && hasTicks) {

                      console.log("chart hat ticks: " + that.chartdata.ticks.length)
                      // wenn bei den ticks kein -1 dabei ist, dann beginAtZero auf true setzen
                      var hasMinusEinsTick = false
                      for (const [key] of Object.entries(that.chartdata.ticks)) {
                        if (key == -1) {
                          hasMinusEinsTick = true
                          break
                        }
                      }

                      ticks =
                          {
                            beginAtZero: hasMinusEinsTick ? false : true,
                            callback: function (value) {
                              if (that.chartdata && that.chartdata.ticks) {
                                console.log("get from ticks" + that.chartdata.ticks[value]);
                                return that.chartdata.ticks[value];
                              }
                              console.log('get value = ' + value + ' ' + that.chartdata.ticks);
                              return value;
                            },
                            min: hasMinusEinsTick ? -1 : 0,
                            max: max
                          }

                      tooltips = {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            console.log('tooltipItem: ' + tooltipItem);
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
                            label += ": " + that.chartdata.ticks[tooltipItem.yLabel];
                            return label;
                          }
                        }
                      }

                    }

                    that.options =
                        {
                          responsive: true,
                          title: {
                            display: false,
                            // text: that.javaDateToDefaultDateFormat(that.start) + ' - ' + that.javaDateToDefaultDateFormat(that.end)
                          },
                          legend: {
                            display: false
                          },
                          hover: {
                            mode: 'index'
                          },
                          scales: {
                            xAxes: [{
                              display: that.showX
                            }],
                            yAxes: [{
                              display: true,
                              // scaleLabel: {
                              //   display: true,
                              //   labelString: that.$t('message.sensor_temp')
                              // },
                            }]
                          },
                          maintainAspectRatio: false,
                        }

                    if (hasTicks) {
                      that.options.tooltips = tooltips;
                      that.options.scales.yAxes[0].ticks = ticks;
                    } else {
                      // that.options.scales.yAxes[0].ticks = {
                      //   beginAtZero: true,
                      //   min: 0
                      // }
                    }

                  } else {
                    that.chartdata = null
                    that.setNoData = true
                  }

                } else { // end if that.chartdata
                  that.setNoData = true
                }

              } else {
                that.chartdata = null
                that.setNoData = true
              }
              that.loaded = true

            }).catch(error => {
              console.log(error)
              that.loaded = true
              that.setNoData = true
            })

          }

        },
        /**
         * Chart des Parents zurückliefern um Klick Events zu behandeln
         */
        setChart: function (obj) {
          console.log('set chart: ', obj);
          this.chart = obj;
        },

      },

    }

</script>