<!-- Details über eine Position -->
<template>

    <transition name="fade" mode="out-in">
        <div v-if="positionDetails">

            <div class="row">
                <div class="col" v-if="positionDetails.position && positionDetails.position.area">
                    <div class="row">
                        <div class="col-sm-2"><label>{{ $t('message.printPositionen_bereich') }}</label></div>
                        <div class="col-sm-10">{{ positionDetails.position.area.beschreibung }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-sm-12">
                    <b-alert variant="info" show v-if="positionDetails.position && positionDetails.position.staysTemp">
                        {{ $t('message.positionDetails_zusatzleistung') }}
                    </b-alert>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion mt-3" role="tablist">

              <!-- Chart Accordion -->
              <b-card no-body v-if="!errormessage">
                <b-card-header header-tag="header"
                               class="p-1"
                               role="tab"
                               v-if="hasCharts">
                  <div class="row" v-b-toggle.accordion-1 style="cursor: pointer; outline: none;">
                    <div class="col">
                      <label class="ml-3 pt-1">{{ $t('message.positionDetails_charts') }}</label>
                    </div>
                    <div class="col text-right">
                      <b-icon icon="caret-down" font-scale="2"></b-icon>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-1"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                            v-if="hasCharts">
                  <b-card-body>

                    <div class="row mt-3">

                      <!-- Charts nach Tasks -->
                      <div class="col-md-6"
                           v-for="(lastTaskErgebnis, index) in lastTaskErgebnisseFiltered"
                           :key="lastTaskErgebnis.task.id">
                        <div class="row mb-5">
                          <div class="col-sm-2">
                              <label>
                                  {{ $t('message.printErgebnisliste_aufgabe') }}: {{ lastTaskErgebnis.task.name }}
                              </label>
                          </div>
                          <div class="col-md-10">

                              <LineStackedAreaChartAlarmeTaskResult :task-ergebnis-id="lastTaskErgebnis.taskErgebnis.id"
                                                                    v-on:no-data="setHidden(index)"
                              />

                          </div>
                        </div>
                      </div>

                      <!-- zusammenfassender Chart der Traplinked Devices -->
                      <div class="col-md-6" v-if="showDevices && traplinkedDevices && traplinkedDevices.length > 0">
                        <div class="row mb-5">
                          <div class="col-sm-2">
                            <label>BioTech 24/7</label>
                          </div>
                          <div class="col-md-10">

                            <bar-chart-alarme-traplinked-device-list
                                v-model="position_id"
                                :traplinked-devices="traplinkedDevices"
                                v-on:do-not-show-chart="hideDevices"
                            />

                          </div>
                        </div>
                      </div>

                      <!-- Charts der Sensoren -->
                      <div class="col-md-6"
                           v-for="(sensorCustomer, index) in sensorCustomerList"
                           :key="sensorCustomer.sensor.id">
                        <div class="row mb-5">
                          <div class="col-sm-2">
                            <label>
                              BioTech 24/7 Sensor
                            </label>
                          </div>
                          <div class="col-md-10">

                            <LineTempSensorList v-model="sensorCustomerList[index]" />

                          </div>
                        </div>
                      </div>

                    </div>

                  </b-card-body>
                </b-collapse>
              </b-card>
              <div v-else class="mt-4 mb-4">
                <b-alert show variant="warning">{{ errormessage }}</b-alert>
              </div>

              <!-- Objekte Accordion -->
              <b-card no-body v-if="objekte && objekte.length > 0">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="row" v-b-toggle.accordion-2 style="cursor: pointer; outline: none;">
                    <div class="col">
                      <label class="ml-3 pt-1">{{ objekte && objekte.length > 0 ? objekte.length : '' }} {{ $t('message.positionDetails_installierteObjekte') }}</label>
                    </div>
                    <div class="col text-right">
                      <b-icon icon="caret-down" font-scale="2"></b-icon>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{{ $t('message.default_bezeichnung') }}</th>
                          <th scope="col">{{ $t('message.positionDetails_seriennummer') }}</th>
                          <th scope="col">{{ $t('message.positionDetails_statusErgebnis') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="objekt in objekte" :key="objekt.id">
                          <th scope="row">
                            {{ objekt.objectId }}
                          </th>
                          <td>
                            {{ objekt.bezeichnung }}
                          </td>
                          <td>
                            <traplinked-id-of-objekt :objekt="objekt" :position="positionDetails.position" />
                          </td>
                          <td>
                            <objekt-status :objekt="objekt" :position-id="value" style="max-width: 150px;" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Artikel Accordion -->
              <b-card no-body v-if="hasArtikel">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="row" v-b-toggle.accordion-3 style="cursor: pointer; outline: none;">
                    <div class="col">
                      <label class="ml-3 pt-1">{{ hasArtikel && hasArtikel > 0 ? hasArtikel : '' }} {{ $t('message.sicherheitsdatenblaetterList_artikel') }}</label>
                    </div>
                    <div class="col text-right">
                      <b-icon icon="caret-down" font-scale="2"></b-icon>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <artikel-details v-model="positionDetails.position" v-on:has-artikel="setHasArtikel"/>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Mängel Accordion -->
              <b-card no-body v-if="maengel && maengel.length > 0">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="row" v-b-toggle.accordion-4 style="cursor: pointer; outline: none;">
                    <div class="col">
                      <label class="ml-3 pt-1">{{ $t('message.printMaengel_h1') }}</label>
                    </div>
                    <div class="col text-right">
                      <b-icon icon="caret-down" font-scale="2"></b-icon>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>

                    <maengel-table :maengellist="maengel" v-on:mangel-prioritaet-changed="mangelPrioritaetChanged" />

                  </b-card-body>
                </b-collapse>
              </b-card>

            </div>

            <hr class="mt-3 mb-3" />

            <!-- Actions -->
            <div class="row" v-if="editPlanRight && (editable == null || editable == true)">
                <div class="col text-right">
                    <b-button variant="outline-danger" size="sm" @click="removePositionFromPlan()">
                        <b-icon icon="trash"></b-icon> {{ $t('message.positionDetails_pos_entfernen') }}
                    </b-button>
                </div>
            </div>

        </div>
    </transition>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import LineStackedAreaChartAlarmeTaskResult from '@/components/charts/LineStackedAreaChartAlarmeTaskResult';
    import ArtikelDetails from '@/components/plan/ArtikelDetails';
    import BarChartAlarmeTraplinkedDeviceList from "@/components/charts/BarChartAlarmeTraplinkedDeviceList";
    import TraplinkedIdOfObjekt from "@/components/traplinkedbericht/TraplinkedIdOfObjekt";
    import ObjektStatus from "@/components/ergebnisliste/ObjektStatus";
    import MaengelTable from "@/components/maengelliste/maengelTable";
    import LineTempSensorList from "@/components/sensor/LineTempSensorList.vue";

    // Define a new component
    export default {
        name: 'PositionDetails',
        components: {
          LineTempSensorList,
          ArtikelDetails,
          LineStackedAreaChartAlarmeTaskResult,
          BarChartAlarmeTraplinkedDeviceList,
          TraplinkedIdOfObjekt,
          ObjektStatus,
          MaengelTable
        },
        props: [
          /* die ID der Position die angezeigt werden soll */
          'value',
           /** Position ist nicht änderbar wenn false */
          'editable',
        ],
        /*
          Defines the data used by the component
        */
        data: function(){
            return {
              positionDetails: null,
              /** Liste mit letzten Taskergebnissen an der Position */
              lastTaskErgebnisse: null,
              /** Objekte der Position */
              objekte: null,
              /** wird auf false gesetzt, falls an der Position keine Artikel sind */
              hasArtikel: true,
              /** Traplinked Fallen an dieser Position */
              traplinkedDevices: null,
              /** Fehlerhinweis für den Benutzer, falls z. B. keine Objekte an der Position sind */
              errormessage: null,
              /** 247 Device Chart anzeigen */
              showDevices: true,
              /** unerledigte Mängel an dieser Position */
              maengel: null,
              /** um value nicht direkt für andere Komponenten zu verwenden */
              position_id: null,
              /** Sensoren/Customer Objekte an dieser Position */
              sensorCustomerList: null,
            }
        },
        mounted: function () {

          this.init()

        },
        watch: {

          value: function () {
            this.init()
          }

        },
        computed: {

          hasCharts: function () {
            return (this.lastTaskErgebnisse && this.lastTaskErgebnisse.length > 0) ||
                   (this.traplinkedDevices && this.traplinkedDevices.length > 0) ||
                   (this.sensorCustomerList && this.sensorCustomerList.length > 0)
          },
          /** filtere die Tasks heraus die nicht angezeigt werden sollen */
          lastTaskErgebnisseFiltered: function () {

            return this.lastTaskErgebnisse ?
                this.lastTaskErgebnisse.filter( item =>

                    !item.hidden

                ) :
                null

          },
          /** darf der Benutzer den Plan ändern? */
          editPlanRight: function () {

            return this.editPlan();

          }

        },
        methods: {

          setHidden: function (index) {

            this.$set(this.lastTaskErgebnisse[index], 'hidden', true);

          },
          /** Priorität des Mangels wurde in Detailansicht geändert, an Parent weitergeben */
          mangelPrioritaetChanged: function (mangel) {

            this.$emit('mangel-prioritaet-changed', mangel);

          },
          /** den 247 Chart ausblenden */
          hideDevices: function (val) {
            if (val) {
              this.showDevices = false;
            }
          },
          /** Position aus Plan entfernen */
          removePositionFromPlan: function () {

            // an Parent weitergeben und dort verarbeiten:
            this.$emit('remove-position-from-plan', this.value);

          },
          /** true oder false ob an Position Artikel sind */
          setHasArtikel: function (val) {
            this.hasArtikel = val;
          },
          init: function () {

            this.position_id = this.value;

            this.objekte            = null;
            this.positionDetails    = null;
            this.lastTaskErgebnisse = null;
            this.traplinkedDevices  = null;
            this.maengel            = null;
            this.sensorCustomerList = null;

            if (this.value) {

              this.showSpinner();
              console.log ('init position details ' + this.value);

              this.positionen   = [];
              this.selected     = null;
              var that          = this;
              var url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionDetails';
              var params        = {
                'position_id': this.value
              };
              axios ({
                method:       'post',
                params:       params,
                url:          url,
                cancelToken:  that.cancelTokenSource ? that.cancelTokenSource.token : null
              }).then(function (response) {

                that.positionDetails = response.data;
                var title = 'Position';
                if (that.positionDetails && that.positionDetails.position) {
                  title += ' ' + that.positionDetails.position.positionsNr;
                }
                if (that.positionDetails && that.positionDetails.position) {
                  title += ' ' + that.positionDetails.position.positionsBesch;
                }
                that.$emit('position-modal-title', title);
                that.hideSpinner();

              })

              // hole letzte Task Ergebnis Liste
              url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getLastTaskErgebnisseOfPosition';
              params        = {
                'position_id': this.value
              };
              axios ({
                method: 'post',
                params: params,
                url:    url
              }).then(function (response) {

                that.lastTaskErgebnisse = response.data;

              })

              // hole die Objekte an dieser Position
              url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getObjekte';
              params        = {
                'position_id': this.value
              };
              axios ({
                method: 'post',
                params: params,
                url:    url
              }).then(function (response) {

                if (response.data && response.data.error) {
                  // es sind keine Objekte vorhanden, nichts anzeigen
                  that.errormessage = response.data.message;
                } else if (response.data) {
                  that.objekte = response.data.objekte;
                }

              })

              // hole die Traplinked Devices an dieser Position
              url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedDevices';
              params        = {
                'position_id': this.value,
                'last_monate': 6
              };
              axios ({
                method: 'post',
                params: params,
                url:    url
              }).then(function (response) {

                that.traplinkedDevices = response.data.traplinkedDevices;

              })

              // hole die Mängel an dieser Position
              url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getMaengel';
              params        = {
                'position_id': this.value,
                'status':      'OFFEN'
              };
              axios ({
                method: 'GET',
                params: params,
                url:    url
              }).then( response => {

                if (!response.data.error) {
                  that.maengel = response.data;
                } else {
                  console.log('error' + response.data.error);
                }

              })

              // hole die Sensoren an dieser Position
              url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getSensoren';
              params        = {
                position_id: this.value
              };
              axios ({
                method: 'GET',
                params: params,
                url:    url
              }).then( response => {

                if (!response.data.error && response.data.sensorCustomer) {
                  this.sensorCustomerList = response.data.sensorCustomer
                } else {
                  console.log('error' + response.data.error)
                }

              })

            }

          }

        } // end methods

    }

</script>
