<!--
History eines Sensors anzeigen
-->
<template>

    <span v-if="value && value.id">

      <b-button variant="outline-info" size="sm" @click="showHistory()">
              <b-icon icon="clock-history"
                      v-b-tooltip.hover
                      :title="$t('message.deviceHistory_aeltere')">
              </b-icon>
      </b-button>

      <b-modal :id="'modal-sensor-' + value.id"
               :title="$t('message.sensor_vorherige')"
               :hide-footer="true">

        <span v-if="sensorHistory && sensorHistory.length > 0">

          <b-table striped
                   :items="sensorHistory"
                   :fields="fields"
                   :current-page="currentPage"
                   :per-page="perPage"
                   small
                   hover>

            <template v-slot:cell(timestamp)="row">
              <span v-if="row.item.timestamp">
                {{ javaDateToDefaultTimeFormat(row.item.timestamp) }}
              </span>
            </template>

            <template v-slot:cell(batteryStatus)="row">
              <span v-if="row.item.batteryStatus">
                <batterie-status v-model="row.item.batteryStatus" />
              </span>
            </template>

            <template v-slot:cell(alarmLevel)="row">

              <span v-if="row.item.temperatur">
                  <sensor-ergebnis v-model="row.item" :hide-chart="true" />
              </span>

            </template>

          </b-table>

          <b-row>
            <b-col md="12" class="my-1">
              <b-pagination :total-rows="totalRows"
                            :per-page="perPage"
                            v-model="currentPage"
                            class="my-0" />
            </b-col>
          </b-row>

        </span>
        <b-alert v-else variant="info" show>{{ $t('message.deviceHistory_noch_keine') }}</b-alert>

      </b-modal>

    </span>

</template>
<script>

  import BatterieStatus from '@/components/traplinkedbericht/BatterieStatus';
  import axios from 'axios';
  import SensorErgebnis from '@/components/sensor/SensorErgebnis.vue';

  // Define a new component
  export default {

    name: 'SensorHistory',
    components: {
      SensorErgebnis,
      BatterieStatus
    },
    props: ['value'], // der Sensor
    /*
      Defines the data used by the component
    */
    data: function () {
      return {
        /** Liste mit historischen Daten */
        sensorHistory: null,
        fields: [
          {
            key: 'timestamp',
            label: this.$t('message.traplinkedStatus_letzte_meldung'),
            sortable: true,
          },
          {
            key: 'batteryStatus',
            label: this.$t('message.traplinkedStatus_batterie'),
            sortable: true,
          },
          {
            key: 'alarmLevel',
            label: this.$t('message.printErgebnisliste_ergebnis'),
            sortable: true,
          },
        ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 10,
        /** Pagination der Ergebnistabelle */
        currentPage: 0,
        totalRows: 0
      }
    },
    watch: {

      value: function () {
        this.init()
      }

    },
    mounted() {
      this.init()
    },
    methods: {

      init: function () {
        this.currentPage = 0
        this.totalRows = 0
        this.sensorHistory = null
      },
      /** Modal öffnen um History anzuzeigen */
      showHistory: function () {

        if (this.value && this.value.id) {

          this.showSpinner();
          const customer = this.$store.getters.customer;
          const url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getSensorHistory';
          const params   = {
            customer_id:    customer.id,
            sensor_id:      this.value.id
          }

          this.sensorHistory = null;
          this.totalRows = 0;

          const that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(response => {

            console.log('response = ', response);
            that.hideSpinner();
            if (response.data) {

              that.sensorHistory = response.data.sensorResults;
              that.totalRows = that.sensorHistory.length;
              // modal öffnen
              console.log('öffne modal modal-sensor-' + that.value.id);
              that.$bvModal.show('modal-sensor-' + that.value.id);

            }

          }).catch(error => {
            console.log(error);
          })

        }

      }

    }

  }

</script>
