<template>

    <form>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.forgotPassword_h1") }}</h1>
          </div>
        </div>
      </div>
      <p class="mt-4">{{ $t("message.forgotPassword_p") }}</p>

      <b-alert variant="danger" show v-if="errormessage ? true : false">{{ errormessage }}</b-alert>

      <div class="form-group row">
        <div class="col-md-2">
          <label for="username">{{ $t("message.login_username") }}</label>
        </div>
        <div class="col-md-10">
          <input type="text"
                 maxlength="100"
                 class="form-control"
                 :class="{ 'is-invalid': !isUsernameValid }"
                 id="username"
                 name="username"
                 :placeholder='$t("message.login_username_ph")'
                 v-model="username">
          <div class="invalid-feedback">
              {{ $t("message.forgotPassword_username_invalid") }}
          </div>
        </div>
      </div>

      <captcha-form-row v-on:set-code="setCode" v-if="!successmessage"></captcha-form-row>

      <div class="form-group row" v-if="!successmessage">
        <div class="col-md-2"></div>
        <div class="col-md-10">
          <button type="button"
                  class="btn btn-outline-dark mr-2"
                  v-on:click="abbrechen">{{ $t("message.default_abbrechen") }}</button>
          <button type="button"
                  class="btn btn-outline-dark"
                  v-on:click="submitForm"
                  :disabled="!showSubmitButton">{{ $t("message.default_weiter") }}</button>
        </div>
      </div>
      <b-alert variant="success" show v-if="successmessage ? true : false">{{ successmessage }}</b-alert>

    </form>

</template>

<script>

import axios from 'axios';
axios.defaults.withCredentials = true;
import CaptchaFormRow from '@/components/layout/CaptchaFormRow.vue'

export default {

  name: 'ForgotPassword',
  data () {
    return {
      username: null,
      captcha: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null,
      /** Fehlermeldung nach dem Absenden der Passwort Anforderung */
      errormessage: null,
      /** Erfolgsmeldung wenn E-Mail erfolgreich gesendet wurde */
      successmessage: null
    }
  },
  components: {

    CaptchaFormRow

  },
  computed: {

    /** prüft ob der Benutzername eingegeben wurde */
    isUsernameValid: function () {

      if (this.username && this.username !== '' && this.username.length > 4) {
        return true;
      }

      return false;

    },
    /** kann der Weiter Button angezeigt werden? */
    showSubmitButton: function () {

      if (!this.code || this.code.length < 6) return false;
      if (!this.isUsernameValid) return false;
      return true

    }

  },
  mounted: function () {
    // Umami Tracking aufrufen
    this.doTracking(this.$route.name);
  },
  methods: {

    /** Passwort vergessen abbrechen - zurück zum Login */
    abbrechen: function () {

      this.$router.push('/Login');

    },
    /** Captcha Code wurde eingegeben */
    setCode: function (val) {

      this.code = val;

    },
    submitForm: function () {

      this.showSpinner();
      var url    = process.env.VUE_APP_SERVER_URL + '/login/sendForgotPass';
      var params = {
        'username': this.username,
        'captcha':  this.code,
        'lang': this.$i18n.locale
      };

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      }).then(function (response) {

        that.hideSpinner();
        if (response.data.success) {
          that.successmessage = response.data.message;
          that.errormessage = null;
        } else {
          that.errormessage = response.data.message;
          that.successmessage = null;
        }

      })
      .catch(function (error) {
        that.hideSpinner();
        console.log(error);
      });

    }

  }

}
</script>
