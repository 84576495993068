<template>

    <div id="content" role="main">

      <h1>{{ $t("message.printMaengel_h1") }}</h1>
      <p class="text-center">
        <strong>{{ kundenname }}</strong><br/>
        {{ adresse }}<br/>
          {{ $t("message.printPositionen_kundennummer") }}: {{ cardcode }}
      </p>

      <table>
        <thead>
          <tr>
            <th>{{ $t("message.printMaengel_nummer") }}</th>
            <th>{{ $t("message.printMaengel_datum") }}</th>
            <th>{{ $t("message.printMaengel_kurzbeschreibung") }}</th>
            <th>{{ $t("message.printMaengel_beschreibung") }}</th>
            <th>{{ $t("message.printMaengel_zustaendig") }}</th>
            <th>{{ $t("message.printMaengel_status") }}</th>
            <th>{{ $t("message.printMaengel_foto") }}</th>
          </tr>
        </thead>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.nummer ? item.nummer : '' }}</td>
          <td>{{ javaDateToDefaultDateFormat(item.dateCreated) }}</td>
          <td>{{ item.descriptionShort ? item.descriptionShort : '' }}</td>
          <td>{{ item.descriptionDetail ? item.descriptionDetail : '' }}</td>
          <td>{{ item.zustaendig ? item.zustaendig : '' }}</td>
          <td>{{ getAlertText(item) }}</td>
          <td>
            <span v-if="showMaengelFotosRight">
                <span v-if="item.bilder && item.bilder.length > 0">
                  <img :src="item.bilder[0].url" style="max-height: 200px; max-width: 200px" />
                </span>
            </span>
          </td>
        </tr>
      </table>

      <p>&nbsp;</p>

    </div>

</template>

<script>

  export default {
    name: 'PrintMaengel',
    components: {
    },
    data () {
      return {
        items:                  null,
        kundenname:             null,
        adresse:                null,
        cardcode:               null,
        showMaengelFotosRight:  false
      }
    },
    mounted: function () {

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);

      var items                   = this.$route.query.items;
      // items           = decodeURIComponent(items);
      this.items                  = JSON.parse(items);
      this.kundenname             = this.$route.query.kundenname;
      this.adresse                = this.$route.query.adresse;
      this.cardcode               = this.$route.query.cardcode;
      this.showMaengelFotosRight  = this.$route.query.showMaengelFotosRight;
      window.print();

    }

  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  table {
    width: 100%;
  }
  table, th, td {
    border: 1px solid black;
    padding: 0.5em;
  }
  h2, .h2 {
    font-size: 1.5rem;
  }

</style>
