<template>
    <div class="locale-changer">
        <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                <span :class="'fi fi-' + getLangIso($i18n.locale)"></span>&nbsp;{{ $i18n.locale }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item"
                   @click="setLang(lang)"
                   href="#" v-for="(lang, i) in filteredLang"
                   :key="`Lang${i}`"
                   :value="lang">
                    <span :class="'fi fi-' + getLangIso(lang)"></span>&nbsp;{{ lang }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios'
axios.defaults.withCredentials = true

export default {
    name: 'locale-changer',
    data () {
        return {
            langs: ['de', 'en']
        }
    },
    computed: {

        /** aktuelle Sprache rausfiltern */
        filteredLang: function () {

            return this.langs.filter( item =>
                item != this.$i18n.locale
            )

        }

    },
    methods: {

        setLang: function (lang) {

          this.$i18n.locale = lang

          // schicke die Sprache an den Server
          const url = process.env.VUE_APP_SERVER_URL + '/app/setLang'
          const params = {
            lang: lang
          }
          axios.get(url, {
            params: params,
            withCredentials: true
          })

        },
        /** ISO für Flaggen aus Sprache holen */
        getLangIso: function (lang) {
            if (lang == 'en') return 'gb'
            return lang
        }

    }
}
</script>
