<!-- Formatiert ein Java Datum -->
<template>

    <span v-if="value">
        {{ datum }}
    </span>

</template>

<script>

    export default {
      name: 'JavaDateToDefaultTimeFormat',
      props: ['value'], // value = JavaDatum String,
      computed: {

        datum: function () {

          if (this.value) {

            return this.javaDateToDefaultTimeFormat(this.value)

          }
          return ''

        }

      }

    }

</script>