<template>

    <span class="isCheckedBiotechUserToCustomer" v-if="customer && benutzer">

        <div class="row">
            <div class="col-sm-8 text-right">
                <!-- wenn gecheckt, dann kann das Modal geöffnet werden um die Benutzerrechte zu ändern -->
                <b-button variant="outline-primary"
                          v-if="isChecked === true || isChecked === 'true'"
                          size="sm"
                          v-on:click="showRechteModal">§ {{ $t('message.selectBenutzerToCustomer_rechte') }}</b-button>
            </div>
            <div class="col-sm-2">
                <b-form-checkbox
                        :id="'checkbox-' + customer.id + benutzer.id"
                        v-model="isChecked"
                        name="isChecked"
                        value="true"
                        unchecked-value="false">
                </b-form-checkbox>
            </div>
        </div>

        <!-- bei der Auswahl von Verträgen für einen Subuser, können die Benutzerrechte ausgewählt werden -->
        <b-modal :id="'modal-userroles' + customer.id + benutzer.id"
                 :title="$t('message.selectBenutzerToCustomer_rechte_fuer') + ' ' + customer.kunde"
                 size="lg"
                 :hide-footer="true">

          <form>

            <div class="row">
              <div class="col-md-4">
                  <b-form-checkbox
                      id="editPlan"
                      v-model="editPlanProp"
                      name="editPlan"
                      value="true"
                      unchecked-value="false">
                    {{ $t('message.selectBenutzerToCustomer_plaene_bearbeiten') }}
                  </b-form-checkbox>
              </div>
              <div class="col-md-4">
                  <b-form-checkbox
                      id="showMaengel"
                      v-model="showMaengelProp"
                      name="showMaengel"
                      value="true"
                      unchecked-value="false">
                    {{ $t('message.selectBenutzerToCustomer_maengel_ansehen') }}
                  </b-form-checkbox>
              </div>
              <div class="col-md-4">
                  <b-form-checkbox
                      id="hideMaengelInPlaenen"
                      v-model="isShowMaengelInPlaenen"
                      name="hideMaengelInPlaenen"
                      value="true"
                      unchecked-value="false">
                    {{ $t('message.selectBenutzerToCustomer_maengel_plan_ansehen') }}
                  </b-form-checkbox>
              </div>
              <div class="col-md-4">
                  <b-form-checkbox
                      id="editMaengel"
                      v-model="editMaengelProp"
                      name="editMaengel"
                      value="true"
                      unchecked-value="false">
                    {{ $t('message.selectBenutzerToCustomer_maengel_bearbeiten') }}
                  </b-form-checkbox>
              </div>
              <div class="col-md-4">
                  <b-form-checkbox
                      id="showMaengelFotos"
                      v-model="showMaengelFotosProp"
                      name="showMaengelFotos"
                      value="true"
                      unchecked-value="false">
                    {{ $t('message.selectBenutzerToCustomer_maengel_fotos') }}
                  </b-form-checkbox>
              </div>
              <div class="col-md-4">
                  <b-form-checkbox
                      id="showLieferscheine"
                      v-model="showLieferscheineProp"
                      name="showLieferscheine"
                      value="true"
                      unchecked-value="false">
                    {{ $t('message.selectBenutzerToCustomer_lieferschein_ansehen') }}
                  </b-form-checkbox>
              </div>
            </div>

            <hr />

            <h2 class="h5">{{ $t('message.selectBenutzerToCustomer_bereiche_ansehen') }}</h2>

            <b-form-group label="">
              <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="areasSelected"
                  :options="options"
                  name="flavour-1"
              />
            </b-form-group>
            <b-alert show variant="danger" v-if="isNoAreaSelect">
             {{ $t('message.selectBenutzerToCustomer_bereiche_min_1') }}
            </b-alert>

            <hr />

            <!-- Speichern -->
            <b-button variant="outline-primary" @click="saveRechte()" :disabled="isNoAreaSelect">
              <b-icon icon="check"></b-icon>&nbsp;{{ $t('message.default_ok') }}
            </b-button>

          </form>
        </b-modal>

    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'SelectBenutzerToCustomer',
      props: ['customer', 'benutzer', 'select-it'],
      data () {
        return {
          isChecked: null,
          /** User darf Pläne bearbeiten */
          editPlanProp: true,
          /** User darf Mängel sehen */
          showMaengelProp: true,
          /** User darf Mängel bearbeiten */
          editMaengelProp: true,
          /** User darf Fotos von Mängeln sehen */
          showMaengelFotosProp: true,
          /** User darf Lieferscheine ansehen */
          showLieferscheineProp: true,
          /** welche Areas darf der User sehen? */
          areasSelected: [],
          /** Areas des Kunden */
          areas: null,
          /** aus den Areas ein Options Array machen für Checkbox */
          options: [],
          /** Mängel sollen in Plänen nicht angezeigt werden */
          isShowMaengelInPlaenen: true
        }
      },
      computed: {

        /**
         * ist keine Area ausgewählt?
         * @returns {boolean}
         */
        isNoAreaSelect: function () {

          if (!this.areasSelected || this.areasSelected.length < 1) return true;
          return false;

        }

      },
      watch: {

        customer: function () {
          console.log('init for customer = ' + this.customer);
          this.init();
        },
        isChecked: function () {

          this.saveRechte();

        },
        selectIt: function (newVal) {
          console.log('selectId' + newVal);
          if (newVal == 'true') {
            this.isChecked = true;
          } else if (newVal == 'false') {
            this.isChecked = false;
          }
        }

      },
      created () {

        this.init();

      },
      methods: {

        /** hole die Benutzerrechte falls bereits vorhanden */
        getBenutzerrechte: function () {

          if (this.isChecked) {

            var url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/getBenutzerrechte';
            var params  = {
              cardcode:         this.customer.cardcode,
              mandant:          this.customer.biotechCountry.name,
              biotechUser_id:   this.benutzer.id
            };
            var that    = this;
            axios({
              method: 'post',
              params: params,
              url: url
            }).then(function (response) {

              // setze Benutzerrechte:
              if (response.data && response.data.id) {

                var areaidlist = response.data.showAreas;
                if (areaidlist) {

                  const splitted = areaidlist.split(',');
                  for (var j = 0; j < splitted.length; j++) {
                    for (var i = 0; i < that.areas.length; i++) {
                      if (that.areas[i].id == splitted[j]) {
                        // selektieren:
                        that.areasSelected.push(that.areas[i]);
                        break;
                      }
                    }
                  }

                }

                that.editPlanProp           = response.data.editPlan;
                that.showMaengelProp        = response.data.showMaengel;
                that.editMaengelProp        = response.data.editMaengel;
                that.showMaengelFotosProp   = response.data.showMaengelFotos;
                that.showLieferscheineProp  = response.data.showLieferscheine;
                that.isShowMaengelInPlaenen = response.data.isHideMaengelInPlaenen ? 'false' : 'true';

              }

            }).catch(error => {
              console.log('Fehler beim Lesen der Benutzerrechte ' + error);
            });

          }

        },
        showRechteModal: function () {
          this.$bvModal.show('modal-userroles' + this.customer.id + this.benutzer.id);
        },
        /** Rechte an Parent zurückschreiben */
        saveRechte: function () {

          this.$bvModal.hide('modal-userroles' + this.customer.id + this.benutzer.id);
          console.log('schließe Modal: ' + 'modal-userroles' + this.customer.id + this.benutzer.id);
          var check = this.isChecked == true || this.isChecked == 'true' ? true : false;

          var obj = {
            isChecked:              check,
            customer:               this.customer,
            benutzer:               this.benutzer,
            areasSelected:          this.areasSelected,
            editPlan:               this.editPlanProp,
            showMaengel:            this.showMaengelProp,
            editMaengel:            this.editMaengelProp,
            showMaengelFotos:       this.showMaengelFotosProp,
            showLieferscheine:      this.showLieferscheineProp,
            isHideMaengelInPlaenen: this.isShowMaengelInPlaenen == 'true' || this.isShowMaengelInPlaenen == true ? false : true
          }
          console.log('this.isShowMaengelInPlaenen = ' + this.isShowMaengelInPlaenen);
          this.$emit('set-is-checked', obj);

        },
        init: function () {

          if (this.customer && this.benutzer) {

            const url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/hasBiotechUserToCustomer';
            const params  = {
              cardcode:         this.customer.cardcode,
              mandant:          this.customer.biotechCountry.name,
              biotechUser_id:   this.benutzer.id,
            }
            const that    = this
            axios({
              method: 'post',
              params: params,
              url: url
            }).then(function (response) {

                console.log('User ' + that.benutzer.id + ' to Customer ' + that.customer.cardcode + ' is checked = ' + response.data)
                that.isChecked = response.data && response.data.id ? true : false

                that.getAreasAndSetBenutzerrechte()

            }).catch(error => {
              console.log(error)
            })

          }

        },
        getAreasAndSetBenutzerrechte: function () {

          // hole alle Areas des Kunden
          var that   = this;
          var url    = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAreas';
          var params = {
            cardcode: this.customer.cardcode,
            mandant:  this.customer.biotechCountry.name
          };
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            that.areas = response.data;
            for (var i = 0; i < that.areas.length; i++) {
              var item = {
                text:  that.areas[i].name ? that.areas[i].name : that.areas[i].intAreaId,
                value: that.areas[i]
              };
              that.options.push(item);
              if (that.benutzer.id < 0) {
                // neuer Benutzer alle Areas gleich selektieren
                that.areasSelected.push(that.areas[i]);
              }
            }

            // hole die Rechte falls Benutzer bereits vorhanden:
            if (that.benutzer.id > 0) {
              that.getBenutzerrechte();
            }

          }).catch(error => {
            console.log(error);
          });

        }

      }

    }

</script>