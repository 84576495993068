<template>

  <span>
    <div v-if="value">

      <b-alert variant="warning"
               show
               v-if="value.maxAlarmLevel == 'GELB'">
        {{ value.temperatur }}°C / {{ value.luftfeuchtigkeit }}%
      </b-alert>
      <b-alert variant="danger"
               show
               v-else-if="value.maxAlarmLevel == 'ROT'">
        {{ value.temperatur }}°C / {{ value.luftfeuchtigkeit }}%
      </b-alert>
      <b-alert variant="success"
               show
               v-else-if="value.maxAlarmLevel == 'GRUEN'">
        {{ value.temperatur }}°C / {{ value.luftfeuchtigkeit }}%
      </b-alert>

    </div>
    <div v-else-if="ergebnis">

      <b-alert variant="danger"
               show
               v-if="ergebnis.sensor_luftfeuchtigkeit_alarm_level == 'ROT' || ergebnis.alarm_level == 'ROT'">
        {{ ergebnis.result_value }}°C / {{ ergebnis.sensor_luftfeuchtigkeit_result_value }}%
      </b-alert>
      <b-alert variant="warning"
               show
               v-else-if="ergebnis.sensor_luftfeuchtigkeit_alarm_level == 'GELB' || ergebnis.alarm_level == 'GELB'">
        {{ ergebnis.result_value }}°C / {{ ergebnis.sensor_luftfeuchtigkeit_result_value }}%
      </b-alert>
      <b-alert variant="success"
               show
               v-else>
        {{ ergebnis.result_value }}°C / {{ ergebnis.sensor_luftfeuchtigkeit_result_value }}%
      </b-alert>

    </div>
  </span>

</template>
<script>

export default {

  name: 'SensorAlarmColor',
  props: [
    /** das aktuelle Ergebnis des Sensors */
    'value',
    /** Ergebnis als Ergebnis-Objekt aus dem DB */
    'ergebnis',
  ],
}

</script>