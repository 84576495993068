<!-- zeigt das aktuelle/letzte Ergebnis eines Objektes -->
<template>

    <transition name="fade" mode="out-in">
        <span class="objektstatus" v-if="ergebnis">

          <span v-if="ergebnis.error">{{ ergebnis.message }}</span>
          <sensor-alarm-color v-else-if="ergebnis.sensor_luftfeuchtigkeit_result_value"
                              :ergebnis="ergebnis"
          />
          <ergebnis-color-and-value-no-chart
              :alarm_level="ergebnis.alarm_level"
              :result_value="ergebnis.result_value"
              :not-checkable="ergebnis.is_not_checkable"
              :task_ergebnis_id="ergebnis.task_ergebnis_id"
              v-else-if="!ergebnis.traplinked_device_id && (ergebnis.result_value || ergebnis.is_not_checkable)"
          />
          <traplinked-ergebnis v-if="ergebnis.traplinked_device_id"
                               v-model="ergebnis.traplinked_report_device_status"
                               :alarm-level="ergebnis.alarm_level"
          />

        </span>
    </transition>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import ErgebnisColorAndValueNoChart from "@/components/ergebnisliste/ErgebnisColorAndValueNoChart";
    import TraplinkedErgebnis from "@/components/traplinkedbericht/TraplinkedErgebnis";
    import SensorAlarmColor from "@/components/sensor/SensorAlarmColor.vue";

    export default {
      name: 'ObjektStatus',
      components: {
        SensorAlarmColor,
        ErgebnisColorAndValueNoChart,
        TraplinkedErgebnis
      },
      props: ['positionId', 'objekt'],
      data () {
          return {
            ergebnis: null
          }
      },
      watch: {

        objekt: function () {
          this.init();
        }

      },
      created: function () {
        this.init();
      },
      methods: {

        /** hold das letzte Ergebnis eines Objekts */
        init: function () {

          const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getObjektErgebnis';
          let that = this;
          const params = {
            position_id: this.positionId,
            objekt_id:   this.objekt.id
          };
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data) {
              that.ergebnis = response.data;
            }

          }).catch(error => {
            console.log(error);
          });

        }

      }

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    span.objektliste ul {
        list-style-type: none;
    }

</style>
