<!-- rot, grün, gelb, ... Alarme anzeigen und das Ergebnis -->
<template>

    <span class="ErgebnisColorAndValue" v-if="sicherheitsdatenblaetter && sicherheitsdatenblaetter.length > 0">

        <b-button size="sm"
                  variant="outline-primary"
                  v-on:click="openModalOrShow()"
                  v-b-tooltip.hover.left
                  title="Sicherheitsdatenblätter anzeigen">
           <b-icon icon="file-earmark-check"></b-icon>&nbsp;{{ $t('message.ergebnisseTable_sdb') }}
        </b-button>
        <b-modal :id="randomer" :title="$t('message.sicherheitsdatenblaetter')" :hide-footer="true" size="lg">
            <b-list-group>
              <b-list-group-item v-for="item in sicherheitsdatenblaetter" :key="item.filename"
                                 button
                                 v-on:click="showSDB(item.url)">
                  <b-icon icon="file-earmark-arrow-down"></b-icon>&nbsp;{{ item.filename }}
              </b-list-group-item>
            </b-list-group>
        </b-modal>

    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'Sicherheitsdatenblaetter',
      components: { },
      props: ['article_id'],
      data () {
          return {
            sicherheitsdatenblaetter: null,
            randomer: null,
            /** um einen vorherigen Request zu stoppen */
            cancelSource: null
          }
      },
      created () {
        this.randomer =  '' + Math.random();
        this.init();
      },
      watch: {

        article_id: function () {

          this.init();

        }

      },
      methods: {

        /** zeigt Modal falls mehrere SDB vorhanden, sonst SDB gleich öffnen */
        openModalOrShow: function () {

          if (this.sicherheitsdatenblaetter && this.sicherheitsdatenblaetter.length == 1) {
            var item = this.sicherheitsdatenblaetter[0];
            this.showSDB(item.url);
          } else {
            this.$bvModal.show(this.randomer);
          }

        },
        /** Sicherheitsdatenblatt anzeigen */
        showSDB: function (url) {

          console.log('open url ' + url);
          window.open(url, '_blank');

        },
        /** holt die Sicherheitsdatenblätter des Artikels */
        init: function () {

          this.sicherheitsdatenblaetter = [];

          if (this.article_id) {

            this.cancelSearch();
            this.cancelSource = axios.CancelToken.source();

            var url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getDatenblaetter';
            var that = this;
            var params = {
              article_id: this.article_id
            };
            axios({
              method: 'post',
              params: params,
              url: url,
              cancelToken: this.cancelSource.token,
            }).then(function (response) {

              that.sicherheitsdatenblaetter = response.data;

            }).catch(error => {
              console.log(error);
            });

          }

        },
        /** eine vorherige Suche abbrechen */
        cancelSearch () {
          if (this.cancelSource) {
            this.cancelSource.cancel('Start new search of Sicherheitsdatenblaetter, stop active search');
            console.log('Sicherheitsdatenblaetter cancel request done');
          }
        }

      }

    }

</script>
