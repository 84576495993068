<template>

  <form class="fileUploadWrapper">

    <div class="form-group row">
        <label for="bezeichnung" class="col-sm-2 col-form-label">
            {{ $t('message.fileUpload_bezeichnung') }}
        </label>
        <div class="col-sm-10">
            <input type="text" class="form-control" id="bezeichnung" name="bezeichnung" v-model="bezeichnung" />
        </div>
    </div>

    <span v-if="bezeichnung && size && size == 'sm'">

      <!-- kleine Variante mit einfacher Zeile zum Upload -->
      <b-form-file
        v-model="file"
        :state="done"
        :placeholder="$t('message.fileUpload_auswaehlen')"
        :drop-placeholder="$t('message.fileUpload_einfuegen')">
      </b-form-file>

    </span>
    <span v-else-if="bezeichnung">

      <!-- File Upload Default, große Fläche zum ziehen aus dem Dateisystem -->
      <form class="box"
            v-bind:class="isAdvancedFileUpload"
            method="post"
            action=""
            enctype="multipart/form-data"
            draggable="true"
            v-on:dragstart="dragstart(item, $event)"
            v-on:dragenter="dragenter(item, $event)"
            v-on:dragover="dragover(item, $event)"
            v-on:drag="drag()"
            v-on:dragleave="dragleave(item, $event)"
            v-on:dragend="dragend(item, $event)"
            v-on:drop="drop(item, $event)">

        <div class="box__input mb-3">

          <svg class="box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"/></svg>
          <p class="text-center mb-3">{{ $t('message.fileUpload_ziehen') }}<br/>{{ $t('message.fileUpload_oder') }}</p>
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <div class="custom-file">
                <input type="file"
                       class="custom-file-input"
                       id="file"
                       ref="file"
                       v-on:change="handleFileUpload()"
                       multiple
                />
                <label class="custom-file-label" for="file">{{ $t('message.fileUpload_auswaehlen') }}</label>
              </div>
            </div>
            <div class="col"></div>
          </div>

        </div>
        <div class="box__uploading" v-if="uploading">
          <div class="alert alert-warning text-center" role="alert">
            {{ $t('message.fileUpload_wait') }}&hellip;
          </div>
        </div>
        <div class="box__success" v-if="done">
          <div class="alert alert-success text-center" role="alert">
            {{ $t('message.fileUpload_uploaded') }}
          </div>
        </div>
        <div class="box__error" v-if="error">
          <div class="alert alert-danger text-center" role="alert">
             {{ $t('message.fileUpload_error') }}
          </div>
        </div>

      </form>

    </span>

  </form>

</template>

<script>

  import $ from 'jquery';
  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {

    name: 'FileUpload',
    props: {
      /** Typ des Files aus der Enum */
      filetype: null,
      /** es kann auch eine kleine einzeilige Version angezeigt werden */
      size: null
    },
    data () {
      return {
        isAdvancedFileUpload: '',
        item: null,
        /** Datei wird gerade hochgeladen */
        uploading: false,
        /** Dateien erfolgreich hochgeladen */
        done: false,
        /** Fehler beim Upload */
        error: false,
        /** File der hochgeladen wird bei size = 'sm' */
        file: null,
        bezeichnung: null
      }
    },
    mounted: function () {

      this.init();

    },
    watch: {
      file: function (newVal) {
        if (newVal) {
          this.submitFile(this.file);
        }
      }
    },
    methods: {

      dragover: function (item, event) {
        console.log('drag over ...');
        event.preventDefault();
        event.stopPropagation();
        $('.box').addClass('is-dragover');
      },
      dragenter: function (item, event) {
        console.log('drag entered ...');
        event.preventDefault();
        event.stopPropagation();
        $('.box').addClass('is-dragover');
      },
      dragstart: function (item, event) {
        console.log('drag start ...');
        event.preventDefault();
        event.stopPropagation();
      },
      drag: function (item, event) {
        console.log('onDrag ...');
        event.preventDefault();
        event.stopPropagation();
      },
      dragleave: function (item, event) {
        event.preventDefault();
        event.stopPropagation();
        $('.box').removeClass('is-dragover');
      },
      dragend: function (item, event) {
        event.preventDefault();
        event.stopPropagation();
        $('.box').removeClass('is-dragover');
      },
      drop: function (item, event) {

        event.preventDefault();
        event.stopPropagation();
        $('.box').removeClass('is-dragover');
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          this.submitFile(event.dataTransfer.files[i]);
        }
        this.emitDone();

      },
      addClassToForm: function (classvalue) {

        this.isAdvancedFileUpload += ' ' + classvalue;

      },
      init: function () {

        if (this.isAdvancedUpload()) {

          this.addClassToForm('has-advanced-upload');

        } else {
          console.log('Kein Advanced Upload möglich!');
        }

      },
      /** prüft ob drag & drop File Upload möglich ist */
      isAdvancedUpload: function () {
        var div = document.createElement('div');
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
      },
      /* Utility function to convert a canvas to a BLOB */
      dataURLToBlob: function (dataURL) {

        var BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
          var parts = dataURL.split(',');
          var contentType = parts[0].split(':')[1];
          var raw = parts[1];

          return new Blob([raw], {type: contentType});
        }

        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});

      },
      /**
       * hochgeladenen File an Server senden
       * @param file
       */
      doSubmitFile: function (file) {

        console.log('do submit file ' + file);

        // passt die Filegröße?
        // Achtung Änderungen auch in application.yml setzen!
        if (file.size > 11534336) {
          this.uploading = false;
          this.done = false;
          this.error = true;
          return;
        }

        this.uploading  = true;
        this.done       = false;
        this.error      = false;
        let that        = this;

        let formData = new FormData();
        formData.append('file', file);

        // File an Server senden
        var url = process.env.VUE_APP_SERVER_URL + '/file/doUploadFile';
        var params = {
          filetype: this.filetype
        };
        // speichere File in Filesystem und hole neuen filename
        axios({
          method: 'POST',
          url: url,
          params: params,
          withCredentials: true,
          contentType: 'multipart/form-data',
          data: formData
        }).then(function (daten) {

          console.log('File erfolgreich hochgeladen: ' + daten);

          try {

            // schreibe Datei in den Eventbus
            if (daten.data && daten.data.filename) {
              that.uploading = false;
              that.done = true;
              that.error = false;
              that.emitFile(daten.data);
            } else {
              that.uploading = false;
              that.done = false;
              that.error = true;
            }

          } catch (e) {
            that.uploading = false;
            that.done = false;
            that.error = true;
          }

        }).catch(error => {

          console.log('Upload Error: ' + error);

          that.uploading = false;
          that.done = false;
          that.error = true;

        });

      },
      /*
        Submits the file to the server
      */
      submitFile: function (file) {

        console.log('submit file ' + file);
        this.doSubmitFile(file);

      },
      /*
        Handles a change on the file upload
      */
      handleFileUpload: function () {

        this.showSpinner();
        this.filename = null;
        try {
          this.submitFile(this.$refs.file.files[0]);
        } catch (e) {
          this.uploading  = false;
          this.done       = false;
          this.error      = true;
        }
        this.emitDone();

      },
      /** Objekt zurückschreiben */
      emitFile: function (file) {

        console.log('emit uploaded file ' + file);
        file.bezeichnung = this.bezeichnung;
        this.$emit('set-file', file);

      },
      /** Alle Files wurden hochgeladen */
      emitDone: function () {
        this.$emit('set-done', true);
        this.hideSpinner();
      }

    }

  }

</script>

<style scoped>

  .box__dragndrop {
      display: none;
  }
  .box.has-advanced-upload .box__dragndrop {
      display: inline;
  }
  .box.is-dragover {
      background-color: grey;
  }
  .box.has-advanced-upload {
      outline: 2px dashed #92b0b3;
      outline-offset: -10px;
      -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
      transition: outline-offset .15s ease-in-out, background-color .15s linear;
  }
  .box {
      /*font-size: 1.25rem;*/
      background-color: #ffffff;
      position: relative;
      padding: 50px 20px;
  }
  .box.has-advanced-upload .box__icon {
      width: 100%;
      height: 80px;
      fill: #92b0b3;
      display: block;
      margin-bottom: 40px;
  }

</style>
