import Vue from 'vue';
import Router from 'vue-router';
import Welcome from '@/components/Welcome';
import Login from '@/components/Login';
import ForgotPassword from "@/components/ForgotPassword";
import Dashboard from "@/components/Dashboard";
import NewPassword from '@/components/NewPassword';
import Maengelliste from '@/components/Maengelliste';
import Team from '@/components/Team';
import Lieferscheine from '@/components/Lieferscheine';
import Kundenliste from '@/components/Kundenliste';
import ChangePassword from '@/components/ChangePassword';
import Besuchsbericht from "@/components/Besuchsbericht";
import Positionen from "@/components/Positionen";
import Benutzer from '@/components/Benutzer';
import Benachrichtigungen from "@/components/Benachrichtigungen";
import PrintErgebnisliste from "@/components/PrintErgebnisliste";
import PrintPositionen from "@/components/PrintPositionen";
import Sicherheitsdatenblaetter from "@/components/Sicherheitsdatenblaetter";
import Alarmvorlagen from "@/components/Alarmvorlagen";
import Plan from "@/components/Plan";
import PlanList from "@/components/plan/PlanList";
import BenutzerOfCustomer from "@/components/BenutzerOfCustomer";
import BenutzerSuche from "@/components/BenutzerSuche";
import PrintMaengel from "@/components/PrintMaengel";
import CustomerNotifications from "@/components/CustomerNotifications";
import Export from "@/components/Export.vue";
import Support from "@/components/Support.vue";
import TraplinkedImages from "@/components/TraplinkedImages.vue";

Vue.use(Router)

export default new Router({
  routes: [
    {
      /* welcome kann aufs Dashboard umgeleitet werden */
      path: '/',
      name: 'Welcome',
      component: Welcome
    },
    {
      path: '/traplinkedImages',
      name: '247Images',
      component: TraplinkedImages
    },
    {
      path: '/benutzer',
      name: 'Benutzer',
      component: Benutzer
    },
    {
      path: '/benutzerOfCustomer',
      name: 'BenutzerOfCustomer',
      component: BenutzerOfCustomer
    },
    {
      path: '/benutzerSuche',
      name: 'BenutzerSuche',
      component: BenutzerSuche
    },
    {
      path: '/printErgebnisliste/:name',
      name: 'PrintErgebnisliste',
      component: PrintErgebnisliste,
      props: true
    },
    {
      path: '/plan/:id:position_id',
      name: 'Plan',
      component: Plan,
      props: true
    },
    {
      path: '/plan',
      name: 'Plan',
      component: Plan
    },
    {
      path: '/planList',
      name: 'PlanList',
      component: PlanList
    },
    {
      path: '/printPositionen/:name',
      name: 'PrintPositionen',
      component: PrintPositionen,
      props: true
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      props: true
    },
    {
      path: '/forgotPassword',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/newPassword/:token',
      name: 'NewPassword',
      component: NewPassword
    },
    {
      path: '/changePassword',
      name: 'ChangePassword',
      component: ChangePassword
    },
    {
      path: '/maengelliste',
      name: 'Maengelliste',
      component: Maengelliste
    },
    {
      path: '/printMaengel/:name',
      name: 'PrintMaengel',
      component: PrintMaengel,
      props: true
    },
    {
      path: '/benachrichtigungen',
      name: 'Benachrichtigungen',
      component: Benachrichtigungen
    },
    {
      path: '/lieferscheine',
      name: 'Lieferscheine',
      component: Lieferscheine
    },
    {
      path: '/sicherheitsdatenblaetter',
      name: 'Sicherheitsdatenblaetter',
      component: Sicherheitsdatenblaetter
    },
    {
      path: '/alarmvorlagen',
      name: 'Alarmvorlagen',
      component: Alarmvorlagen
    },
    {
      path: '/team',
      name: 'Team',
      component: Team
    },
    {
      path: '/kundenliste',
      name: 'Kundenliste',
      component: Kundenliste
    },
    {
      path: '/besuchsbericht',
      name: 'Besuchsbericht',
      component: Besuchsbericht
    },
    {
      path: '/positionen',
      name: 'Positionen',
      component: Positionen
    },
    {
      path: '/customerNotifications',
      name: 'CustomerNotifications',
      component: CustomerNotifications
    },
    {
      path: '/export',
      name: 'Export',
      component: Export
    },
    {
      path: '/support',
      name: 'Support',
      component: Support
    }
  ]
})
