<template>

    <div id="content" role="main">

        <div class="row mt-5">
            <div class="col-md-12">
                <div class="content_title">
                    <h1>{{ $t('message.support_h1') }}</h1>
                </div>
            </div>
        </div>

        <div class="container mt-5">

            <b-form @submit.prevent="onSubmit" v-if="$store.getters.customer.kunde">

                <div class="row">
                    <div class="col-md-8">

                        <!-- Position auswählen -->
                        <div class="form-group row">
                            <label for="position" class="col-sm-4 col-form-label">{{ $t('message.support_mon_pos') }}</label>
                            <div class="col-sm-8">
                                <find-position-of-customer
                                    v-model="position"
                                    id="position"
                                    :customer="$store.getters.customer"
                                />
                            </div>
                        </div>
                        <!-- Mangel auswählen -->
                        <div class="form-group row">
                            <label for="mangel" class="col-sm-4 col-form-label">{{ $t('message.support_mon_mangel') }}</label>
                            <div class="col-sm-8">
                                <find-mangel-of-customer
                                    v-model="mangel"
                                    id="mangel"
                                    :customer="$store.getters.customer"
                                />
                            </div>
                        </div>

                        <!-- Telefonnummer anzeigen/ändern -->
                        <div class="form-group row">
                            <label for="tel" class="col-sm-4 col-form-label">{{ $t('message.support_meine_tel') }}*</label>
                            <div class="col-sm-8">
                                <b-form-input v-model="tel"
                                              :placeholder="$t('message.support_tel')"
                                              :state="validationTel"
                                              maxLength="20" />
                                <b-form-invalid-feedback :state="validationTel">
                                  {{ $t('message.support_meine_tel_err') }}
                                </b-form-invalid-feedback>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4">

                        <!-- Standort Adresse -->
                        <strong>{{ $store.getters.customer.kunde }}</strong><br />
                        {{ $store.getters.customer.adresse }}<br />
                        <span v-if="$store.getters.customer.zipcode && $store.getters.customer.ort">
                            {{ $store.getters.customer.zipcode }} {{ $store.getters.customer.ort }}
                        </span><br />
                        {{ $store.getters.customer.land }}

                    </div>
                </div>

                <hr />

                <!-- Problemtyp auswählen -->
                <div class="form-group row">
                    <label for="tel" class="col-sm-2 col-form-label">{{ $t('message.support_problemtyp') }}</label>
                    <div class="col-sm-10">
                        <select-problemtyp v-model="problemTyp" :mandant="$store.getters.customer.biotechCountry.name" />
                    </div>
                </div>
                <!-- Thema -->
                <div class="form-group row">
                    <label for="tel" class="col-sm-2 col-form-label">{{ $t('message.support_thema') }}*</label>
                    <div class="col-sm-10">
                        <b-form-input v-model="thema"
                                      :placeholder="$t('message.support_thema_placeholder')"
                                      :state="validationThema"
                                      maxLength="200" />
                        <b-form-invalid-feedback :state="validationThema">
                          {{ $t('message.support_thema_err') }}
                        </b-form-invalid-feedback>
                    </div>
                </div>
                <!-- Detailtext -->
                <div class="form-group row">
                    <label for="tel" class="col-sm-2 col-form-label">{{ $t('message.support_problem_beschreibung') }}*</label>
                    <div class="col-sm-10">
                        <b-form-textarea
                            id="text"
                            v-model="text"
                            rows="3"
                            max-rows="6"
                            :state="validationText"
                        ></b-form-textarea>
                        <b-form-invalid-feedback :state="validationText">
                          {{ $t('message.support_problem_beschreibung_err') }}
                        </b-form-invalid-feedback>
                    </div>
                </div>

                <hr />

                <!-- Foto hochladen -->
                <div class="form-group row" v-if="fotos && fotos.length > 0">
                    <div class="col-md-2" v-for="foto in fotos" :key="foto.base64">
                        <figure class="figure position-relative"
                                @mouseenter="foto.hover = true"
                                @mouseleave="foto.hover = false">
                            <img :src="foto.base64"
                                 class="img-fluid img-thumbnail"
                            />
                            <figcaption class="figure-caption position-absolute"
                                        style="top: 0.5em; left: 0.5em"
                                        v-if="foto.hover">
                                <b-button variant="danger" size="sm" @click="deleteFoto(foto)">
                                    <b-icon icon="trash"></b-icon> {{ $t('message.default_loeschen') }}
                                </b-button>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <b-form-file
                            @change="setFoto"
                            :placeholder="$t('message.support_problem_foto_auswaehlen') + ' ...'"
                            :drop-placeholder="$t('message.support_problem_foto_ziehen') + ' ...'"
                            accept="image/*"
                            ref="file-input"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-12">
                        <b-button type="submit"
                                  :disabled="!isFormValid"
                                  variant="outline-primary">
                            <b-icon icon="check"></b-icon>&nbsp; {{ $t('message.default_absenden') }}
                        </b-button>
                    </div>
                </div>

            </b-form>

        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import FindPositionOfCustomer from '@/components/support/FindPositionOfCustomer.vue'
    import SelectProblemtyp from '@/components/support/SelectProblemtyp.vue'
    import FindMangelOfCustomer from '@/components/support/FindMangelOfCustomer.vue'
    import bootbox from 'bootbox'

    export default {
      name: 'Support',
      components: {
          FindPositionOfCustomer,
          SelectProblemtyp,
          FindMangelOfCustomer
      },
      data () {
        return {
            /** Telefonnummer des Kunden */
            tel: null,
            problemTyp: null,
            text: null,
            foto: null,
            position: null,
            mangel: null,
            fotos: [],
            thema: null
        }
      },
      mounted: function () {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name)
        this.init()

      },
      computed: {

          validationTel: function () {
              if (!this.tel || this.tel == '') return false
              if (this.tel.length < 5) return false
              return true
          },
          validationText: function () {
              // kein Pflichtfeld
              return true
          },
          validationThema: function () {
              if (!this.thema || this.thema == '') return false
              if (this.thema.length < 4) return false
              if (this.thema.length > 200) return false
              return true
          },
          isFormValid: function () {
              return this.validationText && this.validationTel && this.validationThema
          }

      },
      methods: {

          init: function () {

              this.thema = null
              this.text = null
              this.problemTyp = null
              this.foto = null
              this.position = null
              this.mangel = null
              this.fotos = []
              if (this.$store.getters.customer.tel) {
                  this.tel = this.$store.getters.customer.tel
              } else if (this.$store.getters.customer.tel2) {
                  this.tel = this.$store.getters.customer.tel2
              } else if (this.$store.getters.customer.mobil) {
                  this.tel = this.$store.getters.customer.mobil
              }

          },
          /**
           * ein neues Foto wird hochgeladen und in ein base64 umgewandelt
           * @param event
           */
          setFoto: function (event) {

              console.log('neues Bild wird hochgeladen', event)

              // Bild verkleinern und als base64 zurückliefern
              this.showSpinner()
              const that = this
              let imgfile = event.target.files[0]
              this.resizeImageToBase64({
                  file: imgfile,
                  maxSize: 1280
              }).then(function (resizedImage) {

                  console.log('upload resized image base64: ', resizedImage)
                  // base64 in file liste schreiben und neues Upload Feld anzeigen:
                  that.fotos.unshift({
                      base64: resizedImage,
                      hover: false
                  })
                  that.$refs['file-input'].reset()
                  that.hideSpinner()

              }).catch(function (err) {
                  console.error(err)
              })

          },
          /**
           * Foto wieder löschen
           * @param foto
           */
          deleteFoto: function (foto) {

              for (let i = 0; i < this.fotos.length; i++) {
                  if (this.fotos[i].base64 == foto.base64) {
                      this.fotos.splice(i, 1)
                      break
                  }
              }

          },
          /**
           * an Datenbroker übermitteln
           */
          onSubmit: function () {

              if (this.isFormValid) {

                  this.showSpinner()

                  // baue das JSON für die API zusammen:
                  /*
                      String auftragProblemTypId
                      String auftragHerkunftId
                      String customerId
                      String ansprechpartnerId
                      String personalId
                      String email
                      String thema
                      String bemerkung
                      Date planungsDatum
                      String[] images
                      String positionId
                      String mangelId
                      String tel
                   */

                  let images = [];
                  for (let i = 0; i < this.fotos.length; i++) {
                      if (this.fotos[i].base64) {
                          images.push(this.fotos[i].base64)
                      }
                  }

                  let params = {
                      auftragProblemTypId: this.problemTyp ? this.problemTyp.id : null,
                      auftragHerkunftId: 'ANALYTICS',
                      customerId: this.$store.getters.customer.id,
                      ansprechpartnerId: null,
                      personalId: null,
                      email: this.$store.getters.user.username,
                      bemerkung: this.text,
                      planungsDatum: null,
                      images: images,
                      positionId: this.position ? this.position.id : null,
                      mangelId: this.mangel ? this.mangel.id : null,
                      tel: this.tel,
                      thema: this.thema
                  }
                  const json = JSON.stringify(params)
                  console.log('params', json)

                  const that = this
                  axios({
                     method: 'POST',
                     url: process.env.VUE_APP_SERVER_URL + '/datenbroker/saveServiceCall',
                     data: params
                  }).then(function (response) {

                      that.hideSpinner()
                      console.log('service call success', response.data)
                      bootbox.alert(that.$t('message.support_danke'))
                      that.init()

                  }).catch(function (error) {
                      that.hideSpinner()
                      console.error(error)
                      bootbox.alert(that.$t('message.support_error'))
                  })

              }

          }

      }
    }

</script>