<!-- zeige gesetzte Filter der Charts -->
<template>

    <transition name="fade" mode="out-in">
        <div class="row mt-3" v-if="filter && (filter.filterObjektkategorie || filter.filterArea)">
            <div class="col">
                <b-alert show variant="info">
                    <div class="row">
                        <div class="col-md-8">
                            <b-icon icon="info-circle" scale="2" class="mr-3"/>&nbsp;
                            <span v-if="filter && filter.filterObjektkategorie && filter.filterArea">
                                  {{ $t('message.charts_filter_objektkat') }} <strong>{{ filter.filterObjektkategorie }}</strong>{{ $t('message.charts_filter_und_bereich') }} <strong>{{ filter.filterArea }}</strong>
                              </span>
                            <span v-else-if="filter && filter.filterObjektkategorie">
                                  {{ $t('message.charts_filter_objektkat') }} <strong>{{ filter.filterObjektkategorie }}</strong>
                              </span>
                            <span v-else-if="filter && filter.filterArea">
                                  {{ $t('message.charts_filter_bereich') }} <strong>{{ filter.filterArea }}</strong>
                              </span>
                        </div>
                        <div class="col-md-4 text-right">
                            <b-button variant="outline-primary" size="sm" @click="deleteFilter()">
                                <b-icon icon="funnel"/>&nbsp;{{ $t('message.charts_filter_delete') }}
                            </b-button>
                        </div>
                    </div>
                </b-alert>
            </div>
        </div>
    </transition>

</template>

<script>

    // Define a new component
    export default {
        name: 'ShowFilterCharts',
        props: ['filter'],
        data: function(){
          return {}
        },
        methods: {

          deleteFilter: function () {
            this.$emit('delete-filter', new Date());
          }

        }

    }

</script>
