<template>

  <div id="selectObjekttypenWrapper">
    <b-dropdown id="dropdown-form-tasks"
                :text="$t('message.selectObjekttypen_filtern')"
                ref="dropdown"
                variant="outline-primary">
      <b-dropdown-form>
        <b-form-checkbox-group
            id="checkbox-group-objekttypen"
            v-model="selected"
            :options="options"
            name="checkbox-group-objekttypen" />
      </b-dropdown-form>
    </b-dropdown>
  </div>

</template>

<script>

    // Define a new component
    export default {
        name: 'SelectObjekttypen',
        props: ['value'], // value = List mit objekttypen
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              /** ausgewählte Objekttypen */
              selected: [],
              objekttypen: []
            }
        },
        computed: {

          options: function () {

            var opts = [];
            for (var i = 0; i < this.value.length; i++) {
              opts.push({
                text: this.value[i].bezeichnung,
                value: this.value[i]
              });
            }
            return opts;

          }

        },
        mounted: function () {
          this.init();
        },
        watch: {

          selected: function () {
            this.emitme();
          },
          value: function () {
            this.init();
          }

        },
        methods: {

          init: function () {

            this.klasse = this.class ? this.class : '';

            if (this.value && this.value.length > 0) {
              this.selected = this.value;
            }

          },
          emitme: function () {
            this.$emit('set-selected-objekttypen', this.selected);
          }

        }

    }

</script>
