<template>

  <div v-if="myValue && myValue.temperatur" v-on:click="showModal()" style="cursor: pointer">

    <b-button-group>
      <!-- zuerst die Temperatur -->
      <b-button size="sm"
                v-b-tooltip.hover
                :title="$t('message.sensor_temp')"
                variant="success"
                v-if="myValue.alarmLevel == 'GRUEN'">{{ myValue.temperatur }}°C</b-button>
      <b-button size="sm"
                v-b-tooltip.hover
                :title="$t('message.sensor_temp')"
                variant="warning"
                v-if="myValue.alarmLevel == 'GELB'" >{{ myValue.temperatur }}°C</b-button>
      <b-button size="sm"
                v-b-tooltip.hover
                :title="$t('message.sensor_temp')"
                variant="danger"
                v-if="myValue.alarmLevel == 'ROT'"  >{{ myValue.temperatur }}°C</b-button>
      <!-- dann die Luftfeuchtigkeit -->
      <b-button size="sm"
                v-b-tooltip.hover
                :title="$t('message.sensor_luftfeuchtigkeit')"
                variant="success"
                v-if="myValue.alarmLevelLuftfeuchtigkeit == 'GRUEN'">{{ myValue.luftfeuchtigkeit }}%</b-button>
      <b-button size="sm"
                v-b-tooltip.hover
                :title="$t('message.sensor_luftfeuchtigkeit')"
                variant="warning"
                v-if="myValue.alarmLevelLuftfeuchtigkeit == 'GELB'" >{{ myValue.luftfeuchtigkeit }}%</b-button>
      <b-button size="sm"
                v-b-tooltip.hover
                :title="$t('message.sensor_luftfeuchtigkeit')"
                variant="danger"
                v-if="myValue.alarmLevelLuftfeuchtigkeit == 'ROT'"  >{{ myValue.luftfeuchtigkeit }}%</b-button>
    </b-button-group>

    <b-modal size="lg"
             :id="'sensor-chart-' + myValue.id"
             :title="title"
             :hide-footer="true">

      <b-card style="background-color: rgba(0,0,0,0.05)">

        <div class="row mb-3">
          <div class="col text-right">

            <b-button variant="outline-primary"
                      @click="weekOrMonth = 'month'"
                      :class="{ 'active': weekOrMonth == 'month' }"
                      :disabled="weekOrMonth == 'month'"
                      v-b-tooltip.hover
                      :title="$t('message.sensor_month')">
              <b-icon icon="calendar2-month"></b-icon>
            </b-button>
            <b-button variant="outline-primary"
                      v-b-tooltip.hover
                      class="ml-1"
                      :class="{ 'active': weekOrMonth == 'week' }"
                      :disabled="weekOrMonth == 'week'"
                      @click="weekOrMonth = 'week'"
                      :title="$t('message.sensor_week')">
              <b-icon icon="calendar2-week"></b-icon>
            </b-button>
            <b-button variant="outline-primary"
                      v-b-tooltip.hover
                      class="ml-1"
                      :class="{ 'active': weekOrMonth == 'day' }"
                      :disabled="weekOrMonth == 'day'"
                      @click="weekOrMonth = 'day'"
                      :title="$t('message.sensor_day')">
              <b-icon icon="calendar2-day"></b-icon>
            </b-button>

          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <b-button variant="outline-dark"
                      size="sm"
                      @click="previousMonth()"
                      v-if="weekOrMonth == 'month' && hasPreviousMonth">
              <b-icon icon="arrow-bar-left"></b-icon> {{ $t('message.sensor_vorher') }}
            </b-button>
            <b-button variant="outline-dark"
                      size="sm"
                      @click="previousWeek()"
                      v-else-if="weekOrMonth == 'week' && hasPreviousWeek">
              <b-icon icon="arrow-bar-left"></b-icon> {{ $t('message.sensor_vorher_week') }}
            </b-button>
            <b-button variant="outline-dark"
                      size="sm"
                      @click="previousDay()"
                      v-else-if="weekOrMonth == 'day' && hasPreviousDay">
              <b-icon icon="arrow-bar-left"></b-icon> {{ $t('message.sensor_vorher_day') }}
            </b-button>
            <b-button variant="outline-dark"
                      size="sm"
                      class="ml-2"
                      @click="last24h()"
                      v-if="weekOrMonth == 'day' && !isToday">
              <b-icon icon="calendar-day"></b-icon> {{ $t('message.sensor_vorher_24h') }}
            </b-button>
          </div>
          <div class="col-md-6 text-center">
            <h3 v-if="weekOrMonth == 'month' || weekOrMonth == 'week'">{{ year }}</h3>
            <h3 v-if="weekOrMonth == 'day'">{{ nameOfDay }}</h3>
            <h4 class="h6" v-else-if="weekOrMonth == 'month'">{{ nameOfMonth }}</h4>
            <h4 class="h6" v-else>{{ printWeek }}</h4>
          </div>
          <div class="col-md-3 text-right">
            <b-button variant="outline-dark"
                      size="sm"
                      @click="nextMonth()"
                      v-if="weekOrMonth == 'month' && hasNextMonth">
              {{ $t('message.sensor_naechstes') }} <b-icon icon="arrow-bar-right"></b-icon>
            </b-button>
            <b-button variant="outline-dark"
                      size="sm"
                      @click="nextWeek()"
                      v-else-if="weekOrMonth == 'week' && hasNextWeek">
              {{ $t('message.sensor_naechstes_week') }} <b-icon icon="arrow-bar-right"></b-icon>
            </b-button>
            <b-button variant="outline-dark"
                      size="sm"
                      @click="nextDay()"
                      v-else-if="weekOrMonth == 'day' && hasNextDay">
              {{ $t('message.sensor_naechstes_day') }} <b-icon icon="arrow-bar-right"></b-icon>
            </b-button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <b-card title="Temperatur °C">
              <line-temp-sensor v-model="sensor" type="TEM" :jahr="year" :monat="month" :woche="week" :tag="day" />
            </b-card>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <b-card title="Luftfeuchtigkeit %">
              <line-temp-sensor v-model="sensor" type="HUM" :jahr="year" :monat="month" :woche="week" :tag="day" />
            </b-card>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <b-card title="Taupunkt °C">
              <line-temp-sensor v-model="sensor" type="DEW" :jahr="year" :monat="month" :woche="week" :tag="day" />
            </b-card>
          </div>
        </div>

      </b-card>

    </b-modal>

  </div>

</template>
<script>

// Define a new component
import LineTempSensor from '@/components/charts/LineTempSensor.vue'
import moment from 'moment'
// import locale file(s)
import 'moment/locale/de';
import axios from "axios";

export default {

  name: 'SensorErgebnis',
  components: {
    LineTempSensor
  },
  props: [
    /** value = Row Item von Sensor Status */
    'value',
    'sensor',
     /** wenn true wird kein Chart bei Klick auf die Temperatur angezeigt */
    'hideChart'
  ],
  data: function () {
    return {
      /** das Jahr für den die Chartdaten angezeigt werden sollen */
      year: new Date().getFullYear(),
      /** das Monat für das die Chartdaten angezeigt werden sollen */
      month: new Date().getMonth() + 1,
      /** die Woche für die die Charts angezeigt werden sollen */
      week: null,
      /** der Tag für Tagesansicht als moment() Objekt */
      day: moment(),
      /** Wochenansicht oder Monatsansicht wechseln */
      weekOrMonth: 'day',
      /** myValue value wird hier gespeichert, wobei darauf geachtet wird ob ein lastSensorResult vorhanden ist oder nicht */
      myValue: null,
      /** ab wann stehen die ersten Ergebnisse zur Verfügung? */
      firstData: null,
    }
  },
  computed: {

    isToday: function () {

      if (this.day) {
        const a = this.day.format('DD.MM.YYYY')
        const b = moment().format('DD.MM.YYYY')
        if (a == b) {
          return true
        }
      }
      return false

    },
    title: function () {

      let title = this.$t('message.sensor_chart_title')
      if (this.value.position) {
        title += ' ' + this.$t('message.printPositionen_position')
        title += ': ' + this.value.position.positionsNr + ' ' + this.value.position.positionsBesch
      }
      return title

    },
    /** prüft ob noch ein Monat in die Vergangenheit geblättert werden kann */
    hasPreviousMonth: function () {

      if (this.month && this.firstData) {

        const firstYear = moment(this.firstData).year()
        const firstMonth = moment(this.firstData).month() + 1
        console.log('hasPreviousMonth this.month = ' + this.month + ' firstMonth = ' + firstMonth + ' this.year = ' + this.year + ' firstYear = ' + firstYear)
        if (this.year > firstYear) return true
        if (this.month > firstMonth) return true
        return false

      }
      return true

    },
    /** prüfe ob zum nächsten Monat weitergeblättert werden kann */
    hasNextMonth: function () {

      if (this.month) {
        const nowMonth = new Date().getMonth() + 1
        const nowYear  = new Date().getFullYear()
        console.log('hasNextMonth this.month = ' + this.month + ' nowMonth = ' + nowMonth + ' this.year = ' + this.year + ' nowYear = ' + nowYear)
        if (this.year < nowYear) return true
        if (this.month < nowMonth && this.year <= nowYear) return true
      }
      return false

    },
    /** prüft ob noch ein Monat in die Vergangenheit geblättert werden kann */
    hasPreviousWeek: function () {

      if (this.week && this.firstData) {

        const firstYear = moment(this.firstData).year()
        const firstWeek = moment(this.firstData).week()
        console.log('hasPreviousWeek this.month = ' + this.month + ' firstWeek = ' + firstWeek + ' this.year = ' + this.year + ' firstYear = ' + firstYear)
        if (this.year > firstYear) return true
        if (this.week > firstWeek) return true
        return false

      }
      return true

    },
    hasNextWeek: function () {

      if (this.week) {
        const nowWeek  = moment().week()
        const nowYear  = new Date().getFullYear()
        if (this.year < nowYear) return true
        if (this.week < nowWeek && this.year <= nowYear) return true
      }
      return false

    },
    hasPreviousDay: function () {

      if (this.day && this.firstData) {

        const firstDay = moment(this.firstData).toDate()
        let prev = this.day.toDate()
        if (prev.getTime() > firstDay.getTime()) return true
        return false

      }
      return true

    },
    hasNextDay: function () {

      if (this.day) {
        const nowDay = moment().toDate()
        let next = this.day.toDate()
        next.setDate(next.getDate() + 1)
        if (next.getTime() < nowDay.getTime()) return true
      }
      return false

    },
    /**
     * zeigt den Tag
     */
    nameOfDay: function () {

      // wenn heute dann die letzten 24h
      if (this.isToday) {

        return this.$t('message.sensor_vorher_24h')

      } else {

        console.log('lang', this.$i18n.locale)
        return this.day.locale(this.$i18n.locale).format('dddd, DD.MM.YYYY')

      }

    },
    /**
     * zeigt den Namen des Monats
     */
    nameOfMonth: function () {

      console.log('lang', this.$i18n.locale)
      return moment().month(this.month - 1).locale(this.$i18n.locale).format('MMMM')

    },
    printWeek: function () {

      if (this.week) {

        var dat = moment().year(this.year).week(this.week)
        var monday = dat.clone().weekday(0)
        var sunday = dat.clone().weekday(6)
        console.log('monday', monday)
        console.log('sunday', sunday)

        let resp
        resp = monday.format('DD.MM.YYYY')
        resp += ' - ' + sunday.format('DD.MM.YYYY')
        return resp

      }
      return null

    },

  },
  mounted() {

    this.setMyValue(this.value)
    this.getFirstSensorData()

  },
  watch: {

    sensor: function () {
      this.getFirstSensorData()
    },
    value: function (newObj) {

      this.setMyValue(newObj)

    },
    weekOrMonth: function (newVal) {

      if (newVal == 'day') {

        // setze den aktuellen tag
        this.day   = moment()
        this.month = null
        this.week  = null
        console.log('Tag gesetzt', this.day)

      } else if (newVal == 'week') {

        // Achtung month ist eventuell null, da vorher Tag gesetzt war:
        if (!this.month) {
          this.month = this.day ? moment(this.day).month() : moment().month()
          this.year  = this.day ? moment(this.day).year()  : moment().year()
        }

        // setze die Woche aus dem Tag oder die 1. Woche des Monats
        let firstDate = this.day ? moment(this.day) : moment([this.year, this.month - 1])
        // wenn der firstDate nach dem 1. verfügbaren ist, auf den 1. verfügbaren ändern:
        const firstDay = this.firstData ? moment(this.firstData) : null
        if (firstDay && firstDay.isAfter(firstDate)) {
          firstDate = firstDay
        }

        this.week  = firstDate.week()
        this.month = null
        this.day   = null
        console.log('Woche gesetzt', this.week)

      } else {

        // Achtung month ist eventuell null, da vorher Tag gesetzt war:
        if (!this.month || !this.week) {
          this.week = this.day ? moment(this.day).week() : moment().week()
          this.year = this.day ? moment(this.day).year() : moment().year()
        }

        // setze das Monat der Woche
        let dateOfFirstDayOfWeek = moment().year(this.year).week(this.week).startOf('isoWeek')
        // wenn der firstDate nach dem 1. verfügbaren ist, auf den 1. verfügbaren ändern:
        const firstDay = this.firstData ? moment(this.firstData) : null
        if (firstDay && firstDay.isAfter(dateOfFirstDayOfWeek)) {
          dateOfFirstDayOfWeek = firstDay
        }
        this.month = dateOfFirstDayOfWeek.month() + 1 // months are 0-11 in JavaScript
        this.week  = null
        this.day   = null
        console.log('Monat gesetzt', this.month)

      }

    }

  },
  methods: {

    getFirstSensorData: function () {

      this.firstData = null
      if (this.sensor) {

        const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getFirstSensorData';
        axios({
          method: 'GET',
          params: {
            sensor_id: this.sensor.id,
            customer_id: this.$store.getters.customer.id
          },
          url: url
        }).then(response => {

          this.firstData = response.data.firstData

        }).catch(error => {
          console.log(error)
        })

      }

    },
    setMyValue: function (val) {

      if (val && val.lastSensorResult && val.lastSensorResult.temperatur) {
        this.myValue = val.lastSensorResult
      } else if (val && val.temperatur) {
        this.myValue = val
      } else {
        this.myValue = null
      }

    },
    last24h: function () {

      this.day = moment()

    },
    previousDay: function () {

      console.log('1. previousDay', this.day.dayOfYear())
      let theday = this.day ? this.day.toDate() : new Date()
      console.log('1. theday', theday)
      theday.setDate(theday.getDate() - 1)
      console.log('2. theday', theday)
      this.day = moment(theday)
      console.log('2. previousDay', this.day.dayOfYear())

    },
    previousWeek: function () {

      if (this.week) {
        let week = this.week -1
        if (week < 1) {
          this.year = this.year - 1
          this.week = moment().isoWeeksInYear()
        } else {
          this.week = week
        }
      }

    },
    previousMonth: function () {

      if (this.month) {
        let month = this.month - 1
        if (month < 1) {
          this.month = 12
          this.year = this.year - 1
        } else {
          this.month = month
        }
      }

    },
    nextDay: function () {

      let theday = this.day ? this.day.toDate() : new Date()
      theday.setDate(theday.getDate() + 1)
      this.day = moment(theday)

    },
    nextWeek: function () {

      console.log('show next week', this.week)
      if (this.week) {
        let week = this.week + 1
        const lastWeekNumber = moment().isoWeeksInYear()
        console.log('lastWeekNumber', lastWeekNumber)
        if (week > lastWeekNumber) {
          console.log('this.week = ' + this.week + ' lastWeekNumber = ' + lastWeekNumber)
          this.year = this.year + 1
          this.week = 1
        } else {
          this.week = week
        }
      }

    },
    nextMonth: function () {

      let month = this.month + 1
      if (month > 12) {
        this.month = 1
        this.year = this.year + 1
      } else {
        this.month = month
      }

    },
    showModal: function () {

      if (!this.hideChart) {
        this.$bvModal.show('sensor-chart-' + this.myValue.id)
      }

    }
  }
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .alert {
    text-align: center;
  }
</style>