<!--
Traplinked Report Image anzeigen
-->
<template>

    <span v-if="value">

      <!-- bei Klick auf Icon Bilder anzeigen -->
      <span
          v-b-tooltip.hover
          placement="left"
          @click="showFotos()"
          :title="$t('message.showTraplinkedReportImage_vergroessern')">

            <!-- Thumbnail anzeigen -->
            <b-img v-if="imageDataList && imageDataList.length > 0"
                   thumbnail
                   style="cursor: pointer"
                   fluid
                   :src="imageDataList[0].imageData"
                   :alt="$t('message.showTraplinkedReportImage_automatisch')"
            />

        </span>

        <b-modal :id="'bilder-modal-' + value"
                 :title="$t('message.showTraplinkedReportImage_aufgenommene')"
                 size="lg"
                 :hide-footer="true">

          <div class="row" v-for="bild in imageDataList" :key="bild.id">

            <div class="col">
              <b-img thumbnail fluid :src="bild.imageData" :alt="$t('message.showTraplinkedReportImage_automatisch')" />
            </div>

          </div>

        </b-modal>

    </span>

</template>
<script>

  // Define a new component
  import axios from "axios";

  export default {

    name: 'ShowTraplinkedReportImage',
    props: [
        'value' // traplinked_report_id
    ],
    data () {
      return {
        imageDataList: null
      }
    },
    mounted: function () {
      this.init()
    },
    watch: {

      value: function () {
        this.init()
      }

    },
    methods: {

      /** Modal öffnen und Fotos anzeigen */
      showFotos: function () {

        this.$bvModal.show('bilder-modal-' + this.value)

      },
      /** holt den aktuellen Device Status abhängig vom Kunden */
      init: function () {

        this.imageDataList = null

        console.log('getTraplinkedReportImages für traplinked_report_id', this.value)
        if (this.value) {

          const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedReportImages';
          const params = {
            traplinked_report_id: this.value
          }

          const that = this
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then( response => {

            if (response.data) {
              that.imageDataList = response.data
            }

          })

        } // end if this.value

      }

    }

  }

</script>
