<!-- rot, grün, gelb, ... Alarme anzeigen und das Ergebnis -->
<template>

  <span class="allgemeinedokumentewrapper">

    <b-overlay :show="showOverlay" rounded="sm" style="min-height: 300px">

      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon" />
          <p id="cancel-label">{{ $t('message.allgemeineDokumente_wird_geladen') }} ...</p>
        </div>
      </template>

        <div id="filter" class="row pb-2">

          <div class="col-md-8">

            <b-input-group size="sm">
              <b-form-input
                  v-model="keyword"
                  type="search"
                  id="filterInput"
                  :placeholder="$t('message.positionen_filter')" />
              <b-input-group-append>
                <b-button :disabled="!keyword" @click="keyword = ''"><b-icon-x></b-icon-x></b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
          <div class="col-md-3"></div>
          <div class="col-md-1 text-right">

            <b-form-group horizontal label="" class="">
              <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
            </b-form-group>

          </div>

        </div>
        <div id="allgemeineDokumenteTable">

          <div class="card-box">
            <b-table small
                     hover
                     :items="items"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :keyword="keyword">

              <template v-slot:cell(datum)="row">
                <java-date-to-default-date-format v-model="row.item.datum" />
              </template>
              <template v-slot:cell(actions)="row">

                 <b-button variant="outline-primary" size="sm" @click="open(row.item)">
                   {{ $t('message.allgemeineDokumente_oeffnen') }}&nbsp;&nbsp;<b-icon icon="box-arrow-up-right" />
                 </b-button>

              </template>

            </b-table>
          </div>

          <b-row>
            <b-col md="12" class="my-1">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  class="my-0">
              </b-pagination>
            </b-col>
          </b-row>

        </div>

      </b-overlay>

    </span>

</template>

<script>

    import axios from "axios";
    import JavaDateToDefaultDateFormat from "@/components/layout/JavaDateToDefaultDateFormat.vue";

    export default {
      name: 'AllgemeineDokumente',
      components: {
        JavaDateToDefaultDateFormat
      },
      props: [],
      data () {
          return {
            sortBy: 'datum',
            sortDesc: true,
            /** Filter Query String */
            keyword: '',
            fields: [
              {
                label: this.$t('message.printMaengel_datum'),
                key: 'datum',
                sortable: true
              },
              {
                label: this.$t('message.default_bezeichnung'),
                key: 'bezeichnung',
                sortable: true
              },
              {
                label: '',
                key: 'actions',
                sortable: false,
                class: 'text-right'
              }
            ],
            files: null,
            currentPage: null,
            /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
            pageOptions: [ 5, 10, 15, 20, 50, 100 ],
            /** ausgewählter Wert aus den pageOptions */
            perPage: 20,
            showOverlay: false,
            url: null
          }
      },
      computed: {

        // a computed getter
        items: function () {

          if (this.files) {

            var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

            var selectedRows = lowercase
                ? this.files.filter(item =>

                    (item.bezeichnung && item.bezeichnung.toLowerCase().includes(lowercase)) ||
                    (item.datum       && item.datum.toLowerCase().includes(lowercase))

                ) : this.files;

            return selectedRows;

          }

          return null;

        },
        totalRows: function () {
          return this.items ? this.items.length : 0;
        }

      },
      created () {

        this.init();

      },
      methods: {

        /**
         * File anzeigen
         * @param file
         */
        open: function (file) {

          const url = this.url + file.filename
          window.open(url, '_blank');

        },
        init: function () {

          this.showOverlay = true;
          var that       = this;
          const url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAllgemeineFiles';
          const mandant  = this.$store.getters.customer.biotechCountry.name;
          const params   = {
            mandant: mandant
          };
          axios({
            method: 'get',
            params: params,
            url:    url
          }).then( response => {

            that.files = response.data.files;
            that.url   = response.data.url;
            that.currentPage = 1; // Pagination auf 1. Seite setzen
            that.showOverlay = false;

          }).catch(error => {
            console.log(error);
            that.showOverlay = false;
          });

        }

      }

    }

</script>