<!-- zeige Prioritaet eines Mangels -->
<template>

    <span class="mangel-prioritaet-wrapper" v-if="prioritaet">
        <span v-if="prioritaet && prioritaet.name == 'HOHE_GEFAHR'">
          <b-alert variant="danger" show style="margin-bottom: 0; text-align: center; padding: 0.25rem;">
            <b-icon icon="exclamation-octagon"></b-icon>&nbsp;{{ $t('message.editMangelPrioritaet_hoch') }}
          </b-alert>
        </span>
        <span v-else-if="prioritaet && prioritaet.name == 'MITTLERE_GEFAHR'">
          <b-alert variant="warning" show style="margin-bottom: 0; text-align: center; padding: 0.25rem;">
            <b-icon icon="exclamation-triangle"></b-icon>&nbsp;{{ $t('message.editMangelPrioritaet_mittel') }}
          </b-alert>
        </span>
        <span v-else-if="prioritaet && prioritaet.name == 'GERINGE_GEFAHR'">
          <b-alert variant="info" show style="margin-bottom: 0; text-align: center; padding: 0.25rem;">
            <b-icon icon="exclamation-circle"></b-icon>&nbsp;{{ $t('message.editMangelPrioritaet_gering') }}
          </b-alert>
        </span>
    </span>

</template>

<script>

    export default {
        name: 'MangelPrioritaet',
        props: [
            /** der Mangel */
            'value'
        ],
        data () {
            return {
              prioritaet: null
            }
        },
        watch: {

          value: function () {
            this.init();
          }

        },
        mounted: function () {

          this.init();

        },
        methods: {

          init: function () {

            this.prioritaet = this.value.mangelPrioritaet;

          }

        }

    }

</script>