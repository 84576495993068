<!--
aktuellen Status der Sensoren in Tabelle anzeigen
-->
<template>

  <div class="sensorStatusWrapper">

    <transition name="fade" mode="out-in">

      <div v-if="sensoren">

        <!-- Tabelle Filtern -->
        <div id="filter" class="row border-top mb-3 pt-3">

          <div class="col-md-11">

            <b-input-group size="sm">
              <b-form-input v-model="keyword"
                            type="search"
                            id="filterInput"
                            :placeholder="$t('message.positionen_filter')"
              />
              <b-input-group-append>
                <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
              </b-input-group-append>
            </b-input-group>

          </div>
          <div class="col-md-1 text-right">

            <b-form-group horizontal label="" class="">
              <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
            </b-form-group>

          </div>

        </div>

        <b-table striped
                 id="sensor-status-table"
                 small
                 :fields="fields"
                 :items="items"
                 :keyword="keyword"
                 :current-page="currentPage"
                 :per-page="perPage"
                 hover
                 :responsive="true">

          <template v-slot:cell(lastSensorResult_alarmLevel)="row">

            <span v-if="row.item.lastSensorResult.temperatur">
                <sensor-ergebnis v-model="row.item" :sensor="row.item.sensor" />
            </span>

          </template>
          <template v-slot:cell(lastSensorResult_timestamp)="row">

            <span v-if="row.item.lastSensorResult.timestamp">
              <java-date-to-default-time-format v-model="row.item.lastSensorResult.timestamp" />
            </span>

          </template>
          <template v-slot:cell(batteryStatus)="row">

            <batterie-status v-model="row.item.lastSensorResult.batteryStatus" :sensor="row.item.sensor" />

          </template>
          <template v-slot:cell(sensor_name)="row">

            <span v-if="row.item.sensor.name">
              {{ row.item.sensor.name }}
            </span>
            <span v-else-if="row.item.sensor.code">
               {{ row.item.sensor.code }}
            </span>

          </template>
          <template v-slot:cell(position)="row">

            <span v-if="row.item.position">
               <PositionOfTraplinkedDevice :pos="row.item.position" />
            </span>

          </template>
          <template v-slot:cell(objekt)="row">

            <span v-if="row.item.objekt">
              <objekt-of-traplinked-device :object="row.item.objekt" />
            </span>

          </template>
          <template v-slot:cell(area)="row">

            <span v-if="row.item.area">
              <area-of-traplinked-device :bereich="row.item.area" />
            </span>

          </template>
          <template v-slot:cell(actions)="row">

            <span v-if="row.item.lastSensorResult && row.item.lastSensorResult.temperatur">
              <sensor-history v-model="row.item.sensor" />
            </span>

          </template>

        </b-table>

        <b-row>
          <b-col md="12" class="my-1">
            <b-pagination :total-rows="totalRows"
                          :per-page="perPage"
                          v-model="currentPage"
                          class="my-0"
            />
          </b-col>
        </b-row>

      </div>

    </transition>

  </div>

</template>
<script>

  // Define a new component
  import axios from 'axios';
  import PositionOfTraplinkedDevice from '@/components/traplinkedbericht/PositionOfTraplinkedDevice';
  import BatterieStatus from '@/components/traplinkedbericht/BatterieStatus';
  import AreaOfTraplinkedDevice from '@/components/traplinkedbericht/AreaOfTraplinkedDevice';
  import JavaDateToDefaultTimeFormat from '@/components/layout/JavaDateToDefaultTimeFormat';
  import ObjektOfTraplinkedDevice from '@/components/traplinkedbericht/ObjektOfTraplinkedDevice.vue';
  import SensorErgebnis from '@/components/sensor/SensorErgebnis.vue';
  import SensorHistory from '@/components/sensor/SensorHistory.vue';

  export default {

    name: 'SensorStatus',
    components: {
      SensorHistory,
      ObjektOfTraplinkedDevice,
      BatterieStatus,
      PositionOfTraplinkedDevice,
      AreaOfTraplinkedDevice,
      JavaDateToDefaultTimeFormat,
      SensorErgebnis
    },
    props: ['value'], // Liste mit Sensoren
    /*
      Defines the data used by the component
    */
    data: function () {
      return {
        /** Liste von Sensoren eines Kunden */
        sensoren: null,
        fields: [
          {
            key: 'lastSensorResult_timestamp',
            label: this.$t('message.traplinkedStatus_letzte_meldung'),
            sortable: true,
          },
          {
            key: 'sensor_name',
            label: this.$t('message.sensorStatus_name'),
            sortable: true,
          },
          {
            key: 'objekt',
            label: this.$t('message.printErgebnisliste_objekt'),
            sortable: true,
          },
          {
            key: 'position',
            label: this.$t('message.printPositionen_position'),
            sortable: true,
          },
          {
            key: 'area',
            label: this.$t('message.printPositionen_bereich'),
            sortable: false,
          },
          {
            key: 'batteryStatus',
            label: this.$t('message.traplinkedStatus_batterie'),
            sortable: true,
          },
          {
            key: 'lastSensorResult_alarmLevel',
            label: this.$t('message.printErgebnisliste_ergebnis'),
            sortable: true,
          },
          {
            key: 'actions',
            label: this.$t('message.traplinkedStatus_alt'),
            sortable: false,
            class: 'text-right'
          }
        ],
        /** Filter Query String für Suche nach Position */
        keyword: '',
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 20,
        /** Pagination der Ergebnistabelle */
        currentPage: 1
      }
    },
    computed: {

      items: function () {

        var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

        var selectedRows = lowercase
            ? this.traplinkedDevices.filter( item =>
                (item.lastSensorResult && item.lastSensorResult.timestamp && this.javaDateToDefaultTimeFormat(item.lastSensorResult.timestamp).toLowerCase().includes(lowercase)) ||
                (item.sensor.name && item.sensor.name.toLowerCase().includes(lowercase)) ||
                (item.sensor.code && item.sensor.code.toLowerCase().includes(lowercase)) ||
                (item.position && item.position.positionsNr && item.position.positionsNr.toLowerCase().includes(lowercase)) ||
                (item.position && item.position.positionsBesch && item.position.positionsBesch.toLowerCase().includes(lowercase)) ||
                (item.objekt && item.objekt.bezeichnung && item.objekt.bezeichnung.toLowerCase().includes(lowercase)) ||
                (item.area && item.area.name && item.area.name.toLowerCase().includes(lowercase))
            )
            : this.sensoren;

        return selectedRows;

      },
      totalRows: function () {
        return this.items.length;
      }

    },
    watch: {

      value: function () {
        this.init()
      }

    },
    mounted: function () {

      this.init()

    },
    methods: {

      /** eine Area in die Liste der Sensoren zum Filtern eintragen */
      setArea: function (obj) {

        console.log('setArea' + obj);
        for (let i = 0; i < this.sensoren.length; i++) {
          if (this.sensoren[i].id == obj.traplinked_device_id) {
            this.sensoren[i].area = obj.area;
            break;
          }
        }

      },
      /** eine Position in die Liste der Sensoren zum Filtern eintragen */
      setPosition: function (obj) {

        console.log('setPosition' + obj);
        for (let i = 0; i < this.sensoren.length; i++) {
          if (this.sensoren[i].id == obj.traplinked_device_id) {
            this.sensoren[i].position = obj.position;
            break;
          }
        }

      },
      /** ein Objekt in die Liste der Sensoren zum Filtern eintragen */
      setObjekt: function (obj) {

        console.log('setObjekt', obj);
        for (let i = 0; i < this.sensoren.length; i++) {
          if (this.sensoren[i].id == obj.traplinked_device_id) {
            this.sensoren[i].objekt = obj.objekt;
            break;
          }
        }

      },
      /** hole alle Sensoren des Kunden */
      init: function () {

        this.sensoren = null;

        if (!this.value) {

          this.showSpinner();
          const customer = this.$store.getters.customer;
          const url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getSensoren';
          const params   = {
            customer_id: customer.id
          }

          const that = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(response => {

            if (response.data && response.data.sensorCustomer instanceof Array) {

              that.sensoren = response.data.sensorCustomer;
              that.hideSpinner();

            }

          }).catch(error => {
            console.log(error);
          })

        } else {

          this.sensoren = this.value

        }

      }

    }

  }

</script>
