<!--
aktuellen Status der Sensoren anzeigen
-->
<template>

  <transition name="fade" mode="out-in">

    <div class="card mt-3"  v-if="sensoren && sensoren.length > 0">
      <div class="card-header" @click="showSensorStatus = !showSensorStatus" style="cursor: pointer">
        <div class="row">
          <div class="col-md-8 text-left">
            <h3 class="h4 text-left">{{ $t('message.showHideSensorStatus') }}</h3>
          </div>
          <div class="col-md-4 text-right">
            <b-icon icon="caret-down" font-scale="2" v-if="!showSensorStatus"></b-icon>
            <b-icon icon="caret-up" font-scale="2" v-else></b-icon>
          </div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="card-body" v-if="showSensorStatus">

          <SensorStatus v-model="sensoren" />

        </div>
      </transition>
    </div>

  </transition>

</template>
<script>

  // Define a new component
  import axios from 'axios';
  import SensorStatus from "@/components/sensor/SensorStatus.vue";

  export default {

    name: 'ShowHideSensorStatus',
    components: {
      SensorStatus

    },
    /*
      Defines the data used by the component
    */
    data: function (){
      return {
        /** Sensoren Status ein/ausblenden */
        showSensorStatus: false,
        /** Sensorenliste */
        sensoren: null
      }
    },
    mounted: function () {

      this.init()

    },
    methods: {

      init: function () {

        const customer = this.$store.getters.customer;
        if (customer) {

          const url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getSensoren';
          const params  = {
            customer_id: customer.id
          }

          this.sensoren = null

          const that = this
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(response => {

            if (response.data && response.data.sensorCustomer instanceof Array) {
              that.sensoren = response.data.sensorCustomer
            }

          }).catch(error => {
            console.log(error)
          });

        }

      },

    }

  }

</script>
