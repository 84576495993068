<template>

    <div class="mb-5" v-if="person">

        <div class="row">
            <div class="col-md-4">
                <b-img fluid :src="person.foto" :alt="person.name" />
            </div>
            <div class="col-md-4">

                <div class="card-body">
                    <h3 class="text-left">{{ person.name }}</h3>
                    <h4 v-if="person.funktion" class="text-left">{{ person.funktion }}</h4>
                </div>

                <div class="card-body" v-if="person.tel || person.mobile || person.email" style="border-bottom: none">

                    <ul style="list-style-type: none;list-style-type: none;margin: 0;padding: 0;">
                        <li>
                            <a v-if="person.tel" :href="'tel:' + person.tel">
                                <b-icon-phone class="mr-1"></b-icon-phone> {{ person.tel }}
                            </a>
                        </li>
                        <li>
                            <a v-if="person.mobile" :href="'tel:' + person.mobile">
                                <b-icon-phone class="mr-1"></b-icon-phone> {{ person.mobile }}
                            </a>
                        </li>
                        <li>
                            <a v-if="person.email" :href="'mailto:' + person.email">
                                <b-icon-envelope class="mr-1"></b-icon-envelope> {{ person.email }}
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="col-md-4">

                <div  class="card-body" v-if="person.qualifikationen && person.qualifikationen.length > 0">

                    <div>
                        <h4 class="text-left">{{ $t('message.person_quali') }}</h4>
                        <div v-for="qualifikation in person.qualifikationen" :key="qualifikation.id">
                            <ul style="list-style-type: none;margin: 0;padding: 0;">
                                <li v-if="qualifikation.bezeichnung && qualifikation.beschreibung">
                                    <label v-if="qualifikation.bezeichnung">{{ qualifikation.bezeichnung }}:&nbsp;</label> {{ qualifikation.beschreibung }}
                                </li>
                                <li v-else-if="qualifikation.bezeichnung">
                                    <label v-if="qualifikation.bezeichnung">{{ qualifikation.bezeichnung }}</label>
                                </li>
                                <li v-else-if="qualifikation.beschreibung">
                                    {{ qualifikation.beschreibung }}
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <Schulungsunterlagen :person="person"></Schulungsunterlagen>

            </div>
        </div>

    </div>

</template>

<script>

    import Schulungsunterlagen from "@/components/team/Schulungsunterlagen";

    // Define a new component
    export default {

        name: 'Vertriebsmitarbeiter',
        components: {
            Schulungsunterlagen
        },
        props: {

            /** eine Person die angezeigt werden soll */
            person: {
                type: Object,
                required: true
            }

        }

    }

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    h3 {
        font-size: 1.2em;
        text-transform: none;
    }
    h4 {
        color: #004731;
        font-size: 1em;
        text-transform: none;
    }
    .card-body {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }

</style>
