<template>

  <span class="printKeyUserWrapper" v-if="keyUser">
    {{ $t('message.benutzerListe_key_user') }}:
    <span v-if="keyUser.email && keyUser.keyUserName">
      <a :href="'mailto:' + keyUser.email">{{ keyUser.keyUserName }} <small>({{ keyUser.email }})</small></a>
    </span>
    <span v-else-if="keyUser.keyUserName">{{ keyUser.keyUserName }}</span>
    <span v-else-if="keyUser.email"><a :href="'mailto:' + keyUser.email">{{ keyUser.email }}</a></span>
  </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
      name: 'PrintKeyUser',
      props: ['customer'],
      data () {
        return {
          keyUser: null,
        }
      },
      watch: {
        customer: function () {
            this.init()
        }
      },
      created() {

        this.init()

      },
      methods: {

        init: function () {

          this.keyUser = null
          if (this.customer) {

            const url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/findKeyUserOfCustomer'
            const params  = {
              customer_id: this.customer.id
            }

            const that = this
            axios({
              method: 'GET',
              params: params,
              url: url
            }).then(response => {

              if (response.data && response.data.id) {
                that.keyUser = response.data
              }

            }).catch(error => {
              console.log(error)
            })

          }

        }

      }

    }

</script>