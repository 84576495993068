<template>

  <b-overlay :show="showOverlay" rounded="sm" style="min-height: 100px">

    <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
        <p id="cancel-label">{{ $t('message.listKundenOfBenutzer') }} ...</p>
      </div>
    </template>
    <div class="listKundenOfBenutzer" v-if="customers && customers.length > 0">

        <b-list-group>
            <b-list-group-item v-for="item in customers" :key="item.id">
                {{ item.cardcode }} | {{ item.kunde }} | {{ item.zipcode }} {{ item.ort }} {{ item.adresse }}
            </b-list-group-item>
        </b-list-group>

    </div>

  </b-overlay>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'ListKundenOfBenutzer',
      props: [
          'benutzer'
      ],
      data () {
        return {
          customers: [],
          showOverlay: null
        }
      },
      created () {

        this.getCustomers();

      },
      methods: {

        /** hole die Kunden */
        getCustomers: function () {

          console.log('hole Standorte ...');
          this.showOverlay = true;
          // bei einem eingeloggten Key User nur die Kunden dieses Key Users holen
          var keyUserId     = this.benutzer.keyUserId;
          var url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/customers';
          var userId        = this.benutzer.id;
          var mandant       = this.benutzer.biotechCountry;

          if (!keyUserId) {
            url = process.env.VUE_APP_SERVER_URL + '/biotechUser/findCustomersOfBiotechUser';
          }

          var params  = {
            mandant:        mandant ? mandant.name : null,
            keyUserId:      keyUserId,
            showDetails:    this.showDetails,
            biotechUser_id: userId
          };

          var that = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            that.customers = response.data.customerListAnalytics;
            // Sonderfall: ein KeyUser kann trotzdem noch von einem anderen KeyUser Kunden zugewiesen bekommen haben
            that.getCustomersFromOtherKeyUser(params.mandant, params.biotechUser_id);
            that.showOverlay = false;

          }).catch(error => {

            console.log(error);
            that.showOverlay = false;
            const txt = that.$t('message.listKundenOfBenutzer_fehler_laden_standorte');
            alert(txt);

          });

        },
        /**
         * Sonderfall: ein KeyUser kann trotzdem noch von einem anderen KeyUser Kunden zugewiesen bekommen haben,
         * diese Kunden zur Liste noch hinzufügen
         * Funktion erst ausführen wenn die Kunden geladen sind, da die Liste ergänzt wird
         */
        getCustomersFromOtherKeyUser: function (mandant, biotechUser_id) {

          let that = this;
          const url = process.env.VUE_APP_SERVER_URL + '/biotechUser/findCustomersOfBiotechUser';
          const params = {
            mandant: mandant,
            biotechUser_id: biotechUser_id
          };
          axios({
            method: 'post',
            params: params,
            url: url
          }).then( response => {

            if (response && response.data) {
              for (let i = 0; i < response.data.length; i++) {
                var exists = false;
                if (that.customers) {
                  for (var j = 0; j < that.customers.length; j++) {
                    if (that.customers[j].id == response.data[i].id) {
                      exists = true;
                      break;
                    }
                  }
                }
                if (!exists) {
                  that.customers.push(response.data[i]);
                }
              }
            }

          }).catch(error => {
            console.log(error);
          });

        }

      }
    }

</script>
