<!-- rot, grün, gelb, ... Alarme anzeigen und das Ergebnis -->
<template>

    <span class="ErgebnisColorAndValue" v-if="alarm_level">
        <b-button size="sm"
                  :variant="color"
                  v-on:click="showModal()"
                  v-b-tooltip.hover.left
                  :title="$t('message.ergebnisAndChart_chart_besuch')">

            <span v-if="notCheckable">
                <b-icon icon="lightning" />&nbsp;{{ $t('message.charts_nicht_pruefbar') }}
            </span>
            <span v-else-if="result_value">
               {{ result_value }}
            </span>

        </b-button>
        <b-modal :id="randomer" :title="$t('message.ergebnisAndChart_entwicklung')" :hide-footer="true" size="lg">
            <line-stacked-area-chart-alarme-task-result-no-monatsbericht
                :task-ergebnis-id="task_ergebnis_id"
                :customer-ergebnis-id="customer_ergebnis_id"
            />
        </b-modal>
    </span>

</template>

<script>

    import LineStackedAreaChartAlarmeTaskResultNoMonatsbericht
      from "@/components/charts/LineStackedAreaChartAlarmeTaskResultNoMonatsbericht";

    export default {
      name: 'ErgebnisAndChart',
      components: {
        LineStackedAreaChartAlarmeTaskResultNoMonatsbericht
      },
      props: [
        'alarm_level',
        'result_value',
        'task_ergebnis_id',
        'notCheckable',
        'customer_ergebnis_id',
      ],
      data () {
          return {
            randomer: null
          }
      },
      created: function () {

        this.randomer =  '' + Math.random();

      },
      computed: {

        color: function() {

          if (this.notCheckable) {
            return 'info';
          } else if (this.alarm_level) {
            if (this.alarm_level == 'GRUEN') {
              return 'success';
            }
            if (this.alarm_level == 'ROT') {
              return 'danger';
            }
            if (this.alarm_level == 'GELB') {
              return 'warning';
            }
          }
          return 'secondary';

        }

      },
      methods: {
        showModal: function () {

          this.$bvModal.show(this.randomer);

        }
      }

    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .btn {
        width: 100%;
    }

</style>
