<template>

    <div id="content" role="main">

      <h1>{{ $t("message.printErgebnisliste_h1") }}</h1>
      <h2 v-if="auftragsNr">{{ $t("message.printErgebnisliste_auftrag_nr") }} {{ auftragsNr }} {{ $t("message.printErgebnisliste_vom") }} {{ javaDateToDefaultDateFormat(ended) }}</h2>
      <h2 v-else-if="ended">{{ ended }}</h2><!-- Zeitraum bei einem Monatsberichts -->
      <p class="text-center">
        <strong>{{ kundenname }}</strong><br/>
        {{ adresse }}<br/>
        {{ $t("message.printPositionen_kundennummer") }}: {{ cardcode }}
      </p>

      <table>
        <thead>
          <tr>
            <!-- ein Monatsbericht hat keine auftragsNr -->
            <th v-if="!auftragsNr">
                {{ $t("message.printErgebnisliste_datum_uhrzeit") }}
            </th>
            <th>{{ $t("message.printPositionen_bereich") }}</th>
            <th>{{ $t("message.printPositionen_position_nummer") }}r</th>
            <th>{{ $t("message.printPositionen_position") }}</th>
            <th>{{ $t("message.printErgebnisliste_aufgabe") }}</th>
            <th>{{ $t("message.printErgebnisliste_objekt") }}</th>
            <th>{{ $t("message.printErgebnisliste_ergebnis") }}</th>
            <th>{{ $t("message.printErgebnisliste_bemerkung") }}</th>
            <th>{{ $t("message.printErgebnisliste_material") }}</th>
            <th>{{ $t("message.printErgebnisliste_menge") }}</th>
            <th>{{ $t("message.printErgebnisliste_einheit") }}</th>
            <th>{{ $t("message.printErgebnisliste_berater") }}</th>
          </tr>
        </thead>
        <tr v-for="item in items" :key="item.id">
          <!-- ein Monatsbericht hat keine auftragsNr -->
          <td v-if="!auftragsNr">
            <span v-if="item.traplinked_report_timestamp">
              <java-date-to-default-time-format v-model="item.traplinked_report_timestamp" />
            </span>
            <span v-else>
              <span v-if="item.datum_start && item.datum_end && item.datum_start < item.datum_end">
                <java-date-to-default-date-format v-model="item.datum_start" /> - <java-date-to-default-date-format v-model="item.datum_end" />
              </span>
              <span v-else-if="item.datum_start">
                <java-date-to-default-time-format v-model="item.datum_start" />
              </span>
              <span v-if="!item.datum_start && item.datum_end">
                <java-date-to-default-time-format v-model="item.datum_end" />
              </span>
            </span>
          </td>
          <td>{{ item.area_name }}</td>
          <td>{{ item.position_nr }}</td>
          <td>{{ item.position_beschreibung }}</td>
          <td>{{ item.task_name }}</td>
          <td>{{ item.objekt_bezeichnung }}</td>
          <td>
            <span v-if="item.traplinked_device_id">
              <traplinked-ergebnis v-model="item.traplinked_report_report_type"
                                   :alarm-level="item.alarm_level"
              />
            </span>
            <ergebnis-color-and-value
                :alarm_level="item.alarm_level"
                :result_value="item.result_value"
                :not-checkable="item.is_not_checkable"
                :task_ergebnis_id="item.task_ergebnis_id"
                v-else-if="item.result_value || item.is_not_checkable"
            />
          </td>
          <td>{{ item.bemerkung }}</td>
          <td>{{ item.item_name }}</td>
          <td>{{ item.menge }}</td>
          <td>{{ item.sal_unit_msr }}</td>
          <td>{{ item.berater_name }}</td>
        </tr>
      </table>

      <p>&nbsp;</p>

    </div>

</template>

<script>

  import TraplinkedErgebnis from "@/components/traplinkedbericht/TraplinkedErgebnis";
  import JavaDateToDefaultTimeFormat from "@/components/layout/JavaDateToDefaultTimeFormat";
  import JavaDateToDefaultDateFormat from "@/components/layout/JavaDateToDefaultDateFormat";

  export default {
    name: 'PrintErgebnisliste',
    components: {
      TraplinkedErgebnis,
      JavaDateToDefaultTimeFormat,
      JavaDateToDefaultDateFormat
    },
    data () {
      return {
        items: null,
        auftragsNr: null,
        ended: null,
        kundenname: null,
        adresse: null,
        cardcode: null
      }
    },
    mounted: function () {

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);

      var items       = this.$route.query.items;
      console.log('render items: ' + items);
      try {
        let decodeditems = decodeURIComponent(items);
        items            = decodeditems; // wird nicht ausgeführt wenn vorher exception geworfen wird
      } catch (e) {
        console.log('e=' + e);
      }
      this.items      = JSON.parse(items);
      this.ended      = this.$route.query.ended;
      this.auftragsNr = this.$route.query.auftragsNr;
      this.kundenname = this.$route.query.kundenname;
      this.adresse    = this.$route.query.adresse;
      this.cardcode   = this.$route.query.cardcode;
      window.print();

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  table {
    width: 100%;
  }
  table, th, td {
    border: 1px solid black;
    padding: 0.5em;
  }
  h2, .h2 {
    font-size: 1.5rem;
  }
  /* Farben für Alarm Level */
  .alert-warning {
    background-color: #eda512;
    color: white;
  }
  .alert-success {
    background-color: #73b025;
    color: white;
  }
  .alert-danger {
    background-color: #e3150f;
    color: white;
  }
  .alert-info {
    background-color: #bee5eb;
  }

</style>
