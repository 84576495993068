<template>

    <div draggable="false">
        <b-list-group v-if="maengel">
            <b-list-group-item v-for="mangel in maengel" :key="mangel.id" style="cursor: pointer">
                <div class="row"
                     v-b-tooltip.hover title="In Plan einzeichnen."
                     @click="startDrag($event, mangel)">
                    <div class="col-sm-1" style="padding: 0; cursor: default">
                      <div class="iconWrapper"
                           style="width: 30px; height: 40px">
                        <img :src="getIconsrc(mangel)"
                             style="max-width: 30px; width: 100%">
                      </div>
                    </div>
                    <div class="col-sm-11">
                      {{ $t('message.maengelList_nr') }} {{ mangel.nummer }}: {{ mangel.descriptionShort }}
                    </div>
                </div>
            </b-list-group-item>
        </b-list-group>
    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    // Define a new component
    export default {
        name: 'MaengelList',
        props: [
          /** Liste mit anzuzeigenden Mängeln */
          'maengel'
        ],
        methods: {

          getIconsrc: function (mangel) {

            if (mangel.mangelPrioritaet.name == 'MITTLERE_GEFAHR') {
              return require('@/assets/images/icons/map-MANGEL-GELB.svg')
            }
            if (mangel.mangelPrioritaet.name == 'HOHE_GEFAHR') {
              return require('@/assets/images/icons/map-MANGEL-ROT.svg')
            }
            return require('@/assets/images/icons/map-MANGEL-NICHT_PRUEFBAR.svg')

          },
          /**
           * start drag Position
           */
          startDrag: function (evt, mangel) {

            console.log('start drag mangel: ', mangel)
            this.$emit('drag-mangel', mangel)

          },
          emitme: function () {
            this.$emit('input', this.selected)
          }

        }

    }

</script>
