<template>

    <span v-if="value" class="customerNotificationStatusWrapper">
            <b-badge v-if="value == 'GRUEN'"
                     style="background-color: #73b025">{{ $t('message.customerNotificationPrioritaet_gruen') }}</b-badge>
            <b-badge v-if="value == 'GELB'"
                     style="background-color: #eda512">{{ $t('message.customerNotificationPrioritaet_gelb') }}</b-badge>
            <b-badge v-if="value == 'ROT'"
                     style="background-color: #e3150f">{{ $t('message.customerNotificationPrioritaet_rot') }}</b-badge>
    </span>

</template>

<script>

  export default {

    name: 'CustomerNotificationPrioritaet',
    props: ['value']

  }

</script>