<template>

    <b-overlay :show="showOverlay" rounded="sm">
        <template #overlay>
            <div class="text-center" style="margin-top: 35px;">
                <b-icon icon="three-dots" animation="cylon" font-scale="2"></b-icon>
            </div>
        </template>
        <div v-if="positionen">
          <b-form-select v-model="selected" :options="options" />
        </div>
    </b-overlay>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    // Define a new component
    export default {
        name: 'FindPositionOfCustomer',
        props: ['customer', 'value'],
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              selected: null,
              options: null,
              positionen: null
            }
        },
        computed: {

          showOverlay: function () {
            if (this.positionen == null) return true;
            return false;
          }

        },
        mounted: function () {

          this.init();

        },
        watch: {

          selected: function (newVal, oldVal) {
            if (newVal && oldVal && newVal.id != oldVal.id) {
              this.emitme();
            } else if (newVal) {
              this.emitme();
            } else if (newVal == null && oldVal) {
              this.selected = null;
            }
          },
          customer: function () {
            this.init();
          },
          value: function (newVal) {
            this.selected = newVal
          }

        },
        methods: {

          init: function () {

            this.positionen = null;
            if (this.customer) {

              console.log ('init find position ...');
              this.options    = [
                { value: null, text: '--' }
              ];
              this.selected   = null;
              let that        = this;
              const url       = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionen';
              const params    = {
                'customer_id': this.customer.id
              };
              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(response => {

                console.log('response getPositionen', response.data);
                that.positionen = response.data;
                if (that.positionen && that.positionen.length > 0) {
                  for (var i = 0; i < that.positionen.length; i++) {
                    that.options.push({
                      value: that.positionen[i],
                      text: that.positionen[i].positionsNr + ' | ' + that.positionen[i].positionsBesch
                    });
                  }
                }

              });

            }

          },
          emitme: function () {
            this.$emit('input', this.selected);
          }

        }

    }

</script>
