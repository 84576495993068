<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.alarmvorlagen_h1') }}</h1>
            <h2><small style="text-transform: none;">{{ $t('message.alarmvorlagen_h2') }}</small></h2>
          </div>
        </div>

      </div>
      <div class="row mt-5">

        <div class="col-md-12">

          <div id="filter" class="row pb-2">

            <div class="col-md-8">

              <b-input-group size="sm">
                <b-form-input
                        v-model="keyword"
                        type="search"
                        id="filterInput"
                        :placeholder="$t('message.positionen_filter')" />
                <b-input-group-append>
                  <b-button :disabled="!keyword" @click="keyword = ''"><b-icon-x></b-icon-x></b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <div class="col-md-3"></div>
            <div class="col-md-1 text-right">

              <b-form-group horizontal label="" class="">
                <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
              </b-form-group>

            </div>

          </div>
          <div id="sdbTable">

            <div class="card-box">
              <b-table small
                       hover
                       :bordered="true"
                       :items="items"
                       :fields="fields"
                       :current-page="currentPage"
                       :per-page="perPage"
                       :keyword="keyword">
              </b-table>
            </div>

            <b-row>
              <b-col md="12" class="my-1">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    size="sm"
                    class="my-0">
                </b-pagination>
              </b-col>
            </b-row>

          </div>

        </div>

      </div>
    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;

  export default {
    name: 'Alarmvorlagen',
    components: {
    },
    data () {
      return {

        sortBy: 'datum',
        sortDesc: true,
        /** Filter Query String */
        keyword: '',
        fields: [
          {
            label: this.$t('message.printPositionen_bereich'),
            key: 'area.name',
            sortable: true,
            class: 'border-default-utilo'
          },
          {
            label: this.$t('message.printErgebnisliste_aufgabe'),
            key: 'task.name',
            sortable: true,
            class: 'border-default-utilo'
          },
          {
            label: this.$t('message.alarmvorlagen_aufgabe_beschreibung'),
            key: 'task.uVagDesc',
            sortable: true,
            class: 'border-default-utilo'
          },
          {
            label: this.$t('message.alarmvorlagen_gruen_ab'),
            key: 'alarmAbsolut.gruen',
            sortable: true,
            class: 'border-success-utilo'
          },
          {
            label: this.$t('message.alarmvorlagen_gelb_ab'),
            key: 'alarmAbsolut.gelb',
            sortable: true,
            class: 'border-warning-utilo'
          },
          {
            label: this.$t('message.alarmvorlagen_massnahmen'),
            key: 'gelbMassnahmen',
            sortable: true,
            class: 'border-warning-utilo'
          },
          {
            label: this.$t('message.alarmvorlagen_rot_ab'),
            key: 'alarmAbsolut.rot',
            sortable: true,
            class: 'border-danger-utilo'
          },
          {
            label: this.$t('message.alarmvorlagen_massnahmen'),
            key: 'rotMassnahmen',
            sortable: true,
            class: 'border-danger-utilo'
          }
        ],
        alarmvorlagen: null,
        currentPage: null,
        /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
        pageOptions: [ 5, 10, 15, 20, 50, 100 ],
        /** ausgewählter Wert aus den pageOptions */
        perPage: 20

      }
    },
    computed: {

      // a computed getter
      items: function () {

        if (this.alarmvorlagen) {

          var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

          var selectedRows = lowercase
                  ? this.alarmvorlagen.filter(item =>

                      (item.area.name.toLowerCase().includes(lowercase)) ||
                      (item.task.name.toLowerCase().includes(lowercase)) ||
                      (item.alarmAbsolut.gruen && item.alarmAbsolut.gruen.includes(this.keyword)) ||
                      (item.alarmAbsolut.rot   && item.alarmAbsolut.rot.includes(this.keyword)) ||
                      (item.alarmAbsolut.gelb  && item.alarmAbsolut.gelb.includes(this.keyword)) ||
                      (item.gelbMassnahmen && item.gelbMassnahmen.toLowerCase().includes(lowercase)) ||
                      (item.rotMassnahmen  && item.rotMassnahmen.toLowerCase().includes(lowercase)) ||
                      (item.task.code          && item.task.code.toLowerCase().includes(lowercase)) ||
                      (item.task.uVagDesc      && item.task.uVagDesc.toLowerCase().includes(this.keyword))

                  )
                  : this.alarmvorlagen;

          return selectedRows;

        }
        return null;

      },
      totalRows: function () {
        return this.items ? this.items.length : 0;
      }

    },
    created () {

      this.init();

    },
    methods: {

      init: function() {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name);

        this.showSpinner();
        var that    = this;
        var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAlarmvorlagen';
        var customer = this.$store.getters.customer;
        var params   = {
          customer: JSON.stringify(customer)
        };
        axios({
          method: 'post',
          params: params,
          url: url
        }).then(function (response) {

          that.alarmvorlagen = [];

          // Filtern ob Area angzeigt werden darf durch Benutzerrechte
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].area && that.showAreas(response.data[i].area.id)) {
              that.alarmvorlagen.push(response.data[i]);
            } else if (!response.data[i].area) {
              that.alarmvorlagen.push(response.data[i]);
            }
          }

          that.currentPage      = 1; //Pagination auf 1 Seite setzen
          // füge in die Liste ein rot und ein gelb aus oben und unten ein,
          // dann ist das Sortieren leichter:
          for (var i = 0; i < that.alarmvorlagen.length; i++) {
            that.alarmvorlagen[i].alarmAbsolut.gruen = that.alarmvorlagen[i].gruenAb;
            that.alarmvorlagen[i].alarmAbsolut.gelb  = that.alarmvorlagen[i].gelbAb;
            that.alarmvorlagen[i].alarmAbsolut.rot   = that.alarmvorlagen[i].rotAb;
          }
          that.hideSpinner();

        }).catch(error => {
          console.log(error);
          that.hideSpinner();
        });

      }

    }

  }

</script>

