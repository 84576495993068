<!-- Details über eine Position -->
<template>

    <transition name="fade" mode="out-in">
        <div v-if="maengel">

            <maengel-table :maengellist="maengel" v-on:mangel-prioritaet-changed="mangelPrioritaetChanged" />

            <hr class="mt-3 mb-3" />

            <!-- Actions -->
            <div class="row" v-if="editPlanRight">
                <div class="col text-right">
                    <b-button variant="outline-danger" size="sm" @click="removeMangelFromPlan()">
                        <b-icon icon="trash"></b-icon> {{ $t('message.mangelDetails_entfernen') }}
                    </b-button>
                </div>
            </div>

        </div>
    </transition>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import MaengelTable from "@/components/maengelliste/maengelTable";

    // Define a new component
    export default {
        name: 'MangelDetails',
        components: {
          MaengelTable
        },
        props: [
          /* die ID des Mangels der angezeigt werden soll */
          'value'
        ],
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              /** der Mangel als Liste */
              maengel: null
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          value: function () {
            this.init();
          }

        },
        computed: {

          /** darf der Benutzer den Plan ändern? */
          editPlanRight: function () {

            return this.editPlan();

          }

        },
        methods: {

          /** Priorität des Mangels wurde in Detailansicht geändert, an Parent weitergeben */
          mangelPrioritaetChanged: function (mangel) {

            this.$emit('mangel-prioritaet-changed', mangel);

          },
          /** Mangel aus Plan entfernen */
          removeMangelFromPlan: function () {

            this.showSpinner();

            let that          = this;
            const url         = process.env.VUE_APP_SERVER_URL + '/plan/removeMangelFromPlan';
            const params      = {
              'mangel_id': this.value
            };
            axios ({
              method:       'POST',
              params:       params,
              url:          url,
            }).then( () => {

              // an Parent weitergeben und dort verarbeiten:
              this.$emit('remove-mangel-from-plan', this.maengel[0]);
              that.hideSpinner();

            });


          },
          init: function () {

            this.maengel = null;

            if (this.value) {

              this.showSpinner();
              console.log ('init mangel details ' + this.value);

              var that          = this;
              var url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/getMaengel';
              var params        = {
                'mangel_id': this.value
              };
              axios ({
                method:       'GET',
                params:       params,
                url:          url,
              }).then( response => {

                that.maengel = response.data;
                that.hideSpinner();

              });

            }

          }

        } // end methods

    }

</script>