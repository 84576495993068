<template>

    <div id="content" role="main">

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.benachrichtigungen_h1') }}</h1>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5">

        <div v-if="$store.getters.user_role.authority == ROLE_KEY_USER || $store.getters.anzahlCustomer > 1">

          <!-- bei einem User auswählen ob er über neue Standorte verständigt werden soll -->
          <h2 class="text-left">{{ $t('message.benachrichtigungen_vertragszuteilung') }}:</h2>
          <disable-notification-neuer-vertrag :customer="ALLE" />

          <hr class="mb-5" />

        </div>

        <!-- entweder global setzen, oder individuell -->
        <div v-if="$store.getters.user_role.authority == ROLE_KEY_USER || $store.getters.anzahlCustomer > 1">
          <b-button-group class="mb-3">
            <b-button :variant="hasGlobalNotifications ? 'success' : 'outline-secondary'"
                      @click="setHasGlobalNotifications(true)">
              {{ $t('message.benachrichtigungen_global') }}
            </b-button>
            <b-button :variant="!hasGlobalNotifications ? 'success' : 'outline-secondary'"
                      @click="setHasGlobalNotifications(false)">
              {{ $t('message.benachrichtigungen_global_nein') }}
            </b-button>
          </b-button-group>
        </div>

        <div v-if="($store.getters.user_role.authority == ROLE_KEY_USER || $store.getters.anzahlCustomer > 1) && hasGlobalNotifications">

          <div class="card">
            <div class="card-body">

              <!-- Besuchsbericht Verständigungen -->
              <h3 class="text-left">{{ $t('message.benachrichtigungen_besuchsbericht_meldungen') }}:</h3>
              <p>{{ $t('message.benachrichtigungen_konf2') }}:</p>
              <disable-notification-neuer-besuchsbericht :customer="ALLE"
                                                         :label="$t('message.benachrichtigungen_label')"
                                                         :label-app="$t('message.benachrichtigungen_label_app')"
              />
              <notification-neuer-mangel :customer="ALLE" />
              <notification-alarm :customer="ALLE" />

              <hr class="mt-3 mb-5" />

              <!-- Traplinked Verständigungen -->
              <h3 class="text-left">{{ $t('message.benachrichtigungen_247_meldungen') }}:</h3>
              <traplinked-notifications :customer="ALLE" />

            </div>
          </div>

        </div>
        <div class="row" v-else-if="$store.getters.user_role.authority != ROLE_KEY_USER">
          <div class="col-md-6">

            <b-button
                variant="outline-info"
                id="activate_all"
                class="mt-1 mr-2"
                size="sm"
                @click="setAllEmailNotificationActive()">
              <b-icon icon="check-square"></b-icon> {{ $t('message.benachrichtigungen_alle_on') }}
            </b-button>

            <b-button
                variant="outline-warning"
                size="sm"
                class="mt-1 mr-2"
                id="deactivate_all"
                @click="setAllEmailNotificationInactive()"
                name="deactivate_all">
              <b-icon icon="square"></b-icon> {{ $t('message.benachrichtigungen_alle_off') }}
            </b-button>

          </div>
          <div class="col-md-6">

            <b-button
                variant="outline-info"
                class="mt-1 mr-2"
                size="sm"
                id="activate_all_app"
                @click="setAllAppNotificationActive()"
                name="activate_all_app">
              <b-icon icon="check-square"></b-icon> {{ $t('message.benachrichtigungen_alle_app_on') }}
            </b-button>

            <b-button
                variant="outline-warning"
                class="mt-1 mr-2"
                size="sm"
                id="deactivate_all_app"
                @click="setAllAppNotificationInactive()"
                name="deactivate_all_app">
              <b-icon icon="square"></b-icon> {{ $t('message.benachrichtigungen_alle_app_off') }}
            </b-button>

          </div>
        </div>

        <span v-if="($store.getters.user_role.authority == ROLE_KEY_USER || $store.getters.anzahlCustomer > 1) && hasGlobalNotifications == false">

          <div class="card">
            <div class="card-body">

                <!-- bei einem KeyUser alle Standorte auflisten oder wenn ein User mehrere Standorte zugewiesen hat -->
                <h2 class="text-left">{{ $t('message.benachrichtigungen_mailVerstaendigung') }}:</h2>
                <customer-list :show-details="false"
                               :number-per-page="20"
                               :benutzer="$store.getters.user"
                               :action="'DISABLE-NOTIFICATIONS'"
                               v-on:selected-customers="setSelectedCustomers"
                />

            </div>
          </div>

        </span>

        <!-- bei einem Benutzer mit einem Standort nur Checkboxen anzeigen -->
        <span v-else-if="$store.getters.user_role.authority == ROLE_CUSTOMER">

          <!-- Besuchsbericht Verständigungen -->
          <span v-if="!hasGlobalNotifications">

            <!-- bei einem User auswählen ob er über neue Standort verständigt werden soll -->
            <h2 class="text-left mt-3">{{ $t('message.benachrichtigungen_mailVerstaendigung') }}:</h2>
            <p>{{ $t('message.benachrichtigungen_konf') }}:</p>
            <disable-notification-neuer-vertrag :customer="$store.getters.customer" />

            <hr class="mt-3 mb-3" />

            <h2 class="text-left">{{ $t('message.benachrichtigungen_besuchsbericht_meldungen') }}:</h2>
            <p>{{ $t('message.benachrichtigungen_konf2') }}:</p>
            <disable-notification-neuer-besuchsbericht :customer="$store.getters.customer"
                                                       :label="$t('message.benachrichtigungen_label')"
                                                       :label-app="$t('message.benachrichtigungen_label_app')"
            />
            <notification-neuer-mangel :customer="$store.getters.customer" />
            <notification-alarm :customer="$store.getters.customer" />

            <hr class="mt-3 mb-5" />

            <!-- Traplinked Verständigungen -->
            <h2 class="text-left">{{ $t('message.benachrichtigungen_247_meldungen') }}:</h2>
            <traplinked-notifications :customer="$store.getters.customer" />

          </span>

        </span>


      </div>
    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import DisableNotificationNeuerBesuchsbericht from "@/components/customer/DisableNotificationNeuerBesuchsbericht";
  import DisableNotificationNeuerVertrag from "@/components/customer/DisableNotificationNeuerVertrag";
  import TraplinkedNotifications from '@/components/customer/TraplinkedNotifications';
  import NotificationAlarm from "@/components/customer/NotificationAlarm";
  import NotificationNeuerMangel from "@/components/customer/NotificationNeuerMangel";

  export default {
    name: 'Benachrichtigungen',
    components: {
      DisableNotificationNeuerBesuchsbericht,
      DisableNotificationNeuerVertrag,
      TraplinkedNotifications,
      NotificationAlarm,
      NotificationNeuerMangel
    },
    data () {
      return {
        /** Kunden die von KeyUser ausgewählt wurden um keine Verständigung zu erhalten */
        selectedCustomers: null,
        ALLE: {
          id: 'ALLE',
          cardcode: 'ALLE',
          biotechCountry: { name: null },
        },
        hasGlobalNotifications: null
      }
    },
    mounted () {
      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);
      this.init()
    },
    methods: {

      /**
       * Notification global setzen oder pro Standort
       */
      setHasGlobalNotifications: function (val) {

        console.log('setHasGlobalNotifications', val)
        this.hasGlobalNotifications = val

        const url = process.env.VUE_APP_SERVER_URL + '/notification/setGlobalNotifications';
        axios({
          method: 'POST',
          params: {
            biotechUser_id: this.$store.getters.user.id,
            hasGlobalNotifications: val,
          },
          url: url
        })

      },
      init: function () {

        if (this.$store.getters.user_role.authority == this.ROLE_KEY_USER || this.$store.getters.anzahlCustomer > 1) {

          const that = this;
          that.hasGlobalNotifications = null;
          const url = process.env.VUE_APP_SERVER_URL + '/notification/hasGlobalNotifications';
          axios({
            method: 'GET',
            params: {
              biotechUser_id: this.$store.getters.user.id,
            },
            url: url
          }).then( response => {

            that.hasGlobalNotifications = response.data.hasGlobalNotifications ? true : false

          }).catch(error => {
            console.log(error)
          })

        }

      },
      setAllEmailNotificationInactive: function () {
        this.saveAllAppNotification('setAllEmailNotificationInactive', this.$store.getters.allEmailNotificationInactive + 1)
      },
      setAllEmailNotificationActive: function () {
        this.saveAllAppNotification('setAllEmailNotificationActive', this.$store.getters.allEmailNotificationActive + 1)
      },
      setAllAppNotificationInactive: function () {
        this.saveAllAppNotification('setAllAppNotificationInactive', this.$store.getters.allAppNotificationInactive + 1)
      },
      setAllAppNotificationActive: function () {
        this.saveAllAppNotification('setAllAppNotificationActive', this.$store.getters.allAppNotificationActive + 1)
      },
      saveAllAppNotification: function (type, count) {

        this.showSpinner();
        const url = process.env.VUE_APP_SERVER_URL + '/notification/saveAll';

        axios({
          method: 'post',
          params: {
            type: type,
            biotechUser_id: this.$store.getters.user.id,
          },
          url: url
        }).then( () => {

          this.$store.commit(type, count)
          this.hideSpinner()
          console.log('Alle Notififation Ausnahme gespeichert.');

        }).catch(error => {
          console.log(error);
          this.hideSpinner()
        })

      },
      /**
       * Kunden die von KeyUser ausgewählt wurden um keine Verständigung zu erhalten
       */
      setSelectedCustomers: function (obj) {

        this.selectedCustomers = obj.selectedCustomers;

      }

    }
  }

</script>
