<template>

  <span id="headerWrapper">

    <nav class="navbar navbar-expand-lg navbar-light" :class="background">

      <div :class="containerClass">

        <a class="navbar-brand" href="https://www.biotech.at/" title="zur Biotech Homepage" target="_blank">
          <img src="../../assets/images/biotech-logo.svg" alt="Biotech Logo">
        </a>
        <button class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <!-- kein Home Button für Admin -->
            <li class="nav-item active" v-if="$store.getters.user_role && $store.getters.user_role.authority != ROLE_ADMIN">
              <router-link to="/"
                           v-if="!$store.getters.user"
                           :class="'nav-link'"
                           href="#">
                {{ $t('message.biotechHeader_home') }}
              </router-link>
              <router-link to="/Dashboard"
                           v-else-if="keyUserOhneStandorte || ($store.getters.user_role && $store.getters.user_role.authority == ROLE_CUSTOMER)"
                           :class="'nav-link'"
                           href="#">
                {{ $t('message.biotechHeader_dashboard') }}
              </router-link>
              <router-link to="/Dashboard"
                           v-else
                           :class="'nav-link'"
                           href="#">
                {{ $t('message.biotechHeader_dashboard') }}
              </router-link>
            </li>
            <!-- Kundenliste nur für Admin und Key User mit mehreren Standorten -->
            <li class="nav-item" v-if="keyUserMitStandorten">
              <router-link to="/Kundenliste"
                           :class="'nav-link'"
                           href="#">
                <span v-if="$store.getters.user_role && $store.getters.user_role.authority == ROLE_ADMIN">
                  {{ $t('message.kundenliste_h1') }}
                </span>
                <span v-else>{{ $t('message.benutzer_standorte') }}</span>
              </router-link>
            </li>
            <!-- Benutzer suchen nur für Admin -->
            <li class="nav-item" v-if="$store.getters.user_role && $store.getters.user_role.authority == ROLE_ADMIN">
                  <router-link to="/BenutzerSuche"
                               :class="'nav-link'"
                               href="#">
                      {{ $t('message.benutzer_h1') }}
                  </router-link>
            </li>
            <li>
                <router-link to="/Benutzer"
                             v-if="$store.getters.user_role && $store.getters.user_role.authority == this.ROLE_KEY_USER"
                             :class="'nav-link'"
                             href="#">
                  {{ $t('message.benutzer_h1') }}
                </router-link>
            </li>

            <!-- Dropdown für ausgewählten Kunden bei KeyUser mit mehreren Kunden oder Admin -->
            <li class="nav-item dropdown" v-if="$store.getters.customer && $store.getters.user_role">
              <a class="nav-link dropdown-toggle ml-3 btn btn-outline-light"
                 href="#"
                 id="dropDownKeyUser"
                 role="button"
                 data-toggle="dropdown"
                 aria-haspopup="true"
                 aria-expanded="false">
                <small>{{ $store.getters.customer.kunde }}<br/>
                 <span v-if="$store.getters.customer.zipcode && $store.getters.customer.ort">{{ $store.getters.customer.zipcode }} {{ $store.getters.customer.ort }},</span>
                  {{ $store.getters.customer.adresse }}</small>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/Dashboard"
                             v-if="$store.getters.user_role.authority == this.ROLE_ADMIN"
                             :class="'nav-link'"
                             href="#">
                  {{ $t('message.biotechHeader_dashboard') }}
                </router-link>
                <router-link to="/Besuchsbericht"
                             :class="'nav-link'"
                             href="#">
                  {{ $t('message.biotechHeader_besuchsberichte') }}
                </router-link>
                <router-link to="/Positionen"
                             :class="'nav-link'"
                             href="#">
                  {{ $t('message.printPositionen_h1') }}
                </router-link>
                <router-link to="/Maengelliste"
                             v-if="showMaengelRight"
                             :class="'nav-link'"
                             href="#">
                   {{ $t('message.printMaengel_h1') }}
                </router-link>
                <router-link to="/Team"
                             :class="'nav-link'"
                             v-if="$store.getters.user"
                             href="#">
                  {{ $t('message.team_h1') }}
                </router-link>
                <router-link to="/Lieferscheine"
                             :class="'nav-link'"
                             v-if="showLieferscheineRight"
                             href="#">
                  {{ $t('message.lieferscheine_h1') }}
                </router-link>
                <router-link to="/Sicherheitsdatenblaetter"
                               :class="'nav-link'"
                               v-if="$store.getters.customer"
                               href="#">
                      {{ $t('message.biotechHeader_sdb') }}
                </router-link>
                <router-link to="/Alarmvorlagen"
                               :class="'nav-link'"
                               v-if="$store.getters.customer"
                               href="#">
                    {{ $t('message.alarmvorlagen_h1') }}
                </router-link>
                <router-link to="/Plan"
                             :class="'nav-link'"
                             v-if="$store.getters.customer"
                             href="#">
                      {{ $t('message.plan_plaene') }}
                </router-link>
                <router-link to="/BenutzerOfCustomer"
                             :class="'nav-link'"
                             v-if="$store.getters.user_role.authority == this.ROLE_ADMIN && $store.getters.customer"
                             href="#">
                      {{ $t('message.benutzer_h1') }}
                </router-link>
                <router-link to="/CustomerNotifications"
                             :class="'nav-link'"
                             v-if="$store.getters.user_role.authority && $store.getters.user_role.authority != this.ROLE_ADMIN"
                             href="#">
                      {{ $t('message.customerNotifications_h1') }}
                </router-link>
                <router-link to="/traplinkedImages"
                             :class="'nav-link'"
                             href="#">
                      {{ $t('message.traplinkedImages_nav') }}
                </router-link>
                <router-link to="/Export"
                             :class="'nav-link'"
                             v-if="$store.getters.user_role && ($store.getters.user_role.authority == this.ROLE_KEY_USER || $store.getters.user_role.authority == this.ROLE_ADMIN)"
                             href="#">
                      {{ $t('message.export_h1') }}
                </router-link>

              </div>
            </li>
          </ul>

          <form class="form-inline my-2 my-lg-0">

            <button-login />

            <span v-if="$store.getters.user">

              <ul class="navbar-nav">
                <li class="nav-item mr-2" v-b-tooltip.hover :title="$t('message.support_h1')">
                  <router-link to="/support"
                               :class="'btn btn-outline-light btn-sm mt-1'"
                               v-if="$store.getters.customer"
                               href="#">
                      <b-icon icon="cursor"></b-icon>&nbsp;{{ $t('message.biotechHeader_support') }}
                  </router-link>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle"
                     href="#"
                     id="navbarDropdownUser"
                     role="button"
                     data-toggle="dropdown"
                     aria-haspopup="true"
                     aria-expanded="false"
                    style="text-transform: none">
                      <b-icon icon="person" class="circleIcon"></b-icon> {{ $store.getters.user.username }}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownUser">
                    <router-link to="/Benachrichtigungen"
                                 :class="'nav-link'"
                                 href="#">
                      {{ $t('message.benachrichtigungen_h1') }}
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <router-link to="/ChangePassword"
                                 :class="'nav-link'"
                                 href="#">
                      {{ $t('message.changePassword_h1') }}
                    </router-link>
                    <div class="dropdown-divider"></div>
                    <div class="pl-2 pr-2">
                      <button class="btn btn-outline-warning btn-block"
                              type="button"
                              v-on:click="logout">
                        <span class="oi oi-account-logout"></span>&nbsp;{{ $t('message.biotechHeader_logout') }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- hole Customer Notifications wenn Benutzer eingeloggt -->
              <customer-notifications />

            </span>

            <!-- Sprache wählen -->
            <locale-changer class="ml-2" />

          </form>
        </div>

      </div>

    </nav>

    <b-alert variant="danger" show v-if="$store.getters.loggedInAs">
      {{ $t('message.biotechHeader_logged_in_as').replace('{0}', $store.getters.loggedInAs) }}
    </b-alert>

  </span>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import buttonLogin from '@/components/layout/ButtonLogin'
  import customerNotifications from '@/components/customerNotifications/CustomerNotifications.vue'
  import LocaleChanger from '@/components/layout/LocaleChanger.vue'

  export default {
    name: 'BiotechHeader',
    components: {
      LocaleChanger,
      buttonLogin,
      customerNotifications,
    },
    data () {
      return {
        background: 'background-dark'
      }
    },
    created () {

      this.stayLoggedIn()
      if (process.env.VUE_APP_SERVER_URL.includes('TEST')) {
        // im Testsytem andere Header Farbe anzeigen
        this.background = 'background-test'
      }

    },
    computed: {

      /**
       * true wenn User Mängel sehen darf
       */
      showMaengelRight: function () {

        if (this.$store.getters.user_role) {
          return this.showMaengel()
        }
        return null

      },
      /**
       * true wenn User Lieferscheine sehen darf
       */
      showLieferscheineRight: function () {

        if (this.$store.getters.user_role) {
          return this.showLieferscheine()
        }
        return null

      },
      /** Nav Items für Key User mit nur einem Standort */
      keyUserOhneStandorte: function () {

        if (this.$store.getters.user_role) {

          if (this.$store.getters.user_role.authority == this.ROLE_KEY_USER && this.$store.getters.anzahlCustomer < 2)
            return true

        }
        return false

      },
      /** Nav Item für Key User mit mehreren Standorten */
      keyUserMitStandorten: function () {

        if (this.$store.getters.user_role) {

          if (this.$store.getters.user_role.authority == this.ROLE_ADMIN) return true
          if (this.$store.getters.anzahlCustomer > 1) return true

        }
        return false

      },
      containerClass: function () {

        if (
            this.$route.name === 'Welcome' ||
            this.$route.name === 'Login' ||
            this.$route.name === 'ForgotPassword' ||
            this.$route.name === 'NewPassword'
        ) return 'container'
        return  'container-fluid'

      }

    },
    methods: {

      stayLoggedIn: function () {

        const that = this
        setTimeout(function () {

          if (that.$store.getters.user_role) {

            try {
              const url = process.env.VUE_APP_SERVER_URL + '/login/stayLoggedIn'
              axios({
                method: 'GET',
                url: url
              }).then(function (response) {
                console.log('Stay logged in ' + response.data)
                that.stayLoggedIn()
              }).catch( () => {
              })
            } catch (e) {
              console.log('Stay logged in error ', e)
            }

          }

        }, 240000)

      },
      logout: function () {

        this.showSpinner()
        const url     = process.env.VUE_APP_SERVER_URL + '/login/logout'
        const that    = this
        axios({
          method: 'GET',
          url: url
        }).then(function () {
          that.logoutUser()
          that.hideSpinner()
          that.$store.commit('setLoggedInAs', null)
          that.$router.push('/')
        }).catch(error => {
          that.hideSpinner()
          console.log(error)
        })

      }

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .btn-outline-success {
    color: white;
    border-color: white;
  }
  #dropDownKeyUser > div.navbar-nav.dropdown-menu {
    margin-left: 2em;
  }

</style>
