<template>

    <div id="spinner" style="display: none">

        <div id="spinnerWrapper">

            <div id="spinnerclose">
              <b-button variant="outline-secondary"
                        size="sm"
                        style="padding: 0.05rem 0.10rem;"
                        @click="closeSpinner()">
                <b-icon icon="x" />
              </b-button>
            </div>

            <div class="row mt-3">
                <div class="col text-center">
                  {{ $t('message.spinner_loading') }} ....
                </div>
            </div>
            <div class="col text-center mt-3">
                <SpinnerImage />
            </div>

        </div>

    </div>

</template>

<script>

  import SpinnerImage from '@/components/layout/SpinnerImage'
  export default {

    name: 'Spinner',
    components: {
      SpinnerImage
    },
    data () {
      return {
      }
    },
    methods: {

      closeSpinner: function () {
        this.hideSpinner()
      }

    }

  }

</script>
