<!-- Charts Filtern -->
<template>

    <transition name="fade" mode="out-in">
        <div class="row" v-if="filter">
            <div class="col">
              <form class="form-inline">

                <label class="my-1 mr-2" for="monitoringbesuch"> {{ $t('message.charts_diagramme_filtern') }}:</label>

                <b-form-checkbox
                    id="monitoringbesuch"
                    v-model="filter.auftragsart.monitoringbesuch"
                    name="monitoringbesuch"
                    value="true"
                    class="ml-3"
                    unchecked-value="false">
                    {{ $t('message.besuchsbericht_monitoringbesuche') }}
                </b-form-checkbox>
                <b-form-checkbox
                    id="einzelauftrag"
                    v-model="filter.auftragsart.einzelauftrag"
                    name="einzelauftrag"
                    value="true"
                    class="ml-3"
                    unchecked-value="false">
                    {{ $t('message.besuchsbericht_einzelauftrag') }}
                </b-form-checkbox>

              </form>
            </div>
        </div>
    </transition>

</template>

<script>

    import bootbox from "bootbox";

    // Define a new component
    export default {
        name: 'FilterCharts',
        props: ['value'],
        data: function(){
          return {
            /** der gesetzte Filter */
            filter: null
          }
        },
        mounted: function () {
          this.filter = this.value;
        },
        watch: {
          value: function (newVal) {
            this.filter = newVal;
          },
          filter: {
            handler: function (newObj) {

              // prüfe ob mindestens eine auftragsart ausgewählt wurde
              if (
                  (newObj.auftragsart.monitoringbesuch == false || newObj.auftragsart.monitoringbesuch == 'false') &&
                  (newObj.auftragsart.einzelauftrag == false    || newObj.auftragsart.einzelauftrag == 'false')
              ) {
                const txt = this.$t('message.charts_min_1_auftrag');
                bootbox.alert(txt);
              }

            },
            deep: true
          },
        }

    }

</script>
