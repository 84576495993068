<template>

    <div id="content" role="main">

      <h1>{{ $t("message.printPositionen_h1") }}</h1>
      <h2 class="h4">{{ today }}</h2>
      <p class="text-center">
        <strong>{{ kundenname }}</strong><br/>
        {{ adresse }}<br/>
          {{ $t("message.printPositionen_kundennummer") }}: {{ cardcode }}
      </p>

      <table>
        <thead>
          <tr>
            <th>{{ $t("message.printPositionen_bereich") }}</th>
            <th>{{ $t("message.printPositionen_position_nummer") }}</th>
            <th>{{ $t("message.printPositionen_position") }}</th>
            <th>{{ $t("message.printPositionen_objekte") }}</th>
          </tr>
        </thead>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.area ? item.area.name : '' }}</td>
          <td>{{ item.positionsNr }}</td>
          <td>{{ item.positionsBesch }}</td>
          <td><ObjekteListe :position="item" /></td>
        </tr>
      </table>

      <p>&nbsp;</p>

    </div>

</template>

<script>

  import ObjekteListe from '@/components/ergebnisliste/ObjekteListe'
  import moment from 'moment'

  export default {
    name: 'PrintPositionen',
    components: {
      ObjekteListe
    },
    data () {
      return {
        items: null,
        kundenname: null,
        adresse: null,
        cardcode: null,
        /** heutiges Datum */
        today: null
      }
    },
    mounted: function () {

      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);

      this.today      = moment().format('DD.MM.YYYY');
      var items       = this.$route.query.items;
      items           = decodeURIComponent(items);
      this.items      = JSON.parse(items);
      this.kundenname = this.$route.query.kundenname;
      this.adresse    = this.$route.query.adresse;
      this.cardcode   = this.$route.query.cardcode;
      window.print();

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  table {
    width: 100%;
  }
  table, th, td {
    border: 1px solid black;
    padding: 0.5em;
  }
  h2, .h2 {
    font-size: 1.5rem;
  }

</style>
