<template>

  <div class="traplinkedberichtErgebnislisteWrapper">
    <!-- Besuchsbericht Ergebnisse für Monatsbericht -->
    <div class="mb-5">

      <span v-if="loading || loadingBesuchsberichtErgebnisse">
        <b-overlay show rounded="sm" class="mb-3">
          <div style="min-height: 120px"></div>
          <template #overlay>
            <div class="text-center">
              <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label" class="mt-2">
                {{ $t('message.positionen_daten_laden') }}
                <span v-if="loading"> {{ $t('message.traplinkedErgebnisliste_24_7') }} ... </span>
                <span v-if="loadingBesuchsberichtErgebnisse"> {{ $t('message.biotechHeader_besuchsberichte') }} ... </span>
              </p>
            </div>
          </template>
        </b-overlay>
      </span>

      <b-button variant="outline-danger"
                size="sm"
                @click="leereCache()"
                v-if="$store.getters.user_role.authority == ROLE_ADMIN">
        Cache leeren
      </b-button>

      <monatsbericht-ergebnisse v-model="besuchsberichtErgebnisse" :zeitraum="zeitraum" />

    </div>

  </div>

</template>
<script>

  // Define a new component
  import axios from "axios";
  axios.defaults.withCredentials = true;
  import MonatsberichtErgebnisse from "@/components/ergebnisliste/MonatsberichtErgebnisse";

  export default {

    name: 'TraplinkedErgebnisliste',
    components: {
      MonatsberichtErgebnisse
    },
    /**
     * value = traplinkedbericht
     * reset = Ergebnisse nicht mehr holen, es wurde abgebrochen
     * zeitraum = Datumstring des Zeitraums des Monatsberichts
     */
    props: ['value', 'reset', 'zeitraum'],
    /*
      Defines the data used by the component
    */
    data: function(){
      return {
        /** Ergebnisliste aus Besuchsberichten zusammen mit Traplinked Ergebnissen */
        besuchsberichtErgebnisse: [],
        /** true wenn der Traplinkedbericht geladen wird */
        loading: false,
        /** true wenn die Besuchsberichte dieses Monats geladen werden */
        loadingBesuchsberichtErgebnisse: false,
        /** liste mit Besuchsbericht ids, die hier angezeigt werden */
        besuchsberichte: [],
      }
    },
    watch: {

      value: function () {
        this.init();
      }

    },
    mounted: function () {

      this.init();

    },
    methods: {

      /**
       * hole alle Besuchsberichte des Monats und Traplinkedberichte des Kunden
       */
      init: function () {

        const areasAllowed = this.$store.getters.benutzerrechte ? this.$store.getters.benutzerrechte.showAreas : null;
        this.besuchsberichtErgebnisse = [];
        this.loading                  = false;
        if (this.value) {

          this.loading = true;
          this.loadingBesuchsberichtErgebnisse = true;

          let url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedErgebnisliste';
          let params   = {
            traplinkedbericht_id: this.value.id,
            areasAllowed: areasAllowed
          };

          let that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data && response.data instanceof Array) {

              console.log('TraplinkedErgebnisse sind da, verarbeite sie ...');
              for (var i = 0; i < response.data.length; i++) {

                if (response.data[i]) {
                  that.besuchsberichtErgebnisse.push(response.data[i]);
                }

              }
              console.log('Traplinked Ergebnisse fertig.');

            }
            that.loading = false;

            // hole die Besuchsberichtsdaten in diesem Monat:
            // für den Monatsbericht auch alle Besuchsberichte dieses Monats holen
            // mache das asynchron pro Besuchsbericht um nicht zu viel Zeit zu verplempern
            const url2 = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAllBesuchsberichteOfMonth';
            axios({
              method: 'GET',
              params: params,
              url: url2
            }).then (function (response) {
              that.computeBesuchsberichte(response, areasAllowed);
            }).catch(error => {
              console.log(error);
              that.loadingBesuchsberichtErgebnisse = false;
            });

          }).catch(error => {
            console.log(error);
            that.loading = false;
          });

        }

      },
      computeBesuchsberichte: async function (response, areasAllowed) {

          if (response.data && response.data instanceof Array) {

            for (let i = 0; i < response.data.length; i++) {

              const besuchsbericht = response.data[i];
              console.log('Hole Ergebnisliste von Besuchsbericht ' + besuchsbericht.id);
              // hole die Ergebnisse
              await this.getErgebnisse(besuchsbericht.id, areasAllowed);
              this.besuchsberichte.push(besuchsbericht.id);

            }

          }

          console.log('Besuchsberichte laden fertig.');
          this.loadingBesuchsberichtErgebnisse = false;

      },
      /** holt die Ergebnisliste für den Besuchsbericht */
      getErgebnisse: function (besuchsbericht_id, areasAllowed) {

        if (besuchsbericht_id) {

          this.loadingBesuchsberichtErgebnisse = true;
          const url    = process.env.VUE_APP_SERVER_URL + '/datenbroker/getErgebnisliste';
          let that     = this;
          let params   = {
            besuchsbericht_id:  besuchsbericht_id,
            areasAllowed:       areasAllowed,
            withoutTraplinked:  true
          };
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data && response.data.length > 0) {

              for (var i = 0; i < response.data.length; i++) {
                if (response.data[i]) that.besuchsberichtErgebnisse.push(response.data[i]);
              }

            }
            that.loadingBesuchsberichtErgebnisse = false;

          }).catch(error => {
            console.log(error);
          });

        } // end if besuchsbericht

      }, // end getErgebnisse
      leereCache: async function () {

        const url      = process.env.VUE_APP_SERVER_URL + '/cache/leereErgebnisseCache'
        let params     = {
          traplinkedbericht_id: this.value.id,
          user_id: this.$store.getters.user.id,
        }

        try {

          console.log('leereCache', url)
          this.showSpinner()
          const that = this
          await axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function () {
            console.log('traplinkedbericht ' +  this.value.id + ' aus Cache entfernt')
          }).catch(error => {
            console.log(error)
          })

          for (let i = 0; i < that.besuchsberichte.length; i++) {

            const besuchsbericht_id = that.besuchsberichte[i]
            params = {
              besuchsbericht_id: besuchsbericht_id
            }
            await axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function () {
              console.log('Besuchsbericht ' + besuchsbericht_id + ' aus Cache entfernt')
            })
          }
          that.hideSpinner()
          alert('Cache wurde gelöscht. Bitte Ergebnisliste neu laden.')

        } catch(e) {
          console.log(e)
        }

      }

    } // end methods

  }

</script>
