<template>

    <div class="footer mt-5">

        <div class="container pt-5">
            <div class="row">
                <div class="col-md-12 text-center ">
                    <a href="https://www.biotech.at" title="Biotech Homepage" class="footer_logo">
                        <img src="../../assets/images/biotech-logo.svg" alt="Biotech Logo">
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">

                    <div class="wrapper mt-3">
                        <ul id="menu-footermenu" class="menu">
                            <li>
                                <a target="_blank"
                                   href="https://www.biotech.at/kontakt/">{{ $t('message.biotechFooter_kontakt') }}</a>
                            </li>
                            <li>
                                <a target="_blank"
                                   href="https://www.biotech.at/impressum/">{{ $t('message.biotechFooter_impressum') }}</a>
                            </li>
                            <li>
                                <a target="_blank"
                                   href="https://www.biotech.at/datenschutz/">{{ $t('message.biotechFooter_datenschutz') }}</a>
                            </li>
                            <li>
                                <a target="_blank"
                                   href="https://www.biotech.at/agb/">{{ $t('message.biotechFooter_agb') }}</a>
                            </li>
                            <li>
                                <a target="_blank"
                                   href="https://www.biotech.at/bienen/">{{ $t('message.biotechFooter_bienenblog') }}</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row pb-2">
                <div class="col-md-12 text-center">
                    <hr>
                    <small>Developed by <a href="https://utilo.eu/" title="Website von UTILO">UTILO</a> | Version {{ version }}</small>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
        name: 'BiotechFooter',
        data () {
            return {
                version: null
            }
        },
        created () {
            this.init()
        },
        methods: {

            init: function () {

                // hole aktuelle Version:
                const url = process.env.VUE_APP_SERVER_URL + '/application'
                const that = this
                axios.get(url).then(function (response) {

                    console.log('Version = ', response.data)
                    that.version = response.data.version

                })

            }

        }
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .footer_logo img {
        width: 15%;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: inline-block;
    }

    li {
        float: left;
    }

    li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
    }
    a {
        color: white;
    }

    a:hover,
    li a:hover {
        text-decoration: underline;
        color: white;
    }

</style>
