<template>

    <div class="customerlist">

        <div class="row">

            <div class="col-md-12 mb-3" v-if="progress < anzahl">
                <span v-if="$store.getters.user_role.authority == ROLE_ADMIN">
                    {{ $t('message.customerList_kundendaten_laden') }} ...
                    <br/><br/>
                </span>
                <span v-if="$store.getters.user_role.authority == ROLE_KEY_USER">
                    {{ $t('message.customerList_standorte_laden') }} ...
                    <br/><br/>
                </span>
                <b-progress :value="progress" :max="anzahl" show-value animated />
            </div>
            <div class="col-md-12" v-if="customers">

                <!-- Ergebnis Tabelle Filtern -->
                <div id="filter" class="row border-top mb-3 pt-3">
                    <div class="col-md-6" v-if="showDetails">
                        <h4 class="text-left" v-if="$store.getters.user_role.authority == ROLE_KEY_USER">
                            {{ $t('message.customerList_ihre_standorte') }}
                        </h4>
                        <h4 class="text-left" v-if="$store.getters.user_role.authority == ROLE_ADMIN">
                            {{ $t('message.kundenliste_h1') }}
                        </h4>
                    </div>
                    <div :class="showDetails ? 'col-md-5' : 'col-md-11'">

                        <b-input-group size="sm">
                            <b-form-input
                                    v-model="keyword"
                                    type="search"
                                    id="filterInput"
                                    :placeholder="$t('message.positionen_filter')" />
                            <b-input-group-append>
                                <b-button :disabled="!keyword" @click="keyword = ''">
                                    <span class="oi oi-x"></span>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>

                    </div>
                    <div class="col-md-1 text-right">

                        <b-form-group horizontal label="" class="">
                            <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                        </b-form-group>

                    </div>

                </div>

                <b-overlay :show="showOverlay" rounded="sm" style="min-height: 300px">

                  <template #overlay>
                    <div class="text-center">
                      <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                      <p id="cancel-label">{{ $t('message.customerList_standorte_laden') }} ...</p>
                    </div>
                  </template>

                  <b-table id="customer-table"
                           striped
                           small
                           :fields="fields"
                           :items="items"
                           :keyword="keyword"
                           :current-page="currentPage"
                           :per-page="perPage"
                           hover
                           :sort-compare="mySortCompare"
                           :responsive="true">

                      <!-- A custom formatted header cell for field 'name' -->
                      <template #head(actions)="row">
                        <div class="row" v-if="row.item">
                          <div class="col-sm-8 text-right">
                          </div>
                          <div class="col-sm-2">
                            <input type="checkbox"
                                   v-if="action === 'SELECT-VERTRAEGE'"
                                   id="isCheckedAllVertraege"
                                   v-model="isCheckedAllVertraege"
                                   v-b-tooltip.hover
                                   :title="$t('message.customerList_alle_auswaehlen')"
                                   true-value="true"
                                   false-value="false"/>
                          </div>
                        </div>
                      </template>

                      <template v-slot:cell(letzterBesuch)="row">
                        <span v-if="showDetails">
                            <span class="datumLetzerBesuchsbericht">
                                {{ getDatumOfBesuchsbericht(row.item) }}
                            </span>
                        </span>
                      </template>

                      <!-- mit der Kundenliste können verschiedene Aktionen durchgeführt werden: -->
                      <template v-slot:cell(actions)="row">
                          <span v-if="action === 'DISABLE-NOTIFICATIONS'">

                              <edit-notifications :customer="row.item" />

                          </span>
                          <span v-else-if="action === 'SELECT-VERTRAEGE'">

                              <select-benutzer-to-customer
                                      :select-it="isCheckedAllVertraege"
                                      :customer="row.item"
                                      :benutzer="benutzer"
                                      v-on:set-is-checked="setIsChecked"
                              />

                          </span>
                          <!-- Benutzer des Standorts anzeigen -->
                          <span v-else-if="action === 'SHOW-USERS'">

                              <b-button size="sm" @click="row.toggleDetails">
                                {{ $t('message.benutzer_h1') }}&nbsp;<b-icon icon="caret-up-fill" v-if="row.detailsShowing"></b-icon><b-icon icon="caret-down-fill" v-else></b-icon>
                              </b-button>

                          </span>
                          <!-- jeder darf Besuchsberichte immer sehen <span v-else-if="row.item.lastBesuchsberichtId || row.item.lastMonatsberichtId || $store.getters.user_role.authority == ROLE_ADMIN"> -->
                          <span v-else>

                              <b-button size="sm"
                                        @click="customerSelected(row.item)">
                                 {{ $t('message.customerList_auswaehlen') }}
                              </b-button>

                          </span>
                      </template>

                      <!-- Detail Zeile um Standorte eines Benutzers anzuzeigen bei action == 'SHOW-USERS' -->
                      <template v-slot:row-details="row" v-if="action === 'SHOW-USERS'">
                        <b-card>
                          <show-benutzer-of-customer v-model="row.item" />
                        </b-card>
                      </template>

                      <!-- Alarm asynchron holen, falls details angezeigt werden sollen -->
                      <template #cell(alarm)="row">
                        <span v-if="showDetails">
                          <alarm-level-icon :alarm="row.item.alarmLevel" v-if="row.item.alarmLevelOrder && row.item.alarmLevelOrderMonatsbericht && row.item.alarmLevelOrder >= row.item.alarmLevelOrderMonatsbericht" />
                          <alarm-level-icon :alarm="row.item.alarmLevelMonatsbericht" v-if="row.item.alarmLevelOrder && row.item.alarmLevelOrderMonatsbericht && row.item.alarmLevelOrder < row.item.alarmLevelOrderMonatsbericht" />
                          <alarm-level-icon :alarm="row.item.alarmLevel" v-if="row.item.alarmLevelOrder && !row.item.alarmLevelOrderMonatsbericht" />
                          <alarm-level-icon :alarm="row.item.alarmLevelMonatsbericht" v-if="!row.item.alarmLevelOrder && row.item.alarmLevelOrderMonatsbericht" />
                        </span>
                      </template>

                      <!-- Anzahl der Mängel asynchron holen, falls Details angezeigt werden sollen -->
                      <template #cell(mangel)="row" v-if="showMaengelRight">
                        <span v-if="showDetails">
                            <anzahl-maengel-icon
                                :anzahl="row.item.anzahlOffeneMaengel"
                            />
                        </span>
                      </template>

                  </b-table>

                  <b-row>
                      <b-col md="12" class="my-1">
                          <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
                      </b-col>
                  </b-row>

                </b-overlay>

            </div>

        </div>

    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import SelectBenutzerToCustomer from '@/components/customer/SelectBenutzerToCustomer'
    import anzahlMaengelIcon from "@/components/maengelliste/anzahlMaengelIcon"
    import EditNotifications from '@/components/customer/EditNotifications'
    import AlarmLevelIcon from '@/components/layout/AlarmLevelIcon.vue'
    import ShowBenutzerOfCustomer from '@/components/customer/ShowBenutzerOfCustomer.vue'

    export default {
        name: 'CustomerList',
        components: {
          anzahlMaengelIcon,
          SelectBenutzerToCustomer,
          EditNotifications,
          AlarmLevelIcon,
          ShowBenutzerOfCustomer,
        },
        props: [
          'mandant',
          'showDetails',
          /** zeigt in den Actions eine Selectbox und emitted die selektierten */
          'benutzer',
          /**
           * welche Aktion soll mit der Kundenliste durchgeführt werden
           * 'SELECT-VERTRAEGE': um Verträge einen Benutzer zuzuweisen
           * 'DISABLE-NOTIFICATIONS': um Verständigungen zu deaktivieren
           * 'SHOW-USERS' zur Anzeige von Benutzern des Standorts
           */
          'action',
        ],
        data () {
            return {
              customers: [],
              anzahl: 0,
              fields: [
                {
                  key: 'cardcode',
                  label: this.$t('message.printPositionen_kundennummer'),
                  sortable: true
                },
                {
                  key: 'kunde',
                  label: this.$t('message.customerList_name'),
                  sortable: true
                },
                {
                  key: 'zipcode',
                  label: this.$t('message.customerList_plz'),
                  sortable: true
                },
                {
                  key: 'ort',
                  label: this.$t('message.customerList_ort'),
                  sortable: true
                },
                {
                  key: 'adresse',
                  label: this.$t('message.customerList_address'),
                  sortable: true
                }
              ],
              /** Filter Query String für Suche nach Position */
              keyword: '',
              /** ausgewählter Wert aus den pageOptions */
              perPage: 40,
              /** Pagination der Ergenistabelle */
              currentPage: 1,
              /** liste mit ausgewählten Kunden zu einem Benutzer */
              selectedCustomers: [],
              /** bei select Verträge mit Klick alle Verträge auswählen */
              isCheckedAllVertraege: false,
              /** Spinner wenn Kunden geladen werden */
              showOverlay: null,
            }
      },
      computed: {

          /**
           * true wenn User Mängel sehen darf
           */
          showMaengelRight: function () {

              if (!this.showDetails) {
                return false
              }

              return true

          },
          progress: function () {

            if (this.customers) return this.customers.length
            return 0

          },
          items: function () {

              var lowercase = this.keyword ? this.keyword.toLowerCase() : null

              var selectedRows = lowercase
                ? this.customers.filter( item =>

                  (item.cardcode                  && item.cardcode.toLowerCase().includes(lowercase)) ||
                  (item.kunde                     && item.kunde.toLowerCase().includes(lowercase)) ||
                  (item.zipcode                   && item.zipcode.toLowerCase().includes(lowercase)) ||
                  (item.ort                       && item.ort.toLowerCase().includes(lowercase)) ||
                  (item.anzahlOffeneMaengel       && ('' + item.anzahlOffeneMaengel).includes(lowercase)) ||
                  (item.ended                     && item.ended && item.ended.toLowerCase().includes(lowercase)) ||
                  (item.monatsberichtStarted      && item.monatsberichtStarted && item.monatsberichtStarted.toLowerCase().includes(lowercase)) ||
                  (item.alarmLevel                && item.alarmLevel.toLowerCase().includes(lowercase)) ||
                  (item.alarmLevel                && (lowercase.includes('grün') || lowercase.includes('green')) && item.alarmLevel.toLowerCase().includes('gruen')) ||
                  (item.alarmLevelMonatsbericht   && item.alarmLevelMonatsbericht.toLowerCase().includes(lowercase)) ||
                  (item.alarmLevelMonatsbericht   && (lowercase.includes('grün') || lowercase.includes('green')) && item.alarmLevelMonatsbericht.toLowerCase().includes('gruen')) ||
                  (item.adresse                   && item.adresse.toLowerCase().includes(lowercase))

                )
                : this.customers

              return selectedRows

          },
          pageOptions: function () {

            const anzahl = this.items.length
            let newlist = [5]
            let step = 5
            while (step < anzahl) {
              step = step * 2;
              if (step >= anzahl) {
                newlist.push(anzahl)
              } else {
                newlist.push(step)
              }
            }

            return newlist

          },
          totalRows: function () {

            const anzahl = this.items.length
            return anzahl

          }

      },
      created () {

        if (this.showDetails) {

          // ergänze weitere Tabellenspalten:
          var newfields = []
          newfields.push({
            key: 'alarm',
            label: this.$t('message.customerList_alarm'),
            sortable: true
          });
          newfields.push({
            key: 'mangel',
            label: this.$t('message.customerList_maengel'),
            sortable: true,
            class: 'text-center'
          });
          newfields.push(this.fields[0])
          newfields.push(this.fields[1])
          newfields.push(this.fields[2])
          newfields.push(this.fields[3])
          newfields.push(this.fields[4])
          newfields.push({
            key: 'letzterBesuch',
            label: this.$t('message.customerList_letzte_pruefung'),
            sortable: true
          });
          this.fields = newfields

        }

        this.init()

      },
      mounted: function () {

        // Tabellenspalten Überschrift der action Spalte setzen
        var label = this.$t('message.default_anzeigen')
        if (this.action === 'DISABLE-NOTIFICATIONS') {
          label =  this.$t('message.benachrichtigungen_h1')
        } else if (this.action === 'SELECT-VERTRAEGE') {
          label = this.$t('message.customerList_auswaehlen')
        }
        console.log('Label=' + label)
        // Buttons hinzufügen
        this.fields.push({ key: 'actions', label: label, class: 'text-right paddingRight1' })

      },
      watch: {

        isCheckedAllVertraege: function (newVal) {
          console.log('isCheckedAllVertraege: ' + newVal)
        }

      },
      methods: {

        /**
         * Benutzer ist für Kunde ausgewählt oder abgewählt
         */
        setIsChecked: function (obj) {

          console.log('setIsChecked = ' + obj.isChecked)

          var isChecked = obj.isChecked
          var customer  = obj.customer
          var benutzer  = obj.benutzer

          // ausgewählte Benutzerrechte des Kunden
          var benutzerrechte = {
            areasSelected:          obj.areasSelected,
            editPlan:               obj.editPlan,
            showMaengel:            obj.showMaengel,
            editMaengel:            obj.editMaengel,
            showMaengelFotos:       obj.showMaengelFotos,
            showLieferscheine:      obj.showLieferscheine,
            isHideMaengelInPlaenen: obj.isHideMaengelInPlaenen
          }

          customer.benutzerrechte = benutzerrechte;

          this.selectedCustomers.push(customer)
          var newlist   = [];
          for (var i = 0; i < this.selectedCustomers.length; i++) {
            if (this.selectedCustomers[i].id == customer.id && isChecked) {
              newlist.push(this.selectedCustomers[i])
            } else if (this.selectedCustomers[i].id != customer.id) {
              newlist.push(this.selectedCustomers[i])
            }
          }
          this.selectedCustomers = newlist
          this.$emit('selected-customers', {
            selectedCustomers: this.selectedCustomers,
            benutzer: benutzer
          })

        },
        mySortCompare: function (a, b, key) {

          if (key === 'letzterBesuch') {

            const aEndedMoment         = a.ended ? this.javaDateToMoment(a.ended) : null;
            const aMonatsberichtMoment = a.monatsberichtStarted ? this.javaDateToMoment(a.monatsberichtStarted) : null;
            const bEndedMoment         = b.ended ? this.javaDateToMoment(b.ended) : null;
            const bMonatsberichtMoment = b.monatsberichtStarted ? this.javaDateToMoment(b.monatsberichtStarted) : null;

            let bEnded = bEndedMoment;
            let aEnded = aEndedMoment;
            if (aEndedMoment && aMonatsberichtMoment && aEndedMoment.isBefore(aMonatsberichtMoment)) {
              aEnded = aMonatsberichtMoment;
            } else if (!aEndedMoment && aMonatsberichtMoment) {
              aEnded = aMonatsberichtMoment;
            }
            if (bEndedMoment && bMonatsberichtMoment && bEndedMoment.isBefore(bMonatsberichtMoment)) {
              bEnded = bMonatsberichtMoment;
            } else if (!bEndedMoment && bMonatsberichtMoment) {
              bEnded = bMonatsberichtMoment;
            }

            if (aEnded && bEnded) {
                return aEnded.valueOf() - bEnded.valueOf();
            }
            if (aEnded && !bEnded) return 1;
            if (bEnded && !aEnded) return -1;
            return 0;

          } else if (key === 'alarm') {

            var aAlarm = a.alarmLevelOrder;
            if (a.alarmLevelOrder && a.alarmLevelOrderMonatsbericht && a.alarmLevelOrder < a.alarmLevelOrderMonatsbericht) {
              aAlarm = a.alarmLevelOrderMonatsbericht;
            } else if (!a.alarmLevelOrder && a.alarmLevelOrderMonatsbericht) {
              aAlarm = a.alarmLevelOrderMonatsbericht;
            }
            var bAlarm = b.alarmLevelOrder;
            if (b.alarmLevelOrder && b.alarmLevelOrderMonatsbericht && b.alarmLevelOrder < b.alarmLevelOrderMonatsbericht) {
              bAlarm = b.alarmLevelOrderMonatsbericht;
            } else if (!b.alarmLevelOrder && b.alarmLevelOrderMonatsbericht) {
              bAlarm = b.alarmLevelOrderMonatsbericht;
            }

            // console.log('sortiere alarme aAlarm=' + aAlarm + ' bAlarm=' + bAlarm);
            if (aAlarm && bAlarm) {
                if (aAlarm > bAlarm) return 1;
                if (aAlarm < bAlarm) return -1;
            }
            if (aAlarm && !bAlarm) return 1;
            if (bAlarm && !aAlarm) return -1;
            return 0;

          } else if (key == 'mangel') {

            var am = a.anzahlOffeneMaengel ? a.anzahlOffeneMaengel : 0;
            var bm = b.anzahlOffeneMaengel ? b.anzahlOffeneMaengel : 0;
            // console.log('sortiere mängel am=' + am + ' bm=' + bm);
            if (am > bm) return 1;
            if (am < bm) return -1;
            return 0;

          } else {
            // Let b-table handle sorting other fields (other than `date` field)
            return false
          }
        },
        /** Kunde wurde ausgewählt, zeige Dashboard dieses Kunden */
        customerSelected: function (item) {

          const customer = {
            id: item.customerId,
            cardcode: item.cardcode,
            biotechCountry: {
              name: item.mandant
            },
            kunde: item.kunde,
            zipcode: item.zipcode,
            ort: item.ort,
            adresse: item.adresse,
            dateCreated: item.dateCreated,
            land: item.land,
            tel1: item.tel1,
            tel2: item.tel2,
            mobil: item.mobil
          }
          this.setCustomerAndUserRights(customer);
          this.$router.push('/Besuchsbericht');

        },
        init: function () {

          this.anzahl = 0;
          this.customers = [];
          this.getCustomers();

        },
        /** hole die Kunden */
        getCustomers: function () {

          console.info('getCustomers', this.$store.getters.standorte);

          // prüfe ob bereits gecacht in Vuex
          if (this.$store.getters.standorte && !this.$store.getters.loggedInAs) {
            this.customers = this.$store.getters.standorte;
          } else {

            // bei einem eingeloggten Key User nur die Kunden dieses Key Users holen
            var keyUserId     = null;
            var url           = process.env.VUE_APP_SERVER_URL + '/datenbroker/customers';
            var userId        = null;
            var mandant       = null;
            this.showOverlay  = true;

            let areasAllowed = null;
            if (this.$store.getters.user_role.authority == this.ROLE_KEY_USER) {
              keyUserId = this.$store.getters.user.keyUserId;
              mandant   = this.$store.getters.user.biotechCountry.name;
            } else if (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER) {
              userId =    this.$store.getters.user.id;
              url    = process.env.VUE_APP_SERVER_URL + '/biotechUser/findCustomersOfBiotechUser';
              if (this.$store.getters.showAreas && this.$store.getters.showAreas.length > 0) {
                areasAllowed = this.$store.getters.showAreas.join(',');
              }
            }

            let params = {
              mandant:        mandant ? mandant : this.mandant,
              keyUserId:      keyUserId,
              biotechUser_id: userId,
              areasAllowed:   areasAllowed,
              showDetails:    this.showDetails,
            }

            var that = this;
            axios({
              method: 'GET',
              params: params,
              url: url
            }).then(function (response) {

              console.log('response ist da, Customerlist');
              if (response.data && response.data.customerListAnalytics) {
                that.customers = response.data.customerListAnalytics;
                // speichere in Vuex Cache:
                that.$store.commit('setStandorte', that.customers);
              }
              that.showOverlay = false;

            }).catch(error => {
              console.log(error);
              that.showOverlay = false;
            });

            // Achtung, wenn action === 'SELECT-VERTRAEGE' dann dürfen nur Kunden gezeigt werden,
            // bei denen der User wirklich KeyUser ist, nicht die bei denen er von anderen
            // KeyUser hinzugefügt wurde.
            if (
                this.$store.getters.user_role &&
                this.$store.getters.user_role.authority != this.ROLE_ADMIN &&
                (!this.action || this.action !== 'SELECT-VERTRAEGE')
            ) {

              // Sonderfall: ein KeyUser kann trotzdem noch von einem anderen KeyUser Kunden zugewiesen bekommen haben
              userId = this.$store.getters.user.id
              if (params.biotechUser_id && params.biotechUser_id == userId) {
                console.log('Customer müssen nicht neu gelesen werden, für diesen User schon erledigt')
              } else {

                url = process.env.VUE_APP_SERVER_URL + '/biotechUser/findCustomersOfBiotechUser'
                params = {
                  mandant:        this.mandant,
                  showDetails:    this.showDetails,
                  biotechUser_id: userId,
                  areasAllowed:   areasAllowed
                };
                axios({
                  method: 'post',
                  params: params,
                  url: url
                }).then(function (response) {

                  for (var i = 0; i < response.data.customerListAnalytics.length; i++) {
                    var exists = false;
                    if (that.customers) {
                      for (var j = 0; j < that.customers.length; j++) {
                        if (that.customers[j].customerId == response.data.customerListAnalytics[i].customerId) {
                          exists = true;
                          break;
                        }
                      }
                    }
                    if (!exists && (response.data.customerListAnalytics[i].lastBesuchsberichtId || response.data.customerListAnalytics[i].lastMonatsberichtId)) {
                      // markieren, dass der User hier kein Key User ist:
                      response.data.customerListAnalytics[i].notKeyUser = true
                      that.customers.push(response.data.customerListAnalytics[i]);
                      // speichere in Vuex Cache:
                      that.$store.commit('setStandorte', that.customers);
                    }
                  }

                }).catch(error => {
                  console.log(error)
                })

              }

            }

          } // ende else keine customer im cache

          // ich habe alle Kunden, passe die Anzahl an:
          this.anzahl = this.customers.length;

        },
        /**
         * liefert das Datum des letzten Besuchsberichts oder des letzten Monatsbericht
         * @param data
         * @returns {*|string}
         */
        getDatumOfBesuchsbericht: function (data) {

          if (data) {

            const endedMoment         = data.ended ? this.javaDateToMoment(data.ended) : null;
            const monatsberichtMoment = data.monatsberichtStarted ? this.javaDateToMoment(data.monatsberichtStarted) : null;

            if (endedMoment && monatsberichtMoment && endedMoment.isAfter(monatsberichtMoment)) {
              return this.javaDateToDefaultDateFormat(data.ended);
            } else if (endedMoment && monatsberichtMoment && endedMoment.isSame(monatsberichtMoment)) {
              return this.javaDateToDefaultDateFormat(data.monatsberichtStarted);
            } else if (endedMoment && monatsberichtMoment && endedMoment.isBefore(monatsberichtMoment)) {
              return this.javaDateToDefaultDateFormat(data.monatsberichtStarted);
            } else if (endedMoment && !monatsberichtMoment) {
              return this.javaDateToDefaultDateFormat(data.ended);
            } else if (!endedMoment && monatsberichtMoment) {
              return this.javaDateToDefaultDateFormat(data.monatsberichtStarted);
            }

          }
          return '---';

        }

      }
    }

</script>
