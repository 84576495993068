<template>

    <span class="keyUserAktivWrapper" v-if="isKeyUserAktiv != null">
        <b-icon icon="check" v-if="isKeyUserAktiv" style="color: green"></b-icon>
        <b-icon icon="exclamation-triangle"
                v-if="!isKeyUserAktiv" style="color: red"></b-icon>
    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
      name: 'KeyUserOfUser',
      props: ['value'],
      data () {
        return {
          isKeyUserAktiv: null
        }
      },
      watch: {
        value: function () {
          this.init();
        }
      },
      created () {

        this.init();

      },
      methods: {

        init: function () {

          const that = this

          const url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/isKeyUserAktiv'
          const params  = {
            user_id: this.value.id,
          }

          axios({
            method: 'post',
            params: params,
            url: url
          }).then(response => {

            that.isKeyUserAktiv = response.data.isKeyUserAktiv

          }).catch(error => {
            console.log(error)
          })

        }

      }

    }

</script>
