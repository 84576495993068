<script>

  import { Line } from 'vue-chartjs';

  export default {
    extends: Line,
    props: {
      chartdata: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: null
      }
    },
    mounted () {
      this.renderChart(this.chartdata, this.options);
      var chart = this.$data._chart;
      this.$emit('set-chart', chart);
    }
  }

</script>
