<template>

    <b-overlay :show="!problemTypen || problemTypen.length < 1" rounded="sm">
        <template #overlay>
            <div class="text-center" style="margin-top: 35px;">
                <b-icon icon="three-dots" animation="cylon" font-scale="2"></b-icon>
            </div>
        </template>
        <div v-if="problemTypen">
          <b-form-select v-model="selected" :options="options" />
        </div>
    </b-overlay>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    // Define a new component
    export default {
        name: 'SelectProblemtyp',
        props: ['mandant', 'value'],
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              selected: null,
              options: null,
              problemTypen: null
            }
        },
        mounted: function () {

          this.init();

        },
        watch: {

          selected: function (newVal, oldVal) {
            if (newVal && oldVal && newVal.id != oldVal.id) {
              this.emitme();
            } else if (newVal) {
              this.emitme();
            } else if (newVal == null && oldVal) {
              this.selected = null;
            }
          },
          mandant: function () {
            this.init();
          },
          value: function (newVal) {
            this.selected = newVal
          }

        },
        methods: {

          init: function () {

            if (this.mandant) {

              console.log ('init find Problemtyp ...');
              this.positionen = [];
              this.options    = [
                { value: null, text: '--' }
              ];
              this.selected   = null;

              let that      = this;
              const url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getProblemtypen';
              const params  = {
                mandant: this.mandant
              };
              axios ({
                method: 'GET',
                params: params,
                url: url
              }).then(response => {

                console.log('response getProblemtypen', response.data);
                that.problemTypen = response.data.problemTypen;
                if (that.problemTypen && that.problemTypen.length > 0) {
                  for (var i = 0; i < that.problemTypen.length; i++) {
                    that.options.push({
                      value: that.problemTypen[i],
                      text: that.problemTypen[i].name
                    });
                  }
                }

              });

            }

          },
          emitme: function () {
            this.$emit('input', this.selected);
          }

        }

    }

</script>
