<template>

    <span class="schulungsunterlagen" v-if="person">

        <div class="card-body" v-if="person.schulungsunterlagen && person.schulungsunterlagen.length > 0">

            <div>
                <h4 class="text-left" v-html="$t('message.schulungsunterlagen_kompetent')"></h4>
                <b-button variant="outline-primary" size="sm" @click="$bvModal.show('modal-schulungen-' + person.id + randomer)">
                    {{ person.schulungsunterlagen.length }} {{ $t('message.schulungsunterlagen_kompetenz_anzeigen') }}&nbsp;<b-icon icon="caret-right"></b-icon>
                </b-button>
            </div>

        </div>
        <b-modal :id="'modal-schulungen-' + person.id + randomer" title="Kompetenznachweise" size="xl" :ok-only="okOnly">
            <b-list-group>
                <b-list-group-item v-for="schulungsunterlage in person.schulungsunterlagen" :key="schulungsunterlage.id">
                    <a :href="schulungsunterlage.url" target="_blank">
                        <b-icon-file-richtext class="mr-1"></b-icon-file-richtext> <b-badge variant="primary" pill v-if="schulungsunterlage.datum">{{ schulungsunterlage.datum }}</b-badge> {{ schulungsunterlage.bezeichnung ? schulungsunterlage.bezeichnung : 'Details' }}
                    </a>
                </b-list-group-item>
            </b-list-group>
        </b-modal>

    </span>

</template>

<script>

    // Define a new component
    export default {

        name: 'Schulungsunterlagen',
        props: {

            /** eine Person dessen Schulungsunterlagen angezeigt werden sollen */
            person: {
                type: Object,
                required: true
            }

        },
        data: function () {
            return {
              randomer:  '' + Math.random(),
              okOnly: true
            }
        }

    }

</script>
