<template>

    <span class="lineTempSensorList" v-if="value">

        <div class="row">
          <div class="col">
            <label>{{ $t('message.positionDetails_seriennummer') }}: {{ value.sensor.code }}</label>
          </div>
          <div class="col">
            <sensor-alarm-color v-if="value.lastSensorResult" v-model="value.lastSensorResult" />
          </div>
        </div>

        <!-- Datum anzeigen -->
        <div class="row">
          <div class="col">
            <label>{{ printWeek }}</label>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <b-card title="Temperatur °C">
              <line-temp-sensor v-model="value.sensor" type="TEM" :jahr="year" :woche="week" />
            </b-card>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <b-card title="Luftfeuchtigkeit %">
              <line-temp-sensor v-model="value.sensor" type="HUM" :jahr="year" :woche="week" />
            </b-card>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <b-card title="Taupunkt °C">
              <line-temp-sensor v-model="value.sensor" type="DEW" :jahr="year" :woche="week" />
            </b-card>
          </div>
        </div>
    </span>

</template>

<script>

    import LineTempSensor from '@/components/charts/LineTempSensor.vue'
    import moment from 'moment/moment'
    import SensorAlarmColor from '@/components/sensor/SensorAlarmColor.vue'

    export default {
      name: 'LineTempSensorList',
      components: {
        SensorAlarmColor,
        LineTempSensor,
      },
      props: [
          /** value = SensorCustomer */
          'value',
      ],
      data: function () {
        return {
          year: new Date().getFullYear(),
          week: moment().week(),
        }
      },
      computed: {

        printWeek: function () {

          const dat = moment().year(this.year).week(this.week)
          const monday = dat.clone().weekday(0)
          const sunday = dat.clone().weekday(6)

          let resp
          resp = monday.format('DD.MM.YYYY')
          resp += ' - ' + sunday.format('DD.MM.YYYY')
          return resp

        },

      },
      created: function () {

        this.init()

      },
      methods: {

        init: function () {

          console.log('init Line Temp Sensoren List')

        },

      },

    }

</script>