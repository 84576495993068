<template>

    <div id="content" role="main">

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.export_h1") }}</h1>
          </div>
        </div>
      </div>
      <div class="container mt-5">

        <p>
            {{ $t("message.export_p") }}
        </p>

        <b-overlay :show="showOverlay" rounded="sm">

          <b-form @submit.stop.prevent="onSubmit">

            <div class="row">

              <div class="col-md-6">
                <b-form-group
                    id="ab-1"
                    :label="$t('message.export_datum_ab') + ':'"
                    label-for="ab"
                    :description="$t('message.export_startdatum')">
                  <b-form-input
                      id="ab"
                      v-model="ab"
                      type="date"
                      :state="validationAb"
                      :placeholder="$t('message.export_select_datum')"
                      required>
                  </b-form-input>
                  <b-form-invalid-feedback :state="validationAb">
                      {{ $t("message.export_validationab") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="col-md-6">
                <b-form-group
                    id="bis-1"
                    :label="$t('message.export_datum_bis') + ':'"
                    label-for="bis"
                    :description="$t('message.export_enddatum')">
                  <b-form-input
                      id="bis"
                      v-model="bis"
                      type="date"
                      :state="validationBis"
                      :placeholder="$t('message.export_select_datum')"
                      required>
                  </b-form-input>
                  <b-form-invalid-feedback :state="validationBis">
                      {{ $t("message.export_validationbis") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>

              <div class="col-md-6">

                <b-form-group
                    id="format-1"
                    :label="$t('message.export_dateiformat') + ':'"
                    label-for="format"
                    :description="$t('message.export_dateiformat_des')">
                  <b-form-select v-model="format" :options="options" id="format" name="format" />
                </b-form-group>

              </div>

            </div>

            <b-button variant="outline-primary" type="submit" :disabled="!formValid" class="mb-4 ml-1">
              <b-icon icon="arrow-up-right-square" /> {{ $t("message.export_start") }}
            </b-button>

          </b-form>

          <template #overlay>
            <div class="text-center">
              <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label">{{ $t('message.export_berechnen') }} ...</p>
            </div>
          </template>

        </b-overlay>

      </div>

    </div>

</template>

<script>

  import axios from 'axios';
  axios.defaults.withCredentials = true;
  import moment from "moment";
  import bootbox from "bootbox";

  export default {

    name: 'Export',
    components: {
    },
    data: function () {
      return {
        ab: null,
        bis: null,
        format: null,
        options: [
          { value: 'XLS', text: 'Excel' },
          { value: 'CSV', text: 'Comma-separated values (csv)' },
        ],
        /** Loading spinner */
        showOverlay: false
      }
    },
    computed: {

      validationAb: function () {
        if (!this.ab) return false;
        const mom = moment(this.ab, 'YYYY-MM-DD');
        if (!mom) return false;
        return true;
      },
      validationBis: function () {
        if (!this.bis) return false;
        const momBis = moment(this.bis, 'YYYY-MM-DD');
        if (!momBis) return false;
        const momAb = moment(this.ab, 'YYYY-MM-DD');
        if (moment(momBis).isBefore(momAb)) return false;
        return true;
      },
      formValid: function () {

        if (!this.validationAb) return false;
        if (!this.validationBis) return false;
        return true;

      }

    },
    mounted: function () {

      // bene: Bitte nimm noch als Default Zeitraum die letzten 5 Jahre
      this.ab = moment().subtract(5, 'years').startOf('year').format('YYYY-MM-DD');
      this.bis = moment().format('YYYY-MM-DD');
      this.format = this.options[0].value;

    },
    methods: {

      onSubmit: function () {

        this.showOverlay = true;

        const startTimestamp = moment(this.ab,  'YYYY-MM-DD').unix() * 1000;
        const endTimestamp   = moment(this.bis, 'YYYY-MM-DD').unix() * 1000;

        let that      = this;
        const url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTrendauswertungFile';
        const params  = {
          customer_id: this.$store.getters.customer.id,
          start: startTimestamp,
          end: endTimestamp,
          filetype: this.format
        };
        axios({
          method: 'post',
          params: params,
          url: url,
          responseType: 'blob'
        }).then( response => {

          // check if no content:
          if (response.status == 204) {
            bootbox.alert(that.$t('message.export_keine_daten'));
            that.showOverlay = false;
            return null;
          }

          let a = document.createElement('a');
          let url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = 'export.' + that.format.toLowerCase();
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          that.showOverlay = false;

        }).catch(error => {
          console.log(error);
          that.showOverlay = false;
          alert(that.$t('message.export_error'));
        });

      }

    }

  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .unread {
    font-weight: bold;
  }

</style>
