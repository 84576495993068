<template>

    <span class="notificationNeuerMangel">
      <div class="row">
        <div class="col-md-6">
          <b-form-checkbox
                  :id="'checkbox-notificationNeuerMangel-' + (customer ? customer.id : '')"
                  v-model="isChecked"
                  name="isChecked"
                  value="true"
                  unchecked-value="false">
              {{ $t('message.notificationNeuerMangel') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
              :id="'checkbox-notificationNeuerMangel-app-' + (customer ? customer.id : '')"
              v-model="isCheckedApp"
              name="isCheckedApp"
              value="true"
              unchecked-value="false">
              {{ $t('message.notificationNeuerMangel_app') }}
          </b-form-checkbox>
        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'NotificationNeuerMangel',
      props: [
        /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
        'customer'
      ],
      data () {
        return {
          isChecked: null,
          isCheckedApp: null
        }
      },
      watch: {

        customer: function () {
          this.init();
        },
        isChecked: function(newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(newVal);
          }
        },
        isCheckedApp: function(newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(null, newVal);
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      mounted () {

        this.init();

      },
      methods: {

        change: function (newVal, newValApp) {

          console.log('neuer mangel isChecked geändert auf ' + newVal + ' ' + newValApp);

          // Notification setzen:
          var url     = process.env.VUE_APP_SERVER_URL + '/notification/save';
          var params  = {
            cardcode:             this.customer ? this.customer.cardcode : null,
            mandant:              this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:       this.$store.getters.user.id,
          }

          if (newVal != null) {
            params.neuerMangel = this.isChecked
          } else if (newValApp != null) {
            params.neuerMangelApp = this.isCheckedApp
          }

          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function () {

            console.log('Notififation neuerMangel gespeichert.');

          }).catch(error => {
            console.log(error);
          });

        },
        init: function () {

            console.log('init NotificationNeuerMangel ...')
            const url     = process.env.VUE_APP_SERVER_URL + '/notification/show';
            const params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id
            }

            const that = this;
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(function (response) {

                if (response.data.id) {
                  console.log('neuerMangel = ' + response.data.neuerMangel);
                  that.isChecked = response.data.neuerMangel ? true : false;
                  that.isCheckedApp = response.data.neuerMangelApp ? true : false;
                } else {
                  console.log('neuerMangel = keine Antwort, isChecked = false');
                  that.isChecked = false;
                  that.isCheckedApp = false;
                }

            }).catch(error => {
              console.log(error);
            });

          }

      }

    }

</script>