<!-- Status eines Devices in der Chart Legende anzeigen -->
<template>

    <span v-if="value">

      <div class="row" v-if="deinstalliertAm">
        <div class="col">
          <b-alert show variant="dark" style="margin-bottom: 0.3rem; padding: 0.2rem 1.25rem;">
             {{ $t('message.deviceStatusForChartLegende_deinstalliert').replace('{0}', value.traplinkedId).replace('{1}', objektBezeichnung) }}
             <java-date-to-default-date-format v-model="deinstalliertAm" />.
          </b-alert>
        </div>
      </div>
      <div class="row" v-else-if="deviceStatus">
        <div class="col text-right">
          <label>{{ $t('message.deviceStatusForChartLegende_status').replace('{0}', value.traplinkedId).replace('{1}', objektBezeichnung) }}:</label>
        </div>
        <div class="col">
          <traplinked-ergebnis v-model="deviceStatus.name" />
        </div>
      </div>
      <div class="row" v-else-if="keinErgebnis">
        <div class="col">
          <b-alert show variant="dark" style="margin-bottom: 0.3rem; padding: 0.2rem 1.25rem;">
            {{ $t('message.deviceStatusForChartLegende_kein_ergebnis').replace('{0}', value.traplinkedId).replace('{1}', objektBezeichnung) }}
          </b-alert>
        </div>
      </div>

    </span>

</template>
<script>

  // Define a new component
  import axios from "axios";
  import JavaDateToDefaultDateFormat from "@/components/layout/JavaDateToDefaultDateFormat";
  import TraplinkedErgebnis from "@/components/traplinkedbericht/TraplinkedErgebnis";

  export default {

    name: 'DeviceStatusForChartLegende',
    components: {
      JavaDateToDefaultDateFormat,
      TraplinkedErgebnis
    },
    props: [
        'value', // das Device
        'positionId' // ID der Position
    ],
    data () {
      return {
        /** der aktuelle Status des Devices abhängig vom Customer */
        deviceStatus:       null,
        keinErgebnis:       null,
        deinstalliertAm:    null,
        objektBezeichnung:  ''
      }
    },
    watch: {
      value: function () {
        this.init();
      }
    },
    created: function () {

      this.init();

    },
    methods: {

      /** holt den aktuellen Device Status abhängig vom Kunden */
      init: function () {

        this.deviceStatus    = null;
        this.keinErgebnis    = null;
        this.deinstalliertAm = null;

        if (this.value && this.value.id) {

          var url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedDeviceStatus';
          var params = {
            traplinked_device_id: this.value.id,
            customer_id: this.$store.getters.customer.id,
            position_id: this.positionId
          };

          var that = this;
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data && response.data.deinstalliertAm) {
              that.deinstalliertAm = response.data.deinstalliertAm;
            }
            if (response.data && response.data.deviceStatus) {
              that.deviceStatus = response.data.deviceStatus;
            } else if (response.data) {
              // es gibt noch kein Ergebnis:
              that.keinErgebnis = true
            }

            if (response.data && response.data.objektBezeichnung) {
              that.objektBezeichnung = response.data.objektBezeichnung;
            } else {
              that.objektBezeichnung = '';
            }

          });

        } // end if this.value

      }

    }

  }

</script>
