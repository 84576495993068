<template>

    <span :class="theClass"
          v-if="customer">
      <div class="row">
        <div class="col-md-6">
            <b-form-checkbox
                :id="'checkbox-' + customer.id"
                v-model="isChecked"
                name="isChecked"
                value="true"
                unchecked-value="false">
                {{ label }}
            </b-form-checkbox>
        </div>
        <div class="col-md-6">
            <b-form-checkbox
                :id="'checkbox-app-' + customer.id"
                v-model="isCheckedApp"
                name="isCheckedApp"
                value="true"
                unchecked-value="false">
                {{ labelApp }}
            </b-form-checkbox>
        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'DisableNotificationNeuerBesuchsbericht',
      props: [
          /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
          'customer',
          'label',
          'labelApp'
      ],
      data () {
        return {
          isChecked: null,
          isCheckedApp: null,
        }
      },
      watch: {

        customer: function () {
          this.init();
        },
        isChecked: function(newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(newVal);
          }
        },
        isCheckedApp: function(newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(null, newVal);
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      computed: {

        theClass: function() {

          if (this.label) {
            return 'disableNotificationNeuerBesuchsberichtLabel'
          }
          return 'disableNotificationNeuerBesuchsbericht'

        }

      },
      created () {

        this.init();

      },
      methods: {

        change: function (newVal, newValApp) {

          console.log('isChecked geändert auf ' + newVal + ' ' + newValApp);

            // Ausnahme setzen:
            var url     = process.env.VUE_APP_SERVER_URL + '/notification/save';
            var params  = {
              cardcode:                      this.customer.cardcode,
              mandant:                       this.customer.biotechCountry.name,
              biotechUser_id:                this.$store.getters.user.id,
            }

            if (newVal != null) {
              params.disableNeuerBesuchsbericht = this.isChecked
            } else if (newValApp != null) {
              params.disableNeuerBesuchsberichtApp = this.isCheckedApp
            }

            axios({
              method: 'post',
              params: params,
              url: url
            }).then(function () {

              console.log('Notififation Ausnahme gespeichert.');

            }).catch(error => {
              console.log(error);
            });

        },
        init: function () {

          if (this.customer) {

            var url     = process.env.VUE_APP_SERVER_URL + '/notification/show';
            var params  = {
              cardcode:         this.customer.cardcode,
              mandant:          this.customer.biotechCountry.name,
              biotechUser_id:   this.$store.getters.user.id
            };

            var that = this;
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(function (response) {

                if (response.data.id) {
                  console.log('disableNeuerBesuchsbericht = ' + response.data.disableNeuerBesuchsbericht);
                  that.isChecked = response.data.disableNeuerBesuchsbericht ? false : true;
                  that.isCheckedApp = response.data.disableNeuerBesuchsberichtApp ? false : true;
                } else {
                  console.log('keine Antwort, isChecked = true');
                  that.isChecked = true;
                  that.isCheckedApp = true;
                }

            }).catch(error => {
              console.log(error);
            });

          }

        }

      }

    }

</script>