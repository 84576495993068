import axios from 'axios'
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import bootbox from 'bootbox'
axios.defaults.withCredentials = true

/**
 * statische Methoden zum rendern von Plänen
 */
export class PlanHelper {

    static createVectorLayer(source) {

        return new VectorLayer({
            source: source,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33',
                    }),
                }),
            }),
        })

    }
    /**
     * Text eines Position Icons erzeugen
     * @param iconFeature
     * @param positionsnr
     * @param alarmLevel
     */
    static createPositionIconText(positionsnr, alarmLevel) {

        let color = '#000'
        if (alarmLevel && alarmLevel === 'GRUEN') {
            color = '#73b025'
        } else if (alarmLevel && alarmLevel === 'GELB') {
            color = '#eda512'
        } else if (alarmLevel && alarmLevel === 'ROT') {
            color = '#e3150f'
        }

        const fill = new Fill({
            color: color
        })
        const textstyle = new Text({
            fill: fill,
            stroke: new Stroke({
                color: '#fff',
                width: 4
            }),
            text: positionsnr,
            font: '12px Verdana',
            offsetX: 0,
            offsetY: 12
        })
        return textstyle

    }
    /**
     * holt Daten des Plans vom Server
     * @param plan
     * @returns {Promise<void>}
     */
    static async getPlanData(plan, cancelTokenSource) {

        const url = process.env.VUE_APP_SERVER_URL + '/plan/getPlanData'
        const params = {
            plan_id: plan.id
        }
        try {

            const response = await axios ({
                method:       'POST',
                params:       params,
                url:          url,
                cancelToken:  cancelTokenSource ? cancelTokenSource.token : null
            })
            return response.data

        } catch (e) {
            console.log('Fehler beim Laden eines Plans: ', e)
        }

    }
    /** holt die Areas */
    static async getPolygone(plan) {

        const url = process.env.VUE_APP_SERVER_URL + '/plan/getPolygone'
        const params = {
            plan_id: plan.id
        }

        const response = await axios ({
            method:       'POST',
            params:       params,
            url:          url
        })
        return response.data

    }
    /** hole Alarmlevel und Objekttypen */
    static async getPositionResult(id, cancelTokenSource) {

        const response = await axios ({
            method: 'POST',
            params: {
                position_id: id
            },
            url: process.env.VUE_APP_SERVER_URL + '/datenbroker/getPositionResult',
            cancelToken:  cancelTokenSource ? cancelTokenSource.token : null
        })
        return response

    }
    static async getMaengelCoordinates(customer, plan) {

        const url= process.env.VUE_APP_SERVER_URL + '/plan/getMaengel'
        const params= {
            plan_id:      plan.id,
            customer_id:  customer.id
        }

        const response = await axios ({
            method: 'GET',
            params: params,
            url:    url
        })

        return response.data

    }
    /** holt die Positionen und stellt sie dar */
    static async getPositions(customer, plan, cancelTokenSource) {

        const url = process.env.VUE_APP_SERVER_URL + '/plan/getPositions'
        const params = {
            plan_id:      plan.id,
            customer_id:  customer.id
        }

        try {

            const response = await axios ({
                method:       'post',
                params:       params,
                url:          url,
                cancelToken:  cancelTokenSource ? cancelTokenSource.token : null
            })

            return response.data

        } catch(e) {
            bootbox.alert(this.$t('message.plan_position_load_error'))
        }

    }
    /**
     * holt die Koordinaten einer Position
     * @param position
     * @returns {Promise<void>}
     */
    static async getPositionCoordinates(position) {

        const url = process.env.VUE_APP_SERVER_URL + '/plan/getPositionCoordinates'
        const params = {
            position_id: position.id
        }
        const response = await axios ({
            method: 'GET',
            params: params,
            url: url,
        })

        return response.data.coordinates

    }
    /**
     * liest Plan Details und liefert Promise
     * @param id
     * @param cardcode
     * @param mandant
     * @returns {Promise<any>}
     */
    static async readPlan(id, cardcode, mandant) {

        const url = process.env.VUE_APP_SERVER_URL + '/plan/getPlan'
        const params = {
            id: id,
            cardcode: cardcode,
            mandant: mandant,
        }

        const response = await axios ({
            method: 'POST',
            params: params,
            url: url
        })

        return response.data

    }
    /**
     * holt zu einem Ojekttypen typ den Icon
     */
    static getIconSrc(objekttyp, alarmLevel, customer) {

        if (alarmLevel.enumType) {
            alarmLevel = alarmLevel.name
        }

        // Achtung DE und AT haben unterschiedliche Objekttyp Nummern: mappe auf die richtigen Bilderr
        if (objekttyp && customer.biotechCountry.name == 'AT') {
            switch (objekttyp) {
                case '100': // Lichtfalle
                    objekttyp = 'LICHT'
                    break
                case '200': // Schabenfalle
                    objekttyp = 'SCHABE'
                    break
                case '300': // Mottenfalle
                    objekttyp = 'MOTTE'
                    break
                case '310': // Pheromondispenser
                    objekttyp = 'FEROMON'
                    break
                case '400': // Mäuseköderstation
                    objekttyp = 'MAUS'
                    break
                case '500': // Schlagfalle
                    objekttyp = 'SCHLAGFALLE'
                    break
                case '550': // Biotech247 Zubehör
                    objekttyp = 'BIOTECH247'
                    break
                case '600': // Triboliumfalle
                    objekttyp = 'KAEFER'
                    break
                case '700': // Insektenfalle
                    objekttyp = 'GELSE'
                    break
                case '710': // Pelz- und Teppichkäfer
                    objekttyp = 'KAEFER2'
                    break
                case '720': // Ameisenfalle
                    objekttyp = 'AMEISE'
                    break
                case '800': // Nagerköderstation
                    objekttyp = 'RATTE'
                    break
                case '900': // Fruchtfliegenfallen
                    objekttyp = 'FLIEGE'
                    break
                case '1000': // Sichtkontrollpunkt
                    objekttyp = 'LUPE'
                    break
                case '1100': // Sensor
                    objekttyp = 'TEMP'
                    break
            }
        } else if (objekttyp && customer.biotechCountry.name == 'DE') {
            switch (objekttyp) {
                case '100': // Lichtfalle
                    objekttyp = 'LICHT'
                    break
                case '200': // Schabenfalle
                    objekttyp = 'SCHABE'
                    break
                case '300': // Mottenfalle
                    objekttyp = 'MOTTE'
                    break
                case '310': // Pheromondispenser
                    objekttyp = 'FEROMON'
                    break
                case '400': // Mäuseköderstation
                    objekttyp = 'MAUS'
                    break
                case '450': // Schlagfalle
                    objekttyp = 'SCHLAGFALLE'
                    break
                case '500': // Triboliumfalle
                    objekttyp = 'KAEFER'
                    break
                case '550': // Biotech247 Zubehör
                    objekttyp = 'BIOTECH247'
                    break
                case '600': // Mäusefalle
                    objekttyp = 'MAUSFALLE'
                    break
                case '700': // Insektenfalle
                    objekttyp = 'GELSE'
                    break
                case '710': // Pelz- und Teppichkäfer
                    objekttyp = 'KAEFER2'
                    break
                case '720': // Ameisenfalle
                    objekttyp = 'AMEISE'
                    break
                case '800': // Nagerköderstation
                    objekttyp = 'RATTE'
                    break
                case '900': // Fruchtfliegenfallen
                    objekttyp = 'FLIEGE'
                    break
                case '1000': // Sichtkontrollpunkt
                    objekttyp = 'LUPE'
                    break
                case '1100': // Sensor
                    objekttyp = 'TEMP'
                    break
            }
        }

        if (!objekttyp) {
            objekttyp = 'marker'
        }

        var color = alarmLevel ? alarmLevel : 'BLACK'
        if (color == 'BLACK') {
            console.log('--> ACHTUNG: keine Farbe vorhanden!')
        }
        try {
            return require('@/assets/images/icons/map-' + objekttyp + '-' + color + '.svg')
        } catch (e) {
            console.log('Kein Icon gefunden für @/assets/images/icons/map-' + objekttyp + '-' + color + '.svg')
        }
        return require('@/assets/images/icons/map-marker-BLACK.svg')

    }

}