<template>

    <div id="content" role="main">

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t("message.traplinkedImages_nav") }}</h1>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-5">

        <b-overlay :show="showOverlay" rounded="sm" style="min-height: 300px;">

            <div class="panel-body">

              <div class="row align-items-end">
                <div class="col-md-2">
                  <b-form-checkbox v-model="checkAll"
                                   class="ml-4 mb-1"
                                   id="checkAll">
                    alle auswählen
                  </b-form-checkbox>
                </div>
                <div class="col-md-4">

                  <!-- was tun mit ausgewählten? -->
                  <div v-if="hasChecked" class="mb-1">

                    <!-- ausgewählte löschen -->
                    <b-button variant="outline-danger" size="sm" @click="deleteChecked()">
                      <b-icon icon="trash"></b-icon> {{ $t('message.default_loeschen') }}
                    </b-button>

                    <!-- ausgewählte bewerten -->
                    <div class="btn-group ml-3" role="group" aria-label="Bewertung">
                      <!-- kein Task vorhanden, setze einfach rot, grün, gelb -->
                      <button type="button"
                              class="btn btn-outline-success btn-sm"
                              @click="setAlarmLevelChecked('GRUEN')">
                        {{ $t('message.default_ok') }}
                      </button>
                      <button type="button"
                              class="btn btn-outline-warning btn-sm"
                              @click="setAlarmLevelChecked('GELB')">
                        {{ $t('message.charts_warnung') }}
                      </button>
                      <button type="button"
                              class="btn btn-outline-danger btn-sm"
                              @click="setAlarmLevelChecked('ROT')">
                        {{ $t('message.charts_gefahr') }}
                      </button>
                    </div>

                  </div>

                </div>
              </div>

            </div>

            <b-list-group v-if="traplinkedImages != null && traplinkedImages.length > 0">
              <b-list-group-item v-for="reportImage in traplinkedImages" :key="reportImage.id">
                <div class="row">
                  <div class="col-md-6">
                    <div style="position: relative">
                      <div class="checkboxWrapper">
                        <b-form-checkbox v-model="reportImage.checked"
                                         :on-change="computeHasChecked()"
                                         :id="'check_' + reportImage.id">
                          auswählen
                        </b-form-checkbox>
                      </div>
                      <b-img :src="reportImage.src"
                             fluid
                             style="cursor: pointer"
                             @click="resize(reportImage)"
                             :alt="$t('message.traplinkedImages_alt')"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">

                    <form>

                      <div class="row">
                        <div class="col">
                          <b-form-group :id="'time' + reportImage.id"
                                        :label="$t('message.traplinkedImages_date_time')"
                                        :label-for="'time-l' + reportImage.id">
                            <b-form-input
                                :id="'time-l' + reportImage.id"
                                v-model="reportImage.dateTime"
                                readonly="readonly">
                            </b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col">
                          <b-form-group :id="'customer-nr' + reportImage.id"
                                        :label="$t('message.printPositionen_kundennummer') + ':'"
                                        :label-for="'customer-nr-l' + reportImage.id">
                            <b-form-input
                                :id="'customer-nr-l' + reportImage.id"
                                v-model="reportImage.customer.cardcode"
                                readonly="readonly">
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <b-form-group :id="'customer' + reportImage.id"
                                        :label="$t('message.customerNotifications_standort') + ':'"
                                        :label-for="'customer-l' + reportImage.id">
                            <b-form-input
                                :id="'customer-l' + reportImage.id"
                                v-model="reportImage.customer.kunde"
                                readonly="readonly">
                            </b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col">
                          <b-form-group :id="'customer-add' + reportImage.id"
                                        :label="$t('message.customerList_address') + ':'"
                                        :label-for="'customer-add' + reportImage.id">
                            <b-form-input
                                :id="'customer-add' + reportImage.id"
                                v-model="reportImage.customer.adresse"
                                readonly="readonly">
                            </b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col" v-if="reportImage.position && reportImage.position.id">
                          <b-form-group :id="'Position' + reportImage.id"
                                        :label="$t('message.maengelTable_pos_beschreibung') + ':'"
                                        :label-for="'Position-l' + reportImage.id">
                            <input type="text"
                                   :id="'Position-l' + reportImage.id"
                                   :value="reportImage.position.positionsNr + ' ' + reportImage.position.positionsBesch"
                                   class="form-control"
                                   readonly="readonly"
                            />
                          </b-form-group>
                        </div>
                        <div class="col" v-if="reportImage.traplinkedDevice && reportImage.traplinkedDevice.id">
                          <b-form-group :id="'Falle' + reportImage.id"
                                        :label="$t('message.traplinkedStatus_24_7_name') + ':'"
                                        :label-for="'Falle-l' + reportImage.id">
                            <input type="text"
                                   :id="'Falle-l' + reportImage.id"
                                   :value="reportImage.traplinkedDevice.traplinkedId + ' ' + reportImage.traplinkedDevice.name"
                                   class="form-control"
                                   readonly="readonly"
                            />
                          </b-form-group>
                        </div>
                      </div>
                      <b-form-group :label="$t('message.plan_bemerkungen') + ':'"
                                    :label-for="'bemerkung-' + reportImage.id">

                        <b-form-textarea
                            :id="'bemerkung-' + reportImage.id"
                            v-model="reportImage.traplinkedReport.description"></b-form-textarea>

                      </b-form-group>

                      <div class="row">
                        <div class="col">

                          <b-form-group :label="$t('message.printErgebnisliste_ergebnis') + ':'">

                            <div v-if="reportImage.task && reportImage.task.id">

                              <!-- entweder Auswahl aus Required Text - wenn uVagAusprid des Task == 2 -->
                              <select v-model="reportImage.resultValue"
                                      v-if="reportImage.task.uVagAusprid == '2'"
                                      class="form-control">
                                <option v-for="option in reportImage.task.requiredTexts"
                                        :key="option.id"
                                        :value="option.beschreibung">
                                  {{ option.beschreibung }}
                                </option>
                              </select>
                              <!-- ... oder Zahlenwert -->
                              <input type="number"
                                     class="form-control"
                                     v-else
                                     v-model="reportImage.resultValue" />

                            </div>
                            <div class="btn-group" role="group" :aria-label="$t('message.printErgebnisliste_ergebnis')" v-else>
                              <!-- kein Task vorhanden, setze einfach rot, grün, gelb -->
                              <button type="button"
                                      class="btn btn-outline-success"
                                      :class="{'active': reportImage.alarmLevel && reportImage.alarmLevel == '${eu.utilo.dataBroker.enums.AlarmLevel.GRUEN.name()}' }"
                                      @click="setAlarmLevel(reportImage, '${eu.utilo.dataBroker.enums.AlarmLevel.GRUEN.name()}')">
                                {{ $t("message.traplinkedImages_gruen") }}
                              </button>
                              <button type="button"
                                      class="btn btn-outline-warning"
                                      :class="{'active': reportImage.alarmLevel && reportImage.alarmLevel == '${eu.utilo.dataBroker.enums.AlarmLevel.GELB.name()}' }"
                                      @click="setAlarmLevel(reportImage, '${eu.utilo.dataBroker.enums.AlarmLevel.GELB.name()}')">
                                {{ $t("message.traplinkedImages_gelb") }}
                              </button>
                              <button type="button"
                                      class="btn btn-outline-danger"
                                      :class="{'active': reportImage.alarmLevel && reportImage.alarmLevel == '${eu.utilo.dataBroker.enums.AlarmLevel.ROT.name()}' }"
                                      @click="setAlarmLevel(reportImage, '${eu.utilo.dataBroker.enums.AlarmLevel.ROT.name()}')">
                                {{ $t("message.traplinkedImages_rot") }}
                              </button>
                            </div>

                          </b-form-group>

                        </div>
                        <div class="col">

                          <b-form-group
                              :label="$t('message.biotechHeader_support') + ':'">

                            <!-- Service Call aus dem Bild erstellen -->
                            <servicecall-button :traplinked-report-image="reportImage" />

                          </b-form-group>

                        </div>
                      </div>

                      <hr />

                      <div class="row">
                        <div class="col">
                          <b-button variant="outline-dark"
                                    @click="setResultValue(reportImage)"
                                    size="sm">
                            <b-icon icon="save"></b-icon> {{ $t("message.default_speichern") }}
                          </b-button>
                        </div>
                        <div class="col text-right">
                          <b-button variant="outline-danger"
                                    @click="deleteImage(reportImage)"
                                    size="sm">
                            <b-icon icon="trash"></b-icon> {{ $t("message.default_loeschen") }}
                          </b-button>
                        </div>
                      </div>

                    </form>

                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>

            <b-alert v-else-if="traplinkedImages != null && traplinkedImages.length < 1" variant="success" show>
              <b-icon icon="emoji-smile"></b-icon> {{ $t("message.traplinkedImages_empty") }}
            </b-alert>

            <b-pagination v-if="total"
                          class="mt-2"
                          v-model="currentPage"
                          :total-rows="total"
                          per-page="50">
            </b-pagination>

            <template #overlay>
              <div class="text-center">
                <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                <p id="cancel-label">{{ $t('message.spinner_loading') }} ...</p>
              </div>
            </template>

        </b-overlay>

      </div>

      <b-modal id="modal-resize" :title="$t('message.maengelTable_show_details')" size="xl" hide-footer>

        <b-img v-if="detailImage"
               :src="detailImage.src"
               style="width: 100%; height: auto"
               alt="zu prüfendes Bild">
        </b-img>

      </b-modal>

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import ServicecallButton from '@/components/support/ServicecallButton.vue'
  import bootbox from 'bootbox'

  export default {

    name: 'TraplinkedImages',
    components: {
      ServicecallButton
    },
    data: function () {
      return {
        /** die zu prüfenden Bilder */
        traplinkedImages: null,
        showOverlay: false,
        currentPage: 1,
        total: 0,
        /** wenn true dann alle checkboxen ab/anwählen */
        checkAll: false,
        /** sind welche ausgewählt worden? */
        hasChecked: false,
        /** Bild das vergrößert angezeigt werden soll */
        detailImage: null,
      }
    },
    watch: {

      currentPage: function () {
        this.getImages()
      },
      checkAll: function () {

        for (let i = 0; i < this.traplinkedImages.length; i++) {
          this.traplinkedImages[i].checked = this.checkAll
        }
        this.computeHasChecked()

      }

    },
    mounted: function () {

      this.init()

    },
    methods: {

      /**
       * zeige Detailansicht
       */
      resize: function (img) {
        this.detailImage = img
        this.$bvModal.show('modal-resize')
      },
      computeHasChecked: function () {

        if (this.traplinkedImages) {

          for (let i = 0; i < this.traplinkedImages.length; i++) {
            if (this.traplinkedImages[i].checked) {
              this.hasChecked = true
              return this.hasChecked
            }
          }

        }

        this.hasChecked = false
        return this.hasChecked

      },
      /**
       * Alarm Level für ausgewählte setzen
       * @param alarm_level
       */
      setAlarmLevelChecked: function (alarm_level) {

        console.log('setAlarmLevelChecked', alarm_level)

        const that = this
        const text = this.$t('message.traplinkedImages_ergebnis_checked')
        bootbox.confirm(text, result => {

          if (result) {

            that.showOverlay = true
            that.setResultValue('CHECKED', null, alarm_level)

          }

        })

      },
      /**
       * Ergebnis vom Kunden speichern, auch löschen
       * @param image
       * @param deleteImage
       * @returns {null}
       */
      setResultValue: function (image, deleteImage, alarmLevel) {

        console.log('setResultValue', image)

        let ids = []
        if (image == 'CHECKED') {
          // alle ausgewählten löschen oder ändern
          for (let i = 0; i < this.traplinkedImages.length; i++) {
            if (this.traplinkedImages[i].checked) {
              ids.push(this.traplinkedImages[i].id)
            }
          }
        }

        // Fehler anzeigen, wenn nichts eingegeben wurde
        if (!ids && image && !image.resultValue && !deleteImage) {
          bootbox.alert(this.$t('message.traplinkedImages_ergebnis_eingeben'))
          return null
        }

        const pars = {
          description: image && image.traplinkedReport ? image.traplinkedReport.description : null,
          traplinked_report_image_ids: ids,
          result_value: image ? image.resultValue : null,
          customer_id: image && image.customer ? image.customer.id : null,
          position_id: image && image.position ? image.position.id : null,
          task_id: image && image.task ? image.task.id : null,
          traplinked_report_image_id: image ? image.id : null,
          deleted_by_customer: deleteImage ? true : false,
          alarm_level: alarmLevel,
        }

        const url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/saveTraplinkedImage'
        const that    = this

        axios({
          method: 'post',
          url: url,
          withCredentials: true,
          data: pars,
          headers: {
            // Header name and value
            'Content-Type': 'application/json'
          }
        }).then(daten => {

          console.log('erfolgreich gespeichert', daten)

          let variant = 'success'
          let headline = ''
          let body = ''
          if (deleteImage && image == 'CHECKED') {
            body     = that.$t('message.traplinkedImages_success_delete_checked')
            headline = that.$t('message.traplinkedImages_success_title_delete')
          } else if (deleteImage) {
            body     = that.$t('message.traplinkedImages_success_delete')
            headline = that.$t('message.traplinkedImages_success_title_delete')
          } else {
            body     = that.$t('message.traplinkedImages_success')
            headline = that.$t('message.traplinkedImages_success_title')
          }

          that.$bvToast.toast(body, {
            title: headline,
            autoHideDelay: 3000,
            variant: variant,
          })

          that.getImages()

        }).catch(error => {

          console.log('submit Error: ', error)
          bootbox.alert(that.$t('message.support_error'))

        })

      },
      /**
       * ausgewählte Bilder löschen
       */
      deleteChecked: function () {

        console.log('deleteChecked')

        const that = this
        const text = this.$t('message.traplinkedImages_delete_confirm_checked')
        bootbox.confirm(text, result => {

          if (result) {

            that.showOverlay = true
            that.setResultValue('CHECKED', true)

          }

        })

      },
      deleteImage: function (image) {

        console.log('deleteImage', image)

        const that = this
        const text = this.$t('message.traplinkedImages_delete_confirm')
        bootbox.confirm(text, result => {

          if (result) {

            that.setResultValue(image, true)

          }

        })

      },
      init: function () {

        this.showOverlay = true

        if (this.$store.getters.user_role) {

          let params = {}
          if (
              this.$store.getters.user_role &&
              (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER || this.$store.getters.user_role.authority == this.ROLE_ADMIN) &&
              this.$store.getters.customer
          ) {
            params.customer_id = this.$store.getters.customer.id;
          } else if (
              this.$store.getters.user_role &&
              this.$store.getters.user_role.authority == this.ROLE_KEY_USER &&
              this.$store.getters.user
          ) {
            params.key_user_id = this.$store.getters.user.keyUserId;
            params.mandant = this.$store.getters.user.biotechCountry.name;
          }

          const that = this

          // zähle vorhandene Bilder für die Pagination:
          const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/countTraplinkedImages'
          axios({
            method: 'GET',
            params: params,
            url: url,
          }).then( response => {

            that.total = response.data.anzahl

          }).catch(error => {
            console.log(error)
            alert(that.$t('message.export_error'))
          })

          this.getImages()

        }

      },
      getImages: function () {

        if (this.$store.getters.user_role) {

          this.showOverlay = true
          this.traplinkedImages = []

          let params = {}
          if (
              this.$store.getters.user_role &&
              (this.$store.getters.user_role.authority == this.ROLE_CUSTOMER || this.$store.getters.user_role.authority == this.ROLE_ADMIN) &&
              this.$store.getters.customer
          ) {
            params.customer_id = this.$store.getters.customer.id
          } else if (
              this.$store.getters.user_role &&
              this.$store.getters.user_role.authority == this.ROLE_KEY_USER &&
              this.$store.getters.user
          ) {
            params.key_user_id = this.$store.getters.user.keyUserId
            params.mandant = this.$store.getters.user.biotechCountry.name
          }

          const that = this

          params.offset = 50 * ( this.currentPage - 1 )
          const url = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedImages'
          axios({
            method: 'GET',
            params: params,
            url: url,
          }).then( response => {

            that.showOverlay = false;
            if (response.data && response.data.traplinkedReportImages) {

              that.traplinkedImages = response.data.traplinkedReportImages

            } else {
              // no content
            }

          }).catch(error => {
            console.log(error);
            that.showOverlay = false;
            alert(that.$t('message.export_error'));
          })

        }

      }

    }

  }

</script>

<style>
  .checkboxWrapper {
    position: absolute;
    background-color: rgba(255,255,255,0.5);
    padding: 0.5rem 0.3rem
  }
  .checkboxWrapper label {
    font-weight: normal;
    font-size: 1rem;
  }
</style>