<template>

  <div class="form-group row">
    <div class="col-md-2">
      <label for="captcha">{{ $t('message.captchaFormRow_label') }}</label>
    </div>
    <div class="col-md-2">
      <img v-if="captcha" :src="captcha" :alt="$t('message.captchaFormRow_label')"/>
    </div>
    <div class="col-md-8">
      <input type="text"
             class="form-control"
             id="captcha"
             name="captcha"
             :placeholder="$t('message.captchaFormRow_placeholder')"
             v-model="code">
    </div>
  </div>

</template>

<script>

import axios from 'axios'
axios.defaults.withCredentials = true

export default {

  name: 'CaptchaFormRow',
  data () {
    return {
      captcha: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null
    }
  },
  watch: {
    /** eingegebenen Code an Parent zurückgeben */
    code: function (val) {

      this.$emit('set-code', val)

    }
  },
  mounted: function () {

    this.getCaptcha()

  },
  methods: {

    /** holt das Captcha Image */
    getCaptcha: function () {

      const url = process.env.VUE_APP_SERVER_URL + '/mySimpleCaptcha/captcha'
      const that = this
      axios.get(url, {
        withCredentials: true
      }).then(function (response) {
          that.captcha = 'data:image/gif;base64,' + response.data.base64
      }).catch(function (error) {
          console.log(error)
      })

    }

  }

}

</script>
