<template>

    <div id="content" role="main">

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1 v-if="$store.getters.user_role.authority == ROLE_ADMIN">{{ $t("message.kundenliste_h1") }}</h1>
            <h1 v-else>{{ $t("message.benutzer_standorte") }}</h1>
          </div>
        </div>
      </div>
      <div class="container mt-5">

        <!-- bei einem Key User gleich die ganze Tabelle anzeigen, ohne Ajax Suche -->
        <div class="row" v-if="$store.getters.user_role.authority == ROLE_ADMIN">
          <div class="col-md-12">

            <!-- autocomplete für Kundensuche -->
            <v-select
                    label="kunde"
                    :options="options"
                    @search="onSearch"
                    :filterable="false"
                    v-model="selectedCustomer"
                    :placeholder="$t('message.kundenliste_suchen')"
                    @input="doSelectedCustomer">

                <!-- Text falls kein Ergebnis gefunden wurde -->
                <template #no-options="{ search }">
                    -- {{ $t("message.kundenliste_not_found").replace('{0}', search) }} --
                </template>
              <!-- Options Template -->
              <template #option="{ kunde, cardcode, plz, ort, adresse }">
                <span class="optionName">
                    {{ kunde }} ({{ cardcode }})
                </span>
                <span class="optionAdresse">
                  <small><br/>{{ plz }} {{ ort }}, {{ adresse }}</small>
                </span>
              </template>
              <!-- Template für ausgewählte Option -->
              <template #selected-option="{ kunde, cardcode }">
                <span class="optionName">
                    {{ kunde }} ({{cardcode}})
                </span>
              </template>

            </v-select>

          </div>
        </div>
      </div>
      <!-- Kundenliste für AT und DE -->
      <transition name="fade" mode="out-in">
        <div v-if="$store.getters.anzahlCustomer > 1 || $store.getters.user_role.authority == ROLE_ADMIN" class="mt-5">

          <!-- cards nur bei einem Admin anzeigen, bei Key User einfach eine Kundenliste -->
          <b-card no-body v-if="$store.getters.user_role.authority == ROLE_ADMIN">
            <b-tabs card>
              <b-tab :title='$t("message.kundenliste_austria")' active>
                <b-card-text>
                  <customer-list :mandant="'AT'" :show-details="true" />
                </b-card-text>
              </b-tab>
              <b-tab :title='$t("message.kundenliste_germany")'>
                <b-card-text>
                  <customer-list :mandant="'DE'" :show-details="true" />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
          <div v-else>

            <customer-list :show-details="true" />

          </div>

        </div>
      </transition>
    </div>

</template>

<script>

  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css'
  import axios from 'axios'
  axios.defaults.withCredentials = true

  export default {
    name: 'Kundenliste',
    components: {
      vSelect
    },
    data () {
      return {
        /** aus Select ausgewählter Kunde */
        selectedCustomer: null,
        /** Optionen/Kunden zur Auswahl */
        options: [],
        /** AJAX Suche steuern, damit keine Race Condition entsteht */
        lastRequest: null
      }
    },
    mounted () {
      // Umami Tracking aufrufen
      this.doTracking(this.$route.name);
    },
    methods: {

      /** AJAX Suche */
      onSearch: function (search, loading) {

        // erst ab einer Zeichenlänge von 3 Zeichen mit der Suche starten
        if (search && search.length >= 3) {

          loading(true)
          console.log('Search Kunde: ' + search)
          var that = this
          // GET request
          if (this.lastRequest) {
            clearTimeout(this.lastRequest)
          }
          this.lastRequest = setTimeout(function () {

            var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/findCustomers'
            var params  = {
              term: search,
              showActive: true, // nur aktive Kunden anzeigen
            }
            axios({
              method: 'POST',
              params: params,
              url: url
            }).then(response => {

              console.log('Suchergebnis: ' + response.data)
              that.options = response.data
              loading(false)

            }).catch(error => {
              console.log(error)
            })

          }, 300)

        }

      },
      /**
       * ein Kunde wurde vom Benutzer ausgewählt
       * @param val Customer
       */
      doSelectedCustomer: function (val) {

        console.log('Customer wurde ausgewählt ' + (val ? val.id : ''));

        this.selectedCustomer = val;
        this.setCustomerAndUserRights(this.selectedCustomer);
        this.$router.push('/Besuchsbericht');

      }

    }
  }

</script>