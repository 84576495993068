<template>

    <div id="content" role="main">

      <div class="row mt-5">

        <div class="col-md-12">
          <div class="content_title">
            <h1>{{ $t('message.benutzerOfCustomer_h1') }}</h1>
          </div>
        </div>

      </div>

      <div class="card mt-3">
        <div class="card-body benutzerliste">

          <div v-if="loading">

            <div class="row"><div class="col-md-12 text-center"><SpinnerImage /></div></div>

          </div>

          <transition name="fade" mode="out-in">
            <benutzer-liste v-if="benutzerliste" v-model="benutzerliste" />
          </transition>

        </div>
      </div>

    </div>

</template>

<script>

  import axios from "axios";
  axios.defaults.withCredentials = true;
  import SpinnerImage from '@/components/layout/SpinnerImage';
  import BenutzerListe from "@/components/benutzer/BenutzerListe";

  export default {
    name: 'BenutzerOfCustomer',
    components: {
      SpinnerImage,
      BenutzerListe
    },
    data () {
      return {
        benutzerliste: null,
        /** Benutzerliste wird geladen */
        loading: null
      }
    },
    mounted () {
      this.init();
    },
    watch: {
    },
    methods: {

      init: function () {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name);

        this.loading = true;
        this.showSpinner();
        var url         = process.env.VUE_APP_SERVER_URL + '/biotechUser/biotechUserOfCustomer';
        var that        = this;
        var params      = {
          cardcode: this.$store.getters.customer.cardcode,
          mandant:  this.$store.getters.customer.biotechCountry.name
        };
        axios ({
          method: 'get',
          params: params,
          url: url
        }).then(function (response) {

          that.hideSpinner();
          that.benutzerliste = response.data;
          that.loading = false;

        }).catch(error => {
          console.log(error);
          that.hideSpinner();
          that.loading = false;
        });

      }

    }

  }

</script>
