<template>

    <b-form-select v-model="selected" :options="auftraege" class="mb-3">
        <!-- This slot appears above the options from 'options' prop -->
        <template v-slot:first>
            <b-form-select-option :value="null" disabled>
                -- {{ $t('message.selectAuftrag_auftrag_auswaehlen') }} --
            </b-form-select-option>
        </template>

    </b-form-select>

</template>
<script>

  // Define a new component
  import axios from 'axios';

  export default {

    name: 'SelectAuftrag',
    props: ['monitoring', 'einzelauftrag', 'traplinked'],
    /*
      Defines the data used by the component
    */
    data: function () {
      return {
        /** Liste aller Aufträge des Kunden */
        auftraege: null,
        /** ausgewählter Auftrag */
        selected: null
      }
    },
    watch: {

      selected: function (obj) {
        this.emitMe(obj);
      },
      monitoring: function () {
        this.init();
      },
      einzelauftrag: function () {
        this.init();
      },
      traplinked: function () {
        this.init();
      }

    },
    mounted () {

      this.init();

    },
    methods: {

      /** hole alle Besuchsberichte des Kunden */
      init: function () {

        if (this.$store.getters.customer) {

          var sorting = 'ended';
          /* will Bene doch nicht mehr - 10.1.22
          if (this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
            sorting = 'started';
          }
          */

          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAuftraege';
          var params  = {
            customer_id:      this.$store.getters.customer.id,
            monitoring:       this.monitoring ? true : false,
            einzelauftrag:    this.einzelauftrag ? true : false,
            traplinked:       this.traplinked ? true : false,
            sorter:           sorting
          };

          var that = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            console.log('Aufträge erhalten, geniere Liste mit Text ...');
            that.auftraege = null;
            if (response.data && response.data instanceof Array) {

              var berichte = [];
              for (var i = 0; i < response.data.length; i++) {

                // bei Admin startdatum, bei Kunde enddatum
                var datum = response.data[i].datum;
                datum     = that.javaDateToDefaultDateFormat(datum)
                if (that.$store.getters.user_role.authority == that.ROLE_ADMIN) {
                  datum = response.data[i].datumStarted;
                  datum = that.javaDateToDefaultDateFormat(datum)
                  if (response.data[i].datum && response.data[i].datum != response.data[i].datumStarted) {
                    datum = datum + ' - ' + that.javaDateToDefaultDateFormat(response.data[i].datum);
                  }
                }

                var obj = null;
                if (response.data[i].id) { // es handelt sich um einen Auftrag
                  obj = {
                    value: response.data[i].id,
                    text:  datum + ' | ' + response.data[i].auftragsNr + ' | ' + response.data[i].description
                  }
                } else if (response.data[i].traplinkedbericht_id) { // es handelt sich um einen Traplinkedbericht
                  obj = {
                    value: 'traplinkedbericht_id:' + response.data[i].traplinkedbericht_id,
                    text: that.javaDateToDefaultDateFormat(response.data[i].datumStarted) + ' - ' + that.javaDateToDefaultDateFormat(response.data[i].datum) + ' | ' + that.$t('message.besuchsbericht_monatsbericht')
                  }
                }
                berichte.push(obj);

              }
              that.auftraege = berichte;

            }

          }).catch(error => {
            console.log(error);
          });

        }

      },
      emitMe: function (val) {

        console.log("emit auftrag " + val);
        this.$emit('set-auftrag', val);

      }

    }

  }

</script>
