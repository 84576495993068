import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export const store = new Vuex.Store({
    state: {
        /** die Benutzerrolle des eingeloggten Benutzers */
        user_role: null,
        /** der eingeloggte Benutzer */
        user: null,
        /** der Kunde des eingeloggten Benutzers, oder der ausgewählte Kunde */
        customer: null,
        /** Anzahl der Kunden eines KeyUsers */
        anzahlCustomer: 0,
        /** Standorte des eingeloggten Benutzers */
        standorte: null,
        /** wird auf true gesetzt wenn Chart Daten vorhanden sind */
        chartAvailableBarChartAlarmeJeBereiche: false,
        chartAvailableBarChartAlarmeJeBereicheTraplinked: false,
        chartAvailableBarChartAlarmeJeObjektkategorie: false,
        chartAvailableBarChartAlarmeJeJahr: false,
        /** Benutzerrechte eines Users für einen Customer */
        benutzerrechte: null,
        /** alle Areas die der User sehen darf, CS Liste mit Area IDs */
        showAreas: null,
        /** direkt per get aufgerufene page */
        routeName: null,
        /** direkt per get aufgerufene parameter */
        routeQuery : null,
        /** Anzahl ungelesener Benachrichtigungen für Benutzer */
        unreadNotifications: null,
        /** Admin ist eingeloggt als anderer Benutzer */
        loggedInAs: null,
        /**
         * alle Notifications aktivieren oder deaktivieren über Componente Benachrichtigungen.vue
         * number um triggern zu können
         */
        allEmailNotificationInactive: 0,
        allEmailNotificationActive: 0,
        allAppNotificationInactive: 0,
        allAppNotificationActive: 0
    },
    mutations: {
        setUnreadNotifications(state, unreadNotifications) {
            state.unreadNotifications = unreadNotifications
        },
        setRouteName(state, routeName) {
            state.routeName = routeName
        },
        setRouteQuery(state, routeQuery) {
            state.routeQuery = routeQuery
        },
        setShowAreas(state, showAreas) {
            state.showAreas = showAreas
        },
        setUserRole(state, user_role) {
            state.user_role = user_role
        },
        setUser(state, user) {
            state.user = user
        },
        setCustomer(state, customer) {
            state.customer = customer
        },
        setAnzahlCustomer(state, anzahlCustomer) {
            state.anzahlCustomer = anzahlCustomer
        },
        setChartAvailableBarChartAlarmeJeBereiche(state, chartAvailableBarChartAlarmeJeBereiche) {
            state.chartAvailableBarChartAlarmeJeBereiche = chartAvailableBarChartAlarmeJeBereiche
        },
        setChartAvailableBarChartAlarmeJeBereicheTraplinked(state, chartAvailableBarChartAlarmeJeBereicheTraplinked) {
            state.chartAvailableBarChartAlarmeJeBereicheTraplinked = chartAvailableBarChartAlarmeJeBereicheTraplinked
        },
        setChartAvailableBarChartAlarmeJeObjektkategorie(state, chartAvailableBarChartAlarmeJeObjektkategorie) {
            state.chartAvailableBarChartAlarmeJeObjektkategorie = chartAvailableBarChartAlarmeJeObjektkategorie
        },
        setChartAvailableBarChartAlarmeJeJahr(state, chartAvailableBarChartAlarmeJeJahr) {
            state.chartAvailableBarChartAlarmeJeJahr = chartAvailableBarChartAlarmeJeJahr
        },
        setBenutzerrechte(state, benutzerrechte) {
            state.benutzerrechte = benutzerrechte;
        },
        setStandorte(state, standorte) {
            state.standorte = standorte
        },
        setLoggedInAs(state, loggedInAs) {
            state.loggedInAs = loggedInAs;
        },
        setAllEmailNotificationInactive(state, val) {
            state.allEmailNotificationInactive = val;
        },
        setAllEmailNotificationActive(state, val) {
            state.allEmailNotificationActive = val;
        },
        setAllAppNotificationInactive(state, val) {
            state.allAppNotificationInactive = val;
        },
        setAllAppNotificationActive(state, val) {
            state.allAppNotificationActive = val;
        },

    },
    getters: {
        unreadNotifications:                                state => state.unreadNotifications,
        showAreas:                                          state => state.showAreas,
        user:                                               state => state.user,
        user_role:                                          state => state.user_role,
        chartAvailableBarChartAlarmeJeBereicheTraplinked:   state => state.chartAvailableBarChartAlarmeJeBereicheTraplinked,
        customer:                                           state => state.customer,
        anzahlCustomer:                                     state => state.anzahlCustomer,
        chartAvailableBarChartAlarmeJeBereiche:             state => state.chartAvailableBarChartAlarmeJeBereiche,
        chartAvailableBarChartAlarmeJeObjektkategorie:      state => state.chartAvailableBarChartAlarmeJeObjektkategorie,
        chartAvailableBarChartAlarmeJeJahr:                 state => state.chartAvailableBarChartAlarmeJeJahr,
        benutzerrechte:                                     state => state.benutzerrechte,
        routeQuery:                                         state => state.routeQuery,
        routeName:                                          state => state.routeName,
        standorte:                                          state => state.standorte,
        loggedInAs:                                         state => state.loggedInAs,
        allEmailNotificationInactive:                       state => state.allEmailNotificationInactive,
        allEmailNotificationActive:                         state => state.allEmailNotificationActive,
        allAppNotificationInactive:                         state => state.allAppNotificationInactive,
        allAppNotificationActive:                           state => state.allAppNotificationActive,
    }

});