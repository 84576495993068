<!--
Batterie anzeigen mit Ladestatus
-->
<template>

    <span v-if="wert != null">
      <div class="battery">
        <div :class="'battery-level ' + getBatterieStatusClass(wert)" :style="'height:' + (wert * 100) + '%'"></div>
      </div>
      {{ Math.ceil(wert * 100) + '%' }}
    </span>

</template>
<script>

  // Define a new component
  import axios from "axios";

  export default {

    name: 'BatterieStatus',
    props: [
      /** value ist der Batterie Status, wenn dieser leer ist, dann muss über den Sensor geholt werden */
      'value',
      'sensor'
    ],
    data: function () {
      return {
        wert: null
      }
    },
    mounted() {
      this.init()
    },
    watch: {
      value: function () {
        this.init()
      },
      sensor: function () {
        this.init()
      }
    },
    methods: {

      init: function () {

        if (this.value) {
          this.wert = this.value
        } else if (this.sensor) {

          // kein value vorhanden, hole den letzten Batteriestatus des sensors:
          const customer = this.$store.getters.customer
          const url      = process.env.VUE_APP_SERVER_URL + '/datenbroker/getSensorHistory'
          const params   = {
            customer_id:    customer.id,
            sensor_id:      this.sensor.id,
            max:            50,
          }
          const that = this
          axios({
            method: 'GET',
            params: params,
            url: url
          }).then(response => {

            if (response.data && response.data.sensorResults) {

              for (let i = 0; i < response.data.sensorResults.length; i++) {
                if (response.data.sensorResults[i].batteryStatus) {
                  that.wert = response.data.sensorResults[i].batteryStatus
                  break
                }
              }

            }

          }).catch(error => {
            console.log(error);
          })

        }

      },
      /** liefert die Klasse zu einem Batterie Status */
      getBatterieStatusClass: function (batteryStatus) {

        if (batteryStatus < 0.1) {
          return 'batteryalert';
        }
        if (batteryStatus < 0.2) {
          return 'batterywarn';
        }
        return '';

      }

    }

  }

</script>
