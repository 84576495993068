<script>

  import { HorizontalBar } from 'vue-chartjs';

  export default {
    extends: HorizontalBar,
    props: {
      chartdata: {
        type: Object,
        default: null
      },
      options: {
        type: Object,
        default: null
      }
    },
    mounted () {
      this.renderChart(this.chartdata, this.options);
      var chart = this.$data._chart;
      console.log("Chart: " + chart);
      this.$emit('set-chart', chart);
    },
    methods: {
    }
  }

</script>
