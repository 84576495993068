<template>

    <span class="mangelCarousel">

        <b-carousel data-interval="false"
            id="carousel-fade"
            fade
            style="background-color: var(--dark)"
            :indicators="indicators"
            :interval="0"
            :controls="controls">
            <b-carousel-slide
                v-for="image in images"
                :key="image.id">
              <template #img>
                <img
                    class="d-block img-fluid"
                    @click="showLarge(image.url)"
                    :src="image.url"
                    alt=""
                    style="cursor: pointer">
              </template>
              <p @click="showLarge(image.url)">
                {{ $t('message.imageCarousel_aufgenommen_am') }}:
                <java-date-to-default-date-format v-model="image.datum" />
              </p>
            </b-carousel-slide>
        </b-carousel>

        <b-modal id="large-img" title="Detailansicht" hide-footer size="xl">

          <img class="w-100" :src="src" />

        </b-modal>

    </span>

</template>

<script>

    import $ from 'jquery';
    import JavaDateToDefaultDateFormat from "@/components/layout/JavaDateToDefaultDateFormat.vue";

    // Define a new component
    export default {
        name: 'ImageCarousel',
        components: {
          JavaDateToDefaultDateFormat
        },
        props: {

            /** Bilder die angezeigt werden sollen */
            images: {
                type: Array,
                required: true
            }

        },
        /*
          Defines the data used by the component
        */
        data: function () {
            return {
              controls: null,
              indicators: null,
              /** Bild in Detailansicht */
              src: null
            }
        },
        mounted: function () {

            $('.carousel').carousel();
            if (this.images && this.images.length > 1) {

              this.controls = 'controls';
              this.indicators = 'indicators';

            }

        },
        methods: {

          /** Bild in Modal anzeigen */
          showLarge: function (url) {

            console.log('showLarge', url);
            this.src = url;
            this.$bvModal.show('large-img');

          }

        }
    }

</script>

<style scoped>

    .img-fluid {
        max-height: 400px;
    }

</style>
