<template>

    <div v-if="benutzerliste">

        <!-- Ergebnis Tabelle Filtern -->
        <div id="filter" class="row border-top mb-3 pt-3" v-if="benutzerliste.length > 1">

            <div class="col-md-11">

                <b-input-group size="sm">
                    <b-form-input
                            v-model="keyword"
                            type="search"
                            id="filterInput"
                            :placeholder="$t('message.positionen_filter')">
                    </b-form-input>
                    <b-input-group-append>
                        <b-button :disabled="!keyword" @click="keyword = ''"><span class="oi oi-x"></span></b-button>
                    </b-input-group-append>
                </b-input-group>

            </div>
            <div class="col-md-1 text-right">

                <b-form-group horizontal label="" class="">
                    <b-form-select :options="pageOptions" v-model="perPage" size="sm" />
                </b-form-group>

            </div>

        </div>

        <b-alert variant="warning" :show="loginerror ? true : false">{{ loginerror }}</b-alert>

        <b-table striped
                 small
                 :fields="fields"
                 :items="items"
                 :keyword="keyword"
                 :current-page="currentPage"
                 :per-page="perPage"
                 hover
                 :responsive="true">

            <template v-slot:cell(email)="row">
                {{ row.item.username ? row.item.username : row.item.email }}
            </template>
            <template v-slot:cell(enabled)="row">
                <b-icon icon="x" v-if="!row.item.enabled" style="color: red"></b-icon>
                <b-icon icon="check" v-else style="color: green"></b-icon>
            </template>
            <template v-slot:cell(keyUserId)="row">
                <b-icon icon="check" v-if="row.item.role && row.item.role.authority === 'ROLE_KEY_USER'" style="color: green"></b-icon>
                <span v-else>
                  <key-user-of-user v-model="row.item" />
                </span>
            </template>
            <template v-slot:cell(name)="row">
                <span v-if="row.item.nachname">
                    {{ row.item.vorname }} {{ row.item.nachname }}
                </span>
                <span v-else>
                  {{ row.item.name }}
                </span>
            </template>
            <template v-slot:cell(keyUserAktiv)="row">

              <key-user-aktiv v-model="row.item" />

            </template>
            <template v-slot:cell(actions)="row">
                <b-button size="sm" @click="row.toggleDetails">
                    {{ $t('message.benutzer_standorte') }}&nbsp;<b-icon icon="caret-up-fill" v-if="row.detailsShowing"></b-icon><b-icon icon="caret-down-fill" v-else></b-icon>
                </b-button>
                <b-button variant="outline-primary"
                          size="sm"
                          @click="login(row.item)"
                          class="ml-1">
                    <b-icon icon="box-arrow-right"></b-icon>&nbsp;{{ $t('message.login') }}
                </b-button>
                <!-- Benutzer löschen wenn kein Key User -->
                <b-button variant="outline-danger"
                          v-if="(!row.item.keyUserId || row.item.keyUserId < 0) && $store.getters.user_role && $store.getters.user_role.authority == ROLE_ADMIN"
                          size="sm"
                          @click="deleteUser(row.item)"
                          class="ml-1">
                  <b-icon icon="trash"></b-icon>&nbsp;{{ $t('message.default_loeschen') }}
                </b-button>
            </template>
            <template v-slot:row-details="row">
                <b-card>
                    <list-kunden-of-benutzer :benutzer="row.item" />
                </b-card>
            </template>

        </b-table>

        <b-row>
            <b-col md="12" class="my-1">
                <b-pagination :total-rows="totalRows"
                              :per-page="perPage"
                              v-model="currentPage"
                              class="my-0" />
            </b-col>
        </b-row>

    </div>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true
    import bootbox from 'bootbox'
    import KeyUserOfUser from '@/components/benutzer/KeyUserOfUser'
    import ListKundenOfBenutzer from '@/components/customer/ListKundenOfBenutzer'
    import KeyUserAktiv from '@/components/benutzer/KeyUserAktiv.vue'

    export default {
      name: 'BenutzerListe',
      components: {
        KeyUserOfUser,
        ListKundenOfBenutzer,
        KeyUserAktiv
      },
      props: ['value'],
      data () {
        return {
          benutzerliste: null,
          fields: [
            {
                key: 'email',
                label: this.$t('message.login_username'),
                sortable: true
            },
            {
                key: 'name',
                label: this.$t('message.benutzer_name'),
                sortable: true
            },
            {
                key: 'enabled',
                label: this.$t('message.benutzer_aktiv'),
                sortable: true
            },
            {
                key: 'keyUserId',
                label: this.$t('message.benutzerListe_key_user'),
                sortable: true
            },
            {
              key: 'keyUserAktiv',
              label: this.$t('message.benutzerListe_key_user_aktiv'),
              sortable: true
            },
            {
                key: 'actions',
                label: '',
                sortable: false,
                class: 'text-right'
            }
          ],
          /** Filter Query String für Suche nach Position */
          keyword: '',
          /** Anzahl der Positionen die in der Tabelle angezeigt werden sollen */
          pageOptions: [ 5, 10, 15, 20, 50, 100 ],
          /** ausgewählter Wert aus den pageOptions */
          perPage: 20,
          /** Pagination der Ergenistabelle */
          currentPage: 1,
          /** Fehlermeldung beim Login eines Users */
          loginerror: null
        }
      },
      watch: {
        value: function (newVal) {
          this.loginerror    = null;
          this.benutzerliste = newVal;
        }
      },
      created () {
        this.benutzerliste = this.value;
      },
      computed: {

        items: function () {

          var lowercase = this.keyword ? this.keyword.toLowerCase() : null;

          var selectedRows = lowercase
            ? this.benutzerliste.filter( item =>

              (item.email    && item.email.toLowerCase().includes(lowercase)) ||
              (item.username && item.username.toLowerCase().includes(lowercase))

            )
            : this.benutzerliste;

          return selectedRows;

        },
        totalRows: function () {
          return this.items.length;
        }

      },
      methods: {

        /** Benutzer löschen */
        deleteUser: function (item) {

          var that = this;
          var text = this.$t('message.benutzerListe_delete_sure').replace('{0}', item.username);
          bootbox.confirm(text, function(result) {
            if (result) {

              that.showSpinner();
              var url = process.env.VUE_APP_SERVER_URL + '/biotechUser/delete';
              var params = {
                id: item.id
              }
              axios ({
                method:   'post',
                params:   params,
                url:      url
              }).then(function () {

                var newlist = [];
                for (var i = 0; i < that.benutzerliste.length; i++) {
                  if (that.benutzerliste[i].id != item.id) {
                    newlist.push(that.benutzerliste[i]);
                  }
                }
                that.benutzerliste = newlist;
                that.hideSpinner();

              });

            }
          });

        },
        /** als der ausgewählte Benutzer einloggen */
        login: function (item) {

          let that = this;
          const text = this.$t('message.benutzerListe_login_as');

          bootbox.confirm(text, function (result) {
            if (result) {

              that.showSpinner();

              // bestehenden Benutzer (admin) ausloggen:
              that.logoutUser();

              // neuen Benutzer einloggen:
              var url = process.env.VUE_APP_SERVER_URL + '/login/loginAs';
              var params = {
                user_id: item.id
              }
              axios ({
                method:   'post',
                params:   params,
                url:      url
              }).then(function (response) {

                console.log('Login: ' + response.data);
                if (response.data.error) {
                  that.loginerror = response.data.error;
                } else {

                  // Kunden aus dem Cache löschen, da für neu eingeloggten Benutzer neu geholt werden muss
                  that.$store.commit('setStandorte', null)

                  // loggedInAs setzen:
                  that.$store.commit('setLoggedInAs', response.data.loggedInAs)

                  that.$store.commit('setUserRole', response.data.role)
                  that.$store.commit('setUser',     response.data.user)

                  // ein normaler User geht direkt in den Besuchsbericht seiner Firma:
                  if (response.data.role && response.data.role.authority == that.ROLE_CUSTOMER) {

                    // auch ein normaler User kann verschiedene Kunden/Verträge haben:
                    url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/countCustomerOfBiotechUser';
                    params  = {
                      biotechUser_id: response.data.user.id
                    };
                    axios({
                      method: 'post',
                      params: params,
                      url: url
                    }).then(function (response2) {
                      that.$store.commit('setAnzahlCustomer', response2.data.anzahl);
                      if (response2.data.anzahl == 1) {
                        that.setCustomerAndUserRights(response2.data.customer[0]);
                        that.$router.push('/Besuchsbericht');
                      } else {
                        that.setCustomerAndUserRights(null);
                        that.$router.push('/Dashboard');
                      }
                    });

                  } else if (response.data.role && response.data.role.authority == that.ROLE_KEY_USER) {

                    // ein Key User zur Kundenliste falls er mehrere Kunden hat, sonst direkt ins Dashboard
                    url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/countCustomerOfKeyUser';
                    params  = {
                      userjson: JSON.stringify(response.data.user)
                    };
                    axios({
                      method: 'post',
                      params: params,
                      url: url
                    }).then(function (response2) {
                      that.$store.commit('setAnzahlCustomer', response2.data.anzahl);
                      if (response2.data.anzahl == 1) {
                        that.setCustomerAndUserRights(response2.data.customer);
                        that.$router.push('/Besuchsbericht');
                      } else {
                        that.setCustomerAndUserRights(null);
                        that.$router.push('/Dashboard');
                      }
                    });

                  } else if (response.data.role && response.data.role.authority == that.ROLE_ADMIN) {
                    // ein Admin zur Kundenliste
                    that.$router.push('/Kundenliste');
                  }

                }
                that.hideSpinner();

              });

            }
          });

        },

      }

    }

</script>
