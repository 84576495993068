<template>

    <span class="editNotificationsWrapper" v-if="customer">

      <!-- Button der das Notification Modal öffnet -->
       <b-button @click="showModal()">
           <b-icon icon="mailbox"></b-icon>&nbsp;{{ $t('message.editNotifications_verstaendigung_aendern') }}
       </b-button>

        <b-modal :id="'modal-notifications-' + customer.id"
                 size="lg"
                 :title="$t('message.editNotifications_verstaendigung_aendern')">

            <h2 class="h6 text-left">{{ $t('message.editNotifications_vertragszuteilung') }}:</h2>
            <disable-notification-neuer-besuchsbericht :customer="customer"
                                                       :label-app="$t('message.benachrichtigungen_label_app')"
                                                       :label="$t('message.editNotifications_neuer_besuchsbericht')"
            />
            <notification-neuer-mangel :customer="customer" />
            <notification-alarm :customer="customer" />

            <hr class="mb-5" />
            <h2 class="h6 text-left">{{ $t('message.editNotifications_h2') }}:</h2>
            <traplinked-notifications :customer="customer" />

            <template #modal-footer="{ cancel }">
              <b-button size="sm" variant="success" @click="cancel()">
                {{ $t('message.default_ok') }}
              </b-button>
            </template>

        </b-modal>

    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;
    import TraplinkedNotifications from '@/components/customer/TraplinkedNotifications';
    import DisableNotificationNeuerBesuchsbericht from '@/components/customer/DisableNotificationNeuerBesuchsbericht';
    import NotificationAlarm from "@/components/customer/NotificationAlarm";
    import NotificationNeuerMangel from "@/components/customer/NotificationNeuerMangel";

    export default {
      name: 'EditNotifications',
      components: {
        TraplinkedNotifications,
        DisableNotificationNeuerBesuchsbericht,
        NotificationAlarm,
        NotificationNeuerMangel
      },
      props: ['customer'],
      data () {
        return {
          /** mögliche Notifications */
          traplinkedNotifications: null
        }
      },
      watch: {

        customer: function () {
          this.init();
        }

      },
      created () {

        this.init();

      },
      methods: {

        showModal: function () {
          this.$bvModal.show('modal-notifications-' + this.customer.id);
        },
        init: function () {

            var url     = process.env.VUE_APP_SERVER_URL + '/notification/listTraplinkedNotifications';
            var params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id
            };

            var that = this;
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(function (response) {

                if (response.data) {
                  that.traplinkedNotifications = response.data;
                }

            }).catch(error => {
              console.log(error);
            });

          }

      }

    }

</script>