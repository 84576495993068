<template>

    <span class="checkPasswortSicherheitWrapper" v-if="value">

      <b-modal id="modal-pw" hide-footer no-close-on-backdrop>

        <template #modal-header="">
          <h5>{{ $t('message.check_passwort_sicherheit_change') }}</h5>
        </template>

        <b-alert show>{{ $t('message.check_passwort_sicherheit_desc') }}</b-alert>

        <router-link :to="'/ChangePassword?useSavePassword=true'"
                     class="btn btn-outline-primary"
                     href="#">
          {{ $t('message.changePassword_h1') }}
        </router-link>

      </b-modal>

    </span>

</template>

<script>

    import axios from 'axios'
    axios.defaults.withCredentials = true

    export default {
      name: 'CheckPasswortSicherheit',
      props: [
        /** Kunde */
        'value'
      ],
      data () {
        return {
          error: null
        }
      },
      watch: {
        value: function () {
          this.init()
        }
      },
      mounted () {
        this.init()
      },
      methods: {

        init: function () {

          this.error = null

          // keine Prüfung für Administratoren nötig
          if (this.$store.getters.user_role && this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
            return true
          }
          // auch keine Prüfung nötig wenn ein Admin als jemand anderer eingeloggt ist
          if (this.$store.getters.loggedInAs) {
            return true
          }

          if (this.value) {

            const url     = process.env.VUE_APP_SERVER_URL + '/biotechUser/checkPasswortSicherheit'
            const params  = {
              cardcode: this.value.cardcode,
              mandant:  this.value.biotechCountry.name,
            }

            const that = this
            axios({
              method: 'GET',
              params: params,
              url: url,
            }).then( response => {

              if (response.data.error) {

                console.log('Passwort nicht ok, muss geändert werden', response.data.error)
                that.error = response.data.error
                that.$bvModal.show('modal-pw')

              } else {
                console.log('Passwort ok, keine Änderung nötig.')
              }

            }).catch(error => {
              console.log(error)
            })
          }

        }

      }

    }

</script>
