<template>

    <span class="disableNotificationNeuerVertrag">
      <div class="row">
        <div class="col-md-6">
          <b-form-checkbox
                  :id="'checkbox-DisableNotificationNeuerVertrag-' + (customer ? customer.id : '')"
                  v-model="isChecked"
                  name="isChecked"
                  value="true"
                  unchecked-value="false">
            {{ $t('message.benachrichtigungen_mail') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
                    :id="'checkbox-DisableNotificationNeuerVertrag-app-' + (customer ? customer.id : '')"
                    v-model="isCheckedApp"
                    name="isChecked"
                    value="true"
                    unchecked-value="false">
            {{ $t('message.benachrichtigungen_app') }}
          </b-form-checkbox>
        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'DisableNotificationNeuerVertrag',
      props: ['customer'],
      data () {
        return {
          isChecked: null,
          isCheckedApp: null
        }
      },
      watch: {

        customer: function () {
          this.init();
        },
        isChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(newVal);
          }
        },
        isCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.change(null, newVal);
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      created () {

        this.init();

      },
      methods: {

        change: function (newVal, newValApp) {

          console.log('isChecked geändert auf ' + newVal + ' ' + newValApp);

          // Ausnahme setzen:
          var url     = process.env.VUE_APP_SERVER_URL + '/notification/save';
          var params  = {
            cardcode:               this.customer ? this.customer.cardcode : null,
            mandant:                this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:         this.$store.getters.user.id,
          }

          if (newVal != null) {
            params.disableNeuerVertrag = this.isChecked
          } else if (newValApp != null) {
            params.disableNeuerVertragApp = this.isCheckedApp
          }

          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function () {

            console.log('Notififation Ausnahme gespeichert.');

          }).catch(error => {
            console.log(error);
          });

        },
        init: function () {

            var url     = process.env.VUE_APP_SERVER_URL + '/notification/show';
            var params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id,
              disableNeuerVertrag:  true
            };

            var that = this;
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(function (response) {

                if (response.data.id) {
                  console.log('disableNeuerBesuchsbericht = ', response.data);
                  that.isChecked = response.data.disableNeuerVertrag ? false : true;
                  that.isCheckedApp = response.data.disableNeuerVertragApp ? false : true;
                } else {
                  console.log('keine Antwort, isChecked = true');
                  that.isChecked = true;
                  that.isCheckedApp = true;
                }

            }).catch(error => {
              console.log(error);
            });

          }

      }

    }

</script>