<template>

    <div class="besuchsbericht">

      <!-- prüfe ob ein sicheres Passwort benötigt wird und ob es vorhanden ist -->
      <check-passwort-sicherheit v-if="$store.getters.customer" v-model="$store.getters.customer" />

      <b-sidebar id="sidebar-menu" title="Auftrag suchen" shadow width="640px" :visible="sidebarvisible">
        <div class="px-3 py-2">
          <p>
              <!-- Filtern zwischen Monitoringbesuch und Einzelauftrag -->
              <b-form-checkbox
                      id="checkbox-monitoring"
                      v-model="checkMonitoring"
                      name="checkbox-monitoring"
                      value="true"
                      unchecked-value="false">
                  {{ $t('message.besuchsbericht_monitoringbesuche') }}
              </b-form-checkbox>
              <b-form-checkbox
                      id="checkbox-einzelauftrag"
                      v-model="checkEinzelauftrag"
                      name="checkbox-monitoring"
                      value="true"
                      unchecked-value="false">
                  {{ $t('message.besuchsbericht_einzelauftrag') }}
              </b-form-checkbox>
              <b-form-checkbox
                  id="checkbox-traplinked"
                  v-model="checkTraplinked"
                  name="checkbox-monitoring"
                  value="true"
                  unchecked-value="false">
                  {{ $t('message.besuchsbericht_monatsberichte') }}
              </b-form-checkbox>
              <b-form-invalid-feedback :state="state">
                  {{ $t('message.besuchsbericht_select') }}
              </b-form-invalid-feedback>
              <br/>
              <!-- Auftrag auswählen -->
              <select-auftrag v-on:set-auftrag="setAuftrag"
                              :monitoring="checkMonitoring === 'true' ? true : false"
                              :einzelauftrag="checkEinzelauftrag === 'true' ? true : false"
                              :traplinked="checkTraplinked === 'true' ? true : false"
              />
          </p>
        </div>
      </b-sidebar>

      <div class="row mt-5">

        <div class="col-md-2">
          <b-button v-b-toggle.sidebar-menu variant="outline-primary"><b-icon icon="box-arrow-right"></b-icon>
              &nbsp;{{ $t('message.besuchsbericht_auftrag_suchen') }}
          </b-button>
        </div>
        <div class="col-md-10">
          <div class="content_title" v-if="auftrag">
            <h1>
                {{ $t('message.printErgebnisliste_auftrag_nr') }} {{ auftrag.auftragsNr }}<span v-if="ended"> {{ $t('message.printErgebnisliste_vom') }} {{ ended }}</span>
            </h1>
            <h2 class="h4">{{ auftrag.description }}</h2>
          </div>
          <div v-else-if="traplinkedbericht">
            <h1>{{ $t('message.besuchsbericht_monatsbericht') }}</h1>
            <h2 class="h4">{{ zeitraum }}</h2>
          </div>
        </div>

      </div>

      <transition name="fade" mode="out-in">

        <div class="card mt-3" v-if="(besuchsbericht || traplinkedbericht) && $store.getters.customer">
          <div class="card-header">
            <div class="row">

              <div class="col-md-1 text-left pt-4" v-if="previousBesuchsbericht">
                <b-icon icon="caret-left" scale="3.5" style="cursor: pointer" @click="showPrevious" v-b-tooltip.hover :title="titlePrevious"></b-icon>
              </div>
              <div class="col-md-10">

                <p>
                  <strong>{{ $store.getters.customer.kunde }}</strong><br/>
                  <span v-if="$store.getters.customer.zipcode && $store.getters.customer.ort">
                    {{ $store.getters.customer.zipcode }} {{ $store.getters.customer.ort }},
                  </span>
                  {{ $store.getters.customer.adresse }}<br/>
                  {{ $t('message.printPositionen_kundennummer') }}: {{ $store.getters.customer.cardcode }}<br/>
                  <print-key-user :customer="$store.getters.customer" />
                </p>

              </div>
              <div class="text-right pt-4" :class="!nextBesuchsbericht ? 'col-md-2' : 'col-md-1'" v-if="nextBesuchsbericht">
                <b-icon icon="caret-right" scale="3.5" style="cursor: pointer" @click="showNext" v-b-tooltip.hover :title="titleNext"></b-icon>
              </div>
            </div>
          </div>

          <!-- Charts Filterungen nach Sondereinsatz, ... -->
          <div class="card-header">
            <filter-charts v-model="filter" v-if="besuchsbericht" />
            <!-- Filter anzeigen mit der Möglichkeit diesen zu löschen -->
            <show-filter-charts v-on:delete-filter="deleteFilter()"
                                :filter="filter"
            />
          </div>

          <div v-if="besuchsbericht">
            <!-- Charts -->
            <div class="card-body" v-if="auftrag">
              <div class="row">
                <!-- Alarme je Bereich -->
                <div :class="{ 'col-md-6': noDataLineStackedAreaChartAlarmeNachMonaten, 'col-md-4': !noDataLineStackedAreaChartAlarmeNachMonaten }">
                  <bar-chart-alarme-je-bereiche :besuchsbericht="besuchsbericht"
                                                :auftrag="auftrag"
                                                v-on:filter-area="setFilterArea"
                                                :filter="filter"
                  />
                </div>
                <!-- Alarme je Objektkategorie -->
                <div :class="{ 'col-md-6': noDataLineStackedAreaChartAlarmeNachMonaten, 'col-md-4': !noDataLineStackedAreaChartAlarmeNachMonaten }">
                  <bar-chart-alarme-je-objektkategorie :besuchsbericht="besuchsbericht"
                                                       :auftrag="auftrag"
                                                       :filter="filter"
                                                       v-on:filter-objektkategorie="setFilterObjektkategorie"
                  />
                </div>
                <!-- Relative Alarmergebnisse nach Monaten -->
                <div class="col-md-4" id="lineStackedAreaChartAlarmeNachMonaten" v-if="!noDataLineStackedAreaChartAlarmeNachMonaten">
                  <line-stacked-area-chart-alarme-nach-monaten :besuchsbericht="besuchsbericht"
                                                               :filter="filter"
                                                               v-on:set-no-data="setNoDataLineStackedAreaChartAlarmeNachMonaten"
                  />
                </div>
              </div>
            </div>
            <div class="card-header" style="border-top: 1px solid rgba(0, 0, 0, 0.125)">
              <h3 class="h4">{{ $t('message.besuchsbericht') }}</h3>
            </div>
            <!-- Auftrags-Text -->
            <div class="card-body" v-html="auftragInfo">
            </div>
          </div><!-- end v-if besuchsbericht -->

          <div v-else-if="traplinkedbericht">

            <!-- Traplinked Charts -->
            <div class="card-body">
              <div class="row">
                <!-- Traplinked Alarme je Bereich -->
                <div class="col-md-4">
                  <bar-chart-alarme-je-bereich-traplinked
                      :traplinkedbericht="traplinkedbericht"
                      v-on:filter-area="setFilterArea"
                      :filter="filter"
                  />
                </div>
                <div class="col-md-4">
                  <bar-chart-alarme-je-objektkategorie-traplinked
                      :traplinkedbericht="traplinkedbericht"
                      v-on:filter-area="setFilterArea"
                      v-on:filter-objektkategorie="setFilterObjektkategorie"
                      :filter="filter"
                  />
                </div>
                <!-- Relative Alarmergebnisse nach Monaten -->
                <div class="col-md-4" id="lineStackedAreaChartAlarmeNachMonatenTraplinked">
                  <line-stacked-area-chart-alarme-nach-monaten
                      :traplinkedbericht="traplinkedbericht"
                      :filter="filter"
                  />
                </div>
              </div>
            </div>

          </div>

        </div>
        <b-alert variant="danger" show v-else class="mt-2 text-center">{{ keinBesuchsbericht }}</b-alert>

      </transition>

      <!-- Traplinked Fallen Status anzeigen wenn Traplinkedbericht -->
      <ShowHideTraplinkedStatus v-if="traplinkedbericht" />

      <!-- Sensoren anzeigen -->
      <ShowHideSensorStatus />

      <!-- Ergebnisliste anzeigen wenn Besuchsbericht oder Traplinkedbericht -->
      <transition name="fade" mode="out-in">

        <div class="card mt-3" v-if="besuchsbericht || traplinkedbericht">
          <div class="card-header" @click="showErgebnisse = !showErgebnisse" style="cursor: pointer">
            <div class="row">
              <div class="col-md-8">
                <h3 class="h4">{{ $t('message.printErgebnisliste_h1') }}</h3>
              </div>
              <div class="col-md-4 text-right">
                <b-icon icon="caret-down" font-scale="2" v-if="!showErgebnisse"></b-icon>
                <b-icon icon="caret-up" font-scale="2" v-else></b-icon>
              </div>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <div class="card-body" v-if="besuchsbericht && showErgebnisse">
              <ergebnisse-table :besuchsbericht="besuchsbericht"
                                :auftrag="auftrag"
                                :reset="!showErgebnisse"
              />
            </div>
            <div class="card-body" v-else-if="traplinkedbericht && showErgebnisse">

              <traplinked-ergebnisliste v-model="traplinkedbericht" :zeitraum="zeitraum" />

            </div>
          </transition>
        </div>

      </transition>

      <!-- Mängelliste/Bearbeitungsliste anzeigen -->
      <transition name="fade" mode="out-in" v-if="showMaengel()">

        <div class="card mt-3" v-if="$store.getters.customer && anzahlMaengel > 0">
          <div class="card-header" @click="showMaengelliste = !showMaengelliste" style="cursor: pointer">
            <div class="row">
              <div class="col-md-4">
                <h3 class="h4">{{ $t('message.printMaengel_h1') }}</h3>
              </div>
              <div class="col-md-4">
                <b-alert v-if="anzahlMaengelOffen > 0"
                         :variant="mangelVariant"
                         show
                         style="margin-bottom: 0; padding: 0.3rem 1.25rem; text-align: center">
                  {{ maengelliste_info_text }}
                </b-alert>
              </div>
              <div class="col-md-4 text-right">
                <b-icon icon="caret-down" font-scale="2" v-if="!showMaengelliste"></b-icon>
                <b-icon icon="caret-up" font-scale="2" v-else></b-icon>
              </div>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <div class="card-body" v-if="showMaengelliste">
              <maengel-table :customer="$store.getters.customer"
                             v-on:maengel-changed="countMaengel">
              </maengel-table>
            </div>
          </transition>
        </div>

      </transition>

    </div>

</template>

<script>

  import axios from 'axios'
  axios.defaults.withCredentials = true
  import maengelTable from '@/components/maengelliste/maengelTable'
  import BarChartAlarmeJeBereiche from '@/components/charts/BarChartAlarmeJeBereich'
  import BarChartAlarmeJeObjektkategorie from '@/components/charts/BarChartAlarmeJeObjektkategorie'
  import LineStackedAreaChartAlarmeNachMonaten from '@/components/charts/LineStackedAreaChartAlarmeNachMonaten'
  import ErgebnisseTable from '@/components/ergebnisliste/ErgebnisseTable'
  import SelectAuftrag from '@/components/besuchsbericht/SelectAuftrag'
  import ShowFilterCharts from '@/components/charts/ShowFilterCharts'
  import FilterCharts from '@/components/charts/FilterCharts'
  import TraplinkedErgebnisliste from '@/components/traplinkedbericht/TraplinkedErgebnisliste'
  import BarChartAlarmeJeBereichTraplinked from '@/components/charts/BarChartAlarmeJeBereichTraplinked'
  import BarChartAlarmeJeObjektkategorieTraplinked from '@/components/charts/BarChartAlarmeJeObjektkategorieTraplinked'
  import moment from 'moment'
  import ShowHideTraplinkedStatus from '@/components/traplinkedbericht/ShowHideTraplinkedStatus'
  import ShowHideSensorStatus from '@/components/sensor/ShowHideSensorStatus.vue'
  import PrintKeyUser from '@/components/customer/PrintKeyUser.vue'
  import CheckPasswortSicherheit from '@/components/benutzer/CheckPasswortSicherheit.vue'

  export default {
    name: 'Besuchsbericht',
    components: {
      ShowHideSensorStatus,
      ShowHideTraplinkedStatus,
      ShowFilterCharts,
      LineStackedAreaChartAlarmeNachMonaten,
      BarChartAlarmeJeObjektkategorie,
      BarChartAlarmeJeBereiche,
      maengelTable,
      ErgebnisseTable,
      SelectAuftrag,
      FilterCharts,
      TraplinkedErgebnisliste,
      BarChartAlarmeJeBereichTraplinked,
      BarChartAlarmeJeObjektkategorieTraplinked,
      PrintKeyUser,
      CheckPasswortSicherheit
    },
    data () {
      return {
        /** der Besuchsbericht der angezeigt werden soll */
        besuchsbericht: null,
        /** ODER der Traplinkedbericht der angezeigt werden soll */
        traplinkedbericht: null,
        /** der vorherige Besuchsbericht */
        previousBesuchsbericht: null,
        /** der nächste Besuchsbericht */
        nextBesuchsbericht: null,
        /** Text, dass kein Besuchsbericht vorhanden ist oder anderer Fehler */
        keinBesuchsbericht: '',
        auftrag: null,
        /** sind Daten für den Chart vorhanden? */
        noDataLineStackedAreaChartAlarmeNachMonaten: false,
        /** Mängellist ein/ausblenden */
        showMaengelliste: false,
        /** Ergebnisliste ein/ausblenden */
        showErgebnisse: false,
        /** Anzahl der Mängel für den Kunden */
        anzahlMaengel: 1,
        anzahlMaengelOffen: 0,
        anzahlMaengelErledigt: 0,
        /** zeige die Sidebar */
        sidebarvisible: false,
        /** in der Sidebar Monitoringaufträge zur Auswahl */
        checkMonitoring: 'true',
        /** in der Sidebar Einzelaufträge zur Auswahl */
        checkEinzelauftrag: 'true',
        /** in der Sidebar Traplinked Berichte zur Auswahl */
        checkTraplinked: 'true',
        /** Filter der Charts */
        filter: {
          /** Filter nach einer Objektkategorie */
          filterObjektkategorie: null,
          /** Filter nach einer Area */
          filterArea: null,
          /** Filter nach Sondereinsatz/Monitoringauftrag */
          auftragsart: {
            monitoringbesuch: 'true',
            einzelauftrag: 'true'
          }
        },
        /** Das Datum des Besuchsbericht - wird aus der Zeitaufzeichnung ermittelt */
        ended: ''
      }
    },
    created () {

      var moment = require('moment-timezone');
      moment.tz.setDefault("Europe/Vienna");
      this.init();
      this.sidebarvisible = false;

    },
    watch: {

      besuchsbericht: function (newVal) {
        if (newVal) this.getEndedDate(newVal.id);
      }

    },
    computed: {

      /** print Zeitraum des Traplinkedberichts */
      zeitraum: function () {

        if (this.traplinkedbericht) {

          // ended nicht in der Zukunft anzeigen:
          let endedMoment = this.javaDateToMoment(this.traplinkedbericht.ended);
          let endedStr    = this.javaDateToDefaultDateFormat(this.traplinkedbericht.ended);
          if (endedMoment.isAfter()) { // If nothing is passed to moment#isAfter, it will default to the current time.
            endedStr = moment().format('DD.MM.YYYY');
          }

          return this.javaDateToDefaultDateFormat(this.traplinkedbericht.started) + ' - ' + endedStr;

        }
        return '';

      },
      state: function () {
        if (this.checkMonitoring === 'false' && this.checkEinzelauftrag === 'false' && this.checkTraplinked === 'false') return false;
        return true;
      },
      /** Klasse des Mangel Hinweis */
      mangelVariant: function () {

        if (this.anzahlMaengelOffen) {
          let unerledigteMaengel = this.anzahlMaengelOffen - (this.anzahlMaengelErledigt ? this.anzahlMaengelErledigt : 0);
          if (unerledigteMaengel > 0) {
            return 'danger';
          }
        }
        return 'success';

      },
      /** Info über die Mängel anzeigen ohne die Mängel aufzuklappen */
      maengelliste_info_text: function() {

        if (this.anzahlMaengelOffen) {

          let unerledigteMaengel = this.anzahlMaengelOffen - (this.anzahlMaengelErledigt ? this.anzahlMaengelErledigt : 0);
          console.log('unerledigte mängel', unerledigteMaengel);

          if (unerledigteMaengel > 0) {

            let erledigteText = ' ';
            if (this.anzahlMaengelErledigt && this.anzahlMaengelErledigt == 1) {
              erledigteText = this.$t('message.besuchsbericht_1bearbeiterMangel');
            } else if (this.anzahlMaengelErledigt && this.anzahlMaengelErledigt > 1) {
              erledigteText = this.$t('message.besuchsbericht_MbearbeiterMangel').replace('{0}', this.anzahlMaengelErledigt);
            }

            var txt = this.$t('message.besuchsbericht_thereAre');
            if (unerledigteMaengel == 1) {
              txt = this.$t('message.besuchsbericht_thereIs');
            }
            txt += unerledigteMaengel;
            if (unerledigteMaengel == 1) {
              txt += ' ' + this.$t('message.besuchsbericht_1unerledigter').replace('{0}', erledigteText);
            } else {
              txt += ' ' + this.$t('message.besuchsbericht_Munerledigter').replace('{0}', erledigteText);
            }

          } else { // es gibt nur erledigte Mängel

            var txt = this.$t('message.besuchsbericht_thereAre');
            if (this.anzahlMaengelErledigt == 1) {
              txt = this.$t('message.besuchsbericht_thereIs');
            }
            txt += this.anzahlMaengelErledigt;
            if (this.anzahlMaengelErledigt == 1) {
              txt += this.$t('message.besuchsbericht_mangelVorhanden');
            } else {
              txt += this.$t('message.besuchsbericht_maengelVorhanden');
            }

          }

          return txt;

        } else {
          return '';
        }

      },
      auftragInfo: function () {

        var auftragInfo = this.besuchsbericht.auftragInfoOffice ? this.besuchsbericht.auftragInfoOffice : this.besuchsbericht.auftragInfo;

        if (auftragInfo) {
          return auftragInfo.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }

        return null;

      },
      titlePrevious: function () {

        if (this.previousBesuchsbericht) {
          var pEnded = this.previousBesuchsbericht.endedOffice ? this.previousBesuchsbericht.endedOffice : this.previousBesuchsbericht.ended;
          return this.$t('message.besuchsbericht_vorheriger_von') + this.javaDateToDefaultDateFormat(pEnded);
        }
        return null;

      },
      titleNext: function () {

        if (this.nextBesuchsbericht) {
          var nEnded = this.nextBesuchsbericht.endedOffice ? this.nextBesuchsbericht.endedOffice : this.nextBesuchsbericht.ended;
          return this.$t('message.besuchsbericht_naechsten_von') + this.javaDateToDefaultDateFormat(nEnded);
        }
        return null;

      }

    },
    methods: {

      /**
       * holt das anzuzeigende Datum des Besuchsberichts
       * ES IST PROBLEMATISCH DIES AUS DEN ZEITAUFZEICHNUNGEN ZU HOLEN,
       * DA CHARTS UND ALLES ANDERE IMMER NACH ENDED_OFFICE UND ENDED DES
       * BESUCHSBERICHTS GEHT
       * @param besuchsbericht_id
       */
      getEndedDate: function () {

        let datestr = '';
        if (this.besuchsbericht) {

          datestr = this.besuchsbericht.endedOffice ? this.javaDateToDefaultDateFormat(this.besuchsbericht.endedOffice) : null;
          if (!datestr) {
            datestr = this.besuchsbericht.ended ? this.javaDateToDefaultDateFormat(this.besuchsbericht.ended) : null;
          }
          let started = this.javaDateToDefaultDateFormat(this.besuchsbericht.started);
          if (!datestr && started) {
            datestr = started;
          } else if (datestr == started) { // am gleichen Tag ended und started
            datestr = started;
          } else {
            datestr = started + ' - ' + datestr;
          }

        }
        this.ended = datestr;

      },
      /** zeige das Ende Datum eines Traplinked Berichts, wenn das Datum in der Zukunft liegt, zeige das heutige Datum */
      traplinkedberichtEnded: function (javadate) {

        console.log('Traplinked Bericht ended = ' + javadate);
        // blöder Hack, aber ziehe eine Sekunde ab, damit nicht nächster Tag entsteht
        if (javadate.includes('T22:00:00')) {
          javadate = javadate.replace('T22:00:00', 'T21:59:59')
        }
        var mom = this.javaDateToMoment(javadate);
        var now = moment()
        if (now.diff(mom) < 0 ) {
          return now.format('DD.MM.YYYY');
        }
        return mom.format('DD.MM.YYYY');

      },
      /**
       * Filter Objektkategorie löschen
       */
      deleteFilter: function () {
        this.filter = {
          filterObjektkategorie: null,
          filterArea:            null,
          auftragsart: {
            monitoringbesuch: 'true',
            einzelauftrag: 'true'
          }
        }
      },
      /**
       * nach einer Objektkategorie filtern
       */
      setFilterObjektkategorie: function (label) {
        console.log('Setzte Filter Objektkategorie in Besuchsbericht: ' + label);
        this.filter.filterObjektkategorie = label;
      },
      /**
       * nach einer Area filtern
       */
      setFilterArea: function (label) {
        this.filter.filterArea = label;
      },
      /** ein Besuchsbericht wurde aus select-auftrag ausgewählt */
      setAuftrag: function (auftrag_id) {

        console.log('Auftrag ausgewählt ' + auftrag_id);

        // Sidebar schließen
        this.sidebarvisible = false;

        if (auftrag_id && auftrag_id != 'null') {

          this.showSpinner();

          // Filter löschen
          // this.deleteFilter(); // wenn der da gelöscht wird, werden falsche chart daten abgerufen -

          var url    = null;
          var params = null;
          var isTraplinked = false;
          if (('' + auftrag_id).startsWith('traplinkedbericht_id:')) {
            isTraplinked = true;
          }

          if (isTraplinked) {
            url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getTraplinkedbericht';
            params  = {
              traplinkedbericht_id: auftrag_id.replace('traplinkedbericht_id:', '')
            }
          } else {
            url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getBesuchsberichtOfAuftrag';
            params  = {
              auftrag_id: auftrag_id
            }
          }

          var that    = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            that.auftrag                = null;
            that.noDataLineStackedAreaChartAlarmeNachMonaten = false;
            that.showMaengelliste       = false;
            that.showErgebnisse         = false;
            that.filterObjektkategorie  = null;

            if (response.data.id && !isTraplinked) {

              that.traplinkedbericht      = null;
              that.besuchsbericht         = response.data;
              that.getPreviousBesuchsbericht();
              that.getNextBesuchsbericht();
              that.getAuftrag(that.besuchsbericht);

            } else {

              that.traplinkedbericht      = response.data;
              that.besuchsbericht         = null;
              that.getPreviousBesuchsbericht();
              that.getNextBesuchsbericht();

            }
            that.hideSpinner();

          }).catch(error => {
            console.log(error);
            that.hideSpinner();
          });

        }

      },
      setNoDataLineStackedAreaChartAlarmeNachMonaten: function(val) {
        this.noDataLineStackedAreaChartAlarmeNachMonaten = val;
      },
      /** zeige nächsten Besuchsbericht */
      showNext: function() {

        this.showSpinner();
        if (this.nextBesuchsbericht) {
          this.auftrag                = null;
          this.previousBesuchsbericht = this.besuchsbericht ? this.besuchsbericht : this.traplinkedbericht;
          if (this.nextBesuchsbericht && this.nextBesuchsbericht.auftrag) {
            this.besuchsbericht    = this.nextBesuchsbericht;
            this.traplinkedbericht = null;
            this.getAuftrag(this.besuchsbericht);
          } else {
            this.besuchsbericht    = null;
            this.traplinkedbericht = this.nextBesuchsbericht
          }
          this.getNextBesuchsbericht();
          this.noDataLineStackedAreaChartAlarmeNachMonaten = false;
          this.showMaengelliste       = false;
          this.showErgebnisse         = false;
          // Filter löschen
          this.deleteFilter();
        }
        this.hideSpinner();

      },
      /** zeige vorherigen Besuchsbericht */
      showPrevious: function() {

        console.log('show previous Besuchsbericht ...');
        this.showSpinner();
        if (this.previousBesuchsbericht) {
          this.auftrag                = null;
          this.nextBesuchsbericht     = this.besuchsbericht ? this.besuchsbericht : this.traplinkedbericht;
          if (this.previousBesuchsbericht && this.previousBesuchsbericht.auftrag) {
            this.besuchsbericht         = this.previousBesuchsbericht;
            this.traplinkedbericht      = null;
            this.getAuftrag(this.besuchsbericht);
          } else {
            this.besuchsbericht         = null;
            this.traplinkedbericht      = this.previousBesuchsbericht;
          }
          this.getPreviousBesuchsbericht();
          this.noDataLineStackedAreaChartAlarmeNachMonaten = false;
          this.showMaengelliste       = false;
          this.showErgebnisse         = false;
          // Filter löschen
          this.deleteFilter();
        }
        this.hideSpinner();

      },
      /** holt den nächsten Besuchsbericht oder Traplinked Bericht des Kunden */
      getNextBesuchsbericht: function() {

        console.log("get next Besuchsbericht " + this.besuchsbericht);

        var sorting = 'ended';
        /* will Bene doch nicht mehr 10.1.22
        if (this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
          sorting = 'started';
        }
        */

        if (this.besuchsbericht || this.traplinkedbericht) {

          this.nextBesuchsbericht = null;
          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getNextBesuchsbericht';
          var params  = {
            besuchsbericht_id:    this.besuchsbericht ? this.besuchsbericht.id : null,
            checkEinzelauftrag:   this.checkEinzelauftrag == 'true' ? true : false,
            checkMonitoring:      this.checkMonitoring == 'true'    ? true : false,
            checkTraplinked:      this.checkTraplinked == 'true'    ? true : false,
            traplinkedbericht_id: this.traplinkedbericht ? this.traplinkedbericht.id : null,
            sorter:               sorting
          };

          var that    = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data.id) {
              console.log("nächster = " + response.data.ended);
              that.nextBesuchsbericht = response.data;
            } else {
              that.nextBesuchsbericht = null;
            }

          }).catch(error => {
            console.log(error);
          });

        }

      },
      /** holt den vorherigen Besuchsbericht oder Traplinked Bericht des Kunden */
      getPreviousBesuchsbericht: function() {

        console.log("get prev Besuchsbericht " + this.besuchsbericht);

        var sorting = 'ended';
        /* will Bene doch nicht mehr 10.1.22
        if (this.$store.getters.user_role.authority == this.ROLE_ADMIN) {
          sorting = 'started';
        }
        */

        if (this.besuchsbericht || this.traplinkedbericht) {

          this.previousBesuchsbericht = null;
          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getPreviousBesuchsbericht';
          var params  = {
            besuchsbericht_id:    this.besuchsbericht ? this.besuchsbericht.id : null,
            checkEinzelauftrag:   this.checkEinzelauftrag == 'true' ? true : false,
            checkMonitoring:      this.checkMonitoring == 'true'    ? true : false,
            checkTraplinked:      this.checkTraplinked == 'true'    ? true : false,
            traplinkedbericht_id: this.traplinkedbericht ? this.traplinkedbericht.id : null,
            sorter:               sorting
          };

          var that    = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data.id) {
              console.log("vorheriger = " + response.data.ended);
              that.previousBesuchsbericht = response.data;
            } else {
              that.previousBesuchsbericht = null;
            }

          }).catch(error => {
            console.log(error);
          });

        }

      },
      /** holt den letzten Besuchsbericht des Kunden */
      init: function () {

        // Umami Tracking aufrufen
        this.doTracking(this.$route.name);

        if (this.$store.getters.customer) {

          this.showSpinner();
          var customer = this.$store.getters.customer;
          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getLastBesuchsbericht';
          var params  = {
            customer_id: customer.id
          };

          var that    = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            console.log('last besuchsbericht: ' + response.data);

            if (response.data.ended || response.data.started) {

              if (response.data.auftrag) {
                that.besuchsbericht = response.data;
                // hole auftrag zu Besuchsbericht
                that.getAuftrag(that.besuchsbericht);
              } else {
                that.besuchsbericht         = null;
                that.traplinkedbericht      = response.data;
              }

              that.getNextBesuchsbericht();
              that.getPreviousBesuchsbericht();

            } else {
              that.keinBesuchsbericht = that.$t('message.besuchsbericht_keiner');
            }
            that.hideSpinner();

          }).catch(error => {
            console.log(error);
            that.hideSpinner();
          });

          // hole die Anzahl der Mängel
          this.countMaengel();

        }

      },
      /** zähle die Mängel eines Kunden */
      countMaengel: function () {

        if (this.$store.getters.customer) {
          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/countMaengel';
          var params  = {
            customer:    JSON.stringify(this.$store.getters.customer),
            showDetails: true
          };
          var that = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            that.anzahlMaengelOffen    = response.data.offen ? response.data.offen : 0;
            that.anzahlMaengelErledigt = response.data.erledigt ? response.data.erledigt : 0;
            that.anzahlMaengel         = that.anzahlMaengelOffen + that.anzahlMaengelErledigt;

          }).catch(error => {
            console.log(error);
          });
        }

      },
      /** holt den Auftrag zu einem Besuchsbericht */
      getAuftrag: function (besuchsbericht) {

        console.log("hole Auftrag zu Besuchsbericht " + besuchsbericht);
        if (besuchsbericht) {

          var url     = process.env.VUE_APP_SERVER_URL + '/datenbroker/getAuftrag';
          var params  = {
            besuchsbericht_id: besuchsbericht.id
          };

          var that    = this;
          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function (response) {

            if (response.data.auftragsNr) {
              that.auftrag = response.data;
            } else {
              that.auftrag = null;
            }

          }).catch(error => {
            console.log(error);
          });

        }

      }

    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .h4 {
    text-align: left;
  }
  h2.h4 {
    text-align: center;
  }

</style>
