<template>
  <div id="app">
    <biotech-header v-if="!isPrintView" />
    <main class=".routerView container-fluid">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
    <biotech-footer v-if="!isPrintView || !hideFooter" />
    <spinner />
  </div>
</template>

<script>

  import BiotechHeader from './components/layout/BiotechHeader.vue';
  import BiotechFooter from './components/layout/BiotechFooter.vue'
  import Spinner from './components/layout/Spinner.vue';

  export default {
    name: 'app',
    components: {
      BiotechHeader,
      BiotechFooter,
      Spinner
    },
    computed: {

      /**
       * keinen Header und keinen Footer bei Druckansicht
       * @returns {boolean}
       */
      isPrintView: function () {

        var name = this.$route.name ? this.$route.name : this.$route.path;
        if (name == 'PrintErgebnisliste' || name == 'PrintMaengel') {
          return true;
        }
        return false;

      },
      /**
       * Footer ausblenden bei bestimmten Seiten
       * @returns {boolean}
       */
      hideFooter: function () {

        var name = this.$route.name ? this.$route.name : this.$route.path;
        if (name == 'Plan') {
          return true;
        }
        return false;

      }

    },
    created () {

      // merke dir den deep Link und die Parameter, um nach dem Login dorthin zurück zu routen
      console.log('route query: ' + this.$route.query);
      console.log('route name: '  + this.$route.name);
      this.$store.commit('setRouteQuery', this.$route.query);
      this.$store.commit('setRouteName',  this.$route.name);

      // wenn nicht eingeloggt, dann darf nur Welcome, ForgotPassword, NewPassword oder Login geöffnet sein
      if (!this.$store.getters.user) {
        var name = this.$route.name ? this.$route.name : this.$route.path;
        console.log('niemand ist eingeloggt in ' + name);
        if (
                name != 'Welcome' &&
                name != 'ForgotPassword' &&
                name != 'NewPassword' &&
                name != 'Login' &&
                name != 'PrintErgebnisliste' &&
                name != 'PrintPositionen' &&
                name != 'PrintMaengel'
        ) {
          this.$router.push('/');
        }
      }

    }

  }

</script>
