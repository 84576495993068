<template>

    <form>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="content_title">
            <h1 v-if="!isNewUser">{{ $t("message.newPassword_h1") }}</h1>
            <h1 v-else>{{ $t("message.newPassword_h1_2") }}</h1>
          </div>
        </div>
      </div>
      <p class="mt-5">
        <span v-if="!isNewUser">{{ $t("message.newPassword_pleaseinsert") }}</span>
        <span v-else>{{ $t("message.newPassword_festlegen") }}</span>
        {{ $t("message.newPassword_description") }}
      </p>

      <b-alert variant="danger" :show="errormessage ? true : false">{{ errormessage }}</b-alert>
      <b-alert variant="danger" :show="(errormessage ? true : false) && username === null">
          {{ $t("message.newPassword_code_error") }}
      </b-alert>

      <div v-if="username !== true">

        <new-password-form-row v-on:set-password="setPassword" />

        <captcha-form-row v-on:set-code="setCode" />

        <div class="form-group row">
          <div class="col-md-2"></div>
          <div class="col-md-10">
            <button type="button"
                    class="btn btn-outline-dark"
                    v-on:click="submitForm"
                    :disabled="!showSubmitButton">
                {{ $t("message.default_absenden") }}
            </button>
          </div>
        </div>

      </div>

    </form>

</template>

<script>

import axios from 'axios';
axios.defaults.withCredentials = true;
import CaptchaFormRow from '@/components/layout/CaptchaFormRow.vue'
import NewPasswordFormRow from '@/components/password/NewPasswordFormRow.vue'

export default {

  name: 'NewPassword',
  components: {

    CaptchaFormRow,
    NewPasswordFormRow

  },
  data () {
    return {
      username: null,
      captcha: null,
      /** der vom Benutzer eingegebene Sicherheitscode des Captcha */
      code: null,
      /** Fehlermeldung nach dem Absenden der Passwort Anforderung */
      errormessage: null,
      /** Token über GET */
      token: null,
      password: null,
      /** ein neuer User der von einem Key User angelegt wurde */
      isNewUser: null
    }
  },
  computed: {

    /** kann der Weiter Button angezeigt werden? */
    showSubmitButton: function () {

      if (!this.code || this.code.length < 6) return false;
      if (!this.isPasswordValid) return false;
      return true;

    },
    /** prüft ob das Passwort eingegeben wurde */
    isPasswordValid: function () {

      if (this.password && this.password !== '') {
        return true;
      }

      return false;

    }

  },
  mounted: function () {

    /** Token aus GET holen und prüfen */
    this.checkToken();

    // Umami Tracking aufrufen
    this.doTracking(this.$route.name);

  },
  methods: {

    /** Passwort wurde verifiziert und zurückgegeben */
    setPassword: function (val) {

      this.password = val;

    },
    /** Captcha Code wurde eingegeben */
    setCode: function (val) {

      this.code = val;

    },
    /** Token aus GET holen und prüfen */
    checkToken: function () {

      this.token = this.$route.params.token;
      if (this.token && this.token !== '') {

        var url  = process.env.VUE_APP_SERVER_URL + '/login/checkToken';
        var that = this;
        axios.get(url, {
          params: {token: this.token},
          withCredentials: true
        })
          .then(function (response) {
            if (response.data.success) {
              that.username  = response.data.username;
              that.isNewUser = response.data.isNewUser;
            } else {
              that.errormessage = response.data.message;
              that.username = null;
            }
          })
          .catch(function (error) {
            console.log(error);
            that.username = null;
          });

      } else {
        this.username = null;
      }

    },
    submitForm: function () {

      this.showSpinner();
      var url    = process.env.VUE_APP_SERVER_URL + '/login/changePass';
      var params = {
        'username':   this.username,
        'captcha':    this.code,
        'password':   this.password,
        'token':      this.token,
        'isNewUser':  this.isNewUser
      };

      var that = this;
      axios.get(url, {
        params: params,
        withCredentials: true
      })
        .then(function (response) {

          console.log('Passwort Änderung: ' + response.data);
          that.hideSpinner();
          if (response.data.success) {

            console.log('Passwort wurde geändert');
            that.errormessage = null;
            var message = response.data.message;
            that.$router.push({
              name: 'Login',
              params: {
                message: message
              }
            })

          } else {
            that.errormessage = response.data.message;
          }

        })
        .catch(function (error) {
          that.hideSpinner();
          console.log(error);
        });

    }

  }

}
</script>
