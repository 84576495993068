<template>

  <span class="fileUploadWrapper">

    <b-button variant="outline-primary" @click="showModal()" size="sm">
      <b-icon icon="upload" />&nbsp;&nbsp;{{ $t('message.fileUploadButton_button') }}
    </b-button>

    <b-modal id="modal-file-upload"
             :title="$t('message.fileUploadButton_neu')"
             size="lg"
             :hide-footer="true">

      <file-upload filetype="EIGENE_DATEI" v-on:set-file="setFile" />

    </b-modal>

  </span>

</template>

<script>

  import FileUpload from "@/components/dokumente/FileUpload.vue";

  export default {
    name: 'FileUploadButton',
    components: {
      FileUpload
    },
    props: {
      /** Typ des Files aus der Enum */
      filetype: null,
    },
    methods: {

      /** Modal Window öffnen für File Upload */
      showModal: function () {

        this.$bvModal.show('modal-file-upload');

      },
      setFile: function (obj) {
        this.$bvModal.hide('modal-file-upload')
        this.$emit('set-file', obj);
      }

    }

  }

</script>
