<template>

    <span class="notificationAlarm">
      <div class="row">
        <div class="col-md-6">
          <b-form-checkbox
                  :id="'checkbox-notificationAlarmGelb-' + (customer ? customer.id : '')"
                  v-model="isGelbChecked"
                  name="isGelbChecked"
                  value="true"
                  unchecked-value="false">
              {{ $t('message.notificationAlarm_gelber_alarm') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
              :id="'checkbox-notificationAlarmGelb-app-' + (customer ? customer.id : '')"
              v-model="isGelbCheckedApp"
              name="isGelbCheckedApp"
              value="true"
              unchecked-value="false">
              {{ $t('message.notificationAlarm_gelber_alarm_app') }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-checkbox
                    :id="'checkbox-notificationAlarmRot-' + (customer ? customer.id : '')"
                    v-model="isRotChecked"
                    name="isRotChecked"
                    value="true"
                    unchecked-value="false">
              {{ $t('message.notificationAlarm_roter_alarm') }}
          </b-form-checkbox>
        </div>
        <div class="col-md-6">
          <b-form-checkbox
              :id="'checkbox-notificationAlarmRot-app-' + (customer ? customer.id : '')"
              v-model="isRotCheckedApp"
              name="isRotCheckedApp"
              value="true"
              unchecked-value="false">
              {{ $t('message.notificationAlarm_roter_alarm_app') }}
          </b-form-checkbox>
        </div>
      </div>
    </span>

</template>

<script>

    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
      name: 'NotificationAlarm',
      props: [
        /** customer kann auch ein Objekt mit ID 'ALLE' sein, um für alle Standorte des Keyusers Werte zu setzen */
        'customer'
      ],
      data () {
        return {
          isGelbChecked: null,
          isGelbCheckedApp: null,
          isRotChecked: null,
          isRotCheckedApp: null
        }
      },
      watch: {

        customer: function () {
          this.init();
        },
        isGelbChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeGelb(newVal);
          }
        },
        isGelbCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeGelb(null, newVal);
          }
        },
        isRotChecked: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeRot(newVal);
          }
        },
        isRotCheckedApp: function (newVal, oldVal) {
          console.log('newVal = ' + newVal + ' oldVal = ' + oldVal);
          if (newVal != null && oldVal != null && newVal != oldVal) {
            this.changeRot(null, newVal);
          }
        },
        '$store.state.allEmailNotificationInactive': function () {
          console.log('allEmailNotificationInactive', this.$store.state.allEmailNotificationInactive)
          this.init()
        },
        '$store.state.allEmailNotificationActive': function () {
          console.log('allEmailNotificationActive', this.$store.state.allEmailNotificationActive)
          this.init()
        },
        '$store.state.allAppNotificationInactive': function () {
          console.log('allAppNotificationInactive', this.$store.state.allAppNotificationInactive)
          this.init()
        },
        '$store.state.allAppNotificationActive': function () {
          console.log('allAppNotificationActive', this.$store.state.allAppNotificationActive)
          this.init()
        }

      },
      created () {

        this.init();

      },
      methods: {

        changeGelb: function (newVal, newValApp) {

          console.log('changeGelb geändert auf ' + newVal + ' ' + newValApp);

          // Ausnahme setzen:
          var url     = process.env.VUE_APP_SERVER_URL + '/notification/save';
          var params  = {
            cardcode:             this.customer ? this.customer.cardcode : null,
            mandant:              this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:       this.$store.getters.user.id,
          }

          if (newVal != null) {
            params.gelberAlarm = this.isGelbChecked
          } else if (newValApp != null) {
            params.gelberAlarmApp = this.isGelbCheckedApp
          }

          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function () {

            console.log('isGelbChecked gespeichert.');

          }).catch(error => {
            console.log(error);
          });

        },
        changeRot: function (newVal, newValApp) {

          console.log('changeRot geändert auf ' + newVal + ' ' + newValApp);

          // Ausnahme setzen:
          var url     = process.env.VUE_APP_SERVER_URL + '/notification/save';
          var params  = {
            cardcode:             this.customer ? this.customer.cardcode : null,
            mandant:              this.customer ? this.customer.biotechCountry.name : null,
            biotechUser_id:       this.$store.getters.user.id,
          }

          if (newVal != null) {
            params.roterAlarm = this.isRotChecked
          } else if (newValApp != null) {
            params.roterAlarmApp = this.isRotCheckedApp
          }

          axios({
            method: 'post',
            params: params,
            url: url
          }).then(function () {

            console.log('isRotChecked gespeichert.');

          }).catch(error => {
            console.log(error);
          });

        },
        init: function () {

            var url     = process.env.VUE_APP_SERVER_URL + '/notification/show';
            var params  = {
              cardcode:             this.customer ? this.customer.cardcode : null,
              mandant:              this.customer ? this.customer.biotechCountry.name : null,
              biotechUser_id:       this.$store.getters.user.id
            };

            var that = this;
            axios({
              method: 'get',
              params: params,
              url: url
            }).then(function (response) {

                if (response.data.id) {
                  console.log('disableNeuerBesuchsbericht = ' + response.data.disableNeuerBesuchsbericht);
                  that.isRotChecked     = response.data.roterAlarm     ? true : false;
                  that.isRotCheckedApp  = response.data.roterAlarmApp  ? true : false;
                  that.isGelbChecked    = response.data.gelberAlarm    ? true : false;
                  that.isGelbCheckedApp = response.data.gelberAlarmApp ? true : false;
                } else {
                  console.log('keine Antwort, isChecked = false');
                  that.isRotChecked     = false;
                  that.isRotCheckedApp  = false;
                  that.isGelbChecked    = false;
                  that.isGelbCheckedApp = false;
                }

            }).catch(error => {
              console.log(error);
            });

          }

      }

    }

</script>
